import React from "react";
import { Link } from "react-router-dom";

function SocialLinks({ site_settings }) {
  return (
    <>
      <ul className="social_lnks">
        {
          site_settings?.site_facebook ?
            <li>
              <a href={site_settings?.site_facebook} target="_blank">
                <img
                  src="/images/facebook.svg"
                  alt=""
                />
              </a>
            </li>
            :
            ""
        }
        {
          site_settings?.site_instagram ?
            <li>
              <a href={site_settings?.site_instagram} target="_blank">
                <img
                  src="/images/instagram.svg"
                  alt=""
                />
              </a>
            </li>
            :
            ""
        }
        {
          site_settings?.site_twitter ?
            <li>
              <a href={site_settings?.site_twitter} target="_blank">
                <img
                  src="/images/twitter.svg"
                  alt=""
                />
              </a>
            </li>
            :
            ""
        }
        {
          site_settings?.site_linkedin ?
            <li>
              <a href={site_settings?.site_linkedin} target="_blank">
                <img
                  src="/images/linkedin.svg"
                  alt=""
                />
              </a>
            </li>
            :
            ""
        }
      </ul>

    </>
  );
}

export default SocialLinks;
