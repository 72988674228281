import React, { useEffect } from "react";
import { fetchBlogDetail } from "../../../states/actions/fetchBlogDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import Text from "../../common/Text";
import { Link, useParams } from "react-router-dom";
import ImageControl from "../../common/ImageControl";

const SimpleBlogDetails = () => {
    const { id, type } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchBlogDetail.content);
    const isLoading = useSelector((state) => state.fetchBlogDetail.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    const { content, blog, similar_blogs } = data;

    useEffect(() => {
        dispatch(fetchBlogDetail({ id, type: "dashboard-blog" }));
    }, [id]);
    useDocumentTitle(data.page_title);
    return (
        <>
            {isLoading || isSiteSettingsLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <section className="blog">
                        <div className="contain">
                            <div className="blog_image">

                                <ImageControl src={blog.image} folder="testimonials" />
                            </div>
                            <div className="detail_cntnt">
                                <div className="heading_blog">
                                    <h4>
                                        <Text string={blog.name} />
                                    </h4>
                                </div>
                                {
                                    blog.detail ?
                                        <div className="ck_editor blog_cntnt">
                                            <Text string={blog.detail} />
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </section>

                </>
            )}
        </>
    );
};
export default SimpleBlogDetails;
