import React, { useEffect } from "react";
import { fetchBlogDetail } from "../../../states/actions/fetchBlogDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import Text from "../../common/Text";
import { Link, useParams } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import MetaGenerator from "../../common/meta-generator";

const BlogDetails = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchBlogDetail.content);
  const isLoading = useSelector((state) => state.fetchBlogDetail.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, blog, similar_blogs, meta } = data;

  useEffect(() => {
    dispatch(fetchBlogDetail({ id, type }));
  }, [id]);
  // useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <section className="blog">
            <div className="contain">
              <div className="blog_image">
                <Link to="/blogs">
                  <img src="/images/left_angle.svg" alt="" />{" "}
                  <span>Back to blogs</span>
                </Link>
                <ImageControl src={blog.image} folder="blog" />
              </div>
              <div className="detail_cntnt">
                <div className="heading_blog">
                  <h4>
                    <Text string={blog.title} />
                  </h4>
                  <div className="auther_info">
                    <div className="sm_icn">
                      <ImageControl src={blog.writter_image} folder="blog" />
                    </div>
                    <div className="_cnt">
                      <h6>
                        <Text string={blog.writter_name} />
                      </h6>
                      <p>
                        <Text string={blog.writter_tagline} />
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="social_lnk_blog">
                  <li>
                    <Link to="/">
                      <img src="/images/color_fb.svg" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="/images/color_twitter.svg" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="/images/color_linkedin.svg" alt="" />
                    </Link>
                  </li>
                </ul>
                <div className="ck_editor blog_cntnt">
                  <Text string={blog.detail} />
                </div>
              </div>
            </div>
          </section>
          <section className="similar_posts">
            <div className="contain">
              <div className="main_heading">
                <h4>
                  <Text string={content.articles_heading} />
                </h4>
              </div>
              <div className="flex">
                {similar_blogs &&
                  similar_blogs.map((blog, i) => (
                    <>
                      <div className="col" key={i + 912100000000}>
                        <div className="inner">
                          <Link
                            to={`/blog-detail/${blog.encoded_id}/${blog.slug}`}
                            className="image"
                          >
                            <ImageControl src={blog.image} folder="blog" />
                          </Link>
                          <div className="cntnt">
                            <h5>
                              <Link
                                to={`/blog-detail/${blog.encoded_id}/${blog.slug}`}
                              >
                                <Text string={blog.title} length={50} />
                              </Link>
                            </h5>
                            <Text string={blog.detail} length={200} />
                            <br />
                            <div className="auther_info">
                              <div className="sm_icn">
                                <ImageControl
                                  src={blog.writter_image}
                                  folder="blog"
                                />
                              </div>
                              <div className="_cnt">
                                <h6>
                                  <Text string={blog.writter_name} />
                                </h6>
                                <p>
                                  <Text string={blog.writter_tagline} />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default BlogDetails;
