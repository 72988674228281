import React from "react";

function TabContent({ content }) {
  return (
    <>
      <div className="form_grp">
        <label>Location</label>
        <input type="text" defaultValue="Georgia, USA" className="input" />
      </div>
      <div className="form_grp">
        <label>Property Type</label>
        <div className="input_relative">
          <input
            type="text"
            defaultValue="Residential Apartments"
            className="input"
          />
          <span>
            <img src="images/calendar.svg" alt="" />
          </span>
        </div>
      </div>
      <div className="form_grp">
        <button className="site_btn" type="button">
          Browse Leads
        </button>
      </div>
    </>
  );
}

export default TabContent;
