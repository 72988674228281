import React, { useEffect } from "react";
import BlogPage from "./Blog-page";

import { fetchBlogs } from "../../../states/actions/fetchBlogs";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import MetaGenerator from "../../common/meta-generator";

const Blog = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchBlogs.content);
  const isLoading = useSelector((state) => state.fetchBlogs.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, featured_blog, all_articles, meta } = data;

  useEffect(() => {
    dispatch(fetchBlogs());
  }, []);
  // useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <BlogPage
            content={content}
            featured_blog={featured_blog}
            all_articles={all_articles}
          />
        </>
      )}
    </>
  );
};

export default Blog;
