import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchIndustryDetails } from "../../../states/actions/fetchHome";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import Error from "../Error";
import MetaGenerator from "../../common/meta-generator";
const IndustriesDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchHome.content);
  const isLoading = useSelector((state) => state.fetchHome.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const { industry, meta } = data;
  useEffect(() => {
    dispatch(fetchIndustryDetails(slug));
  }, []);

  // useDocumentTitle(data?.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : industry?.id > 0 ? <>
        <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
        <section className="detail_industries_page">

          <div className="contain">
            <div className="sec_heading text-center">
              <h2>{industry?.main_heading}</h2>
            </div>
          </div>
          <div className="outer_blk">
            <div className="contain">
              <div className="flex">
                <div className="col">
                  <div className="image">
                    <ImageControl
                      folder="industries"
                      src={industry?.section1_image}
                    />
                  </div>
                </div>
                <div className="col">
                  <Text string={industry?.section1_text} />
                </div>
              </div>
              {industry?.section2_status ? (
                <div className="flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl
                        folder="industries"
                        src={industry?.section2_image}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Text string={industry?.section2_text} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="other_txt">
            <div className="contain">
              <div className="flex">
                <div className="col">
                  <div className="inner">
                    <Text string={industry?.section3_text1} />
                  </div>
                </div>
                {industry?.section3_status ? (
                  <div className="col">
                    <div className="inner">
                      <Text string={industry?.section3_text2} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* <h1></h1> */}
          <div className="outer_blk">
            <div className="contain">
              {industry?.section4_status ? (
                <div className="flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl
                        folder="industries"
                        src={industry?.section4_image}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Text string={industry?.section4_text} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {industry?.section5_status ? (
                <div className="flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl
                        folder="industries"
                        src={industry?.section5_image}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Text string={industry?.section5_text} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {industry?.section6_status ? (
                <div className="flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl
                        folder="industries"
                        src={industry?.section6_image}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Text string={industry?.section6_text} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </> : (
        <Error />
      )}
    </>
  );
};
export default IndustriesDetail;
