import React from "react";
import { useForm } from "react-hook-form";
import { saveUsers } from "../../../states/actions/fetchProfileSettings";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";

const SaveUserPopup = ({ handleToggleUserPopup, user }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    dispatch(saveUsers(data));
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );
  return (
    <div className="popup lg active">
      <div className="table_dv">
        <div className="table_cell">
          <div className="_inner">
            <div className="x_btn" onClick={handleToggleUserPopup}></div>
            <h6 className="color">{user ? "Edit " : "Add New "}User</h6>
            <div className="br"></div>
            <form
              onSubmit={handleSubmit(firstSubmit)}
              className="user_add_form"
            >
              <input
                type="hidden"
                value={user ? user?.id : ""}
                {...register("id")}
              />
              <div className="from_row row">
                <div className="col-sm-6">
                  <h6>First Name</h6>
                  <div className="form_blk">
                    <input
                      defaultValue={user && user?.user_fname}
                      type="text"
                      className="input"
                      {...register("user_fname", {
                        required: "First Name is required.",
                        minLength: {
                          value: 2,
                          message:
                            "First Name should contains atleast 2 letters."
                        }
                      })}
                    />
                    <span className="validation-error">
                      {errors.user_fname?.message}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <h6>Last Name</h6>
                  <div className="form_blk">
                    <input
                      defaultValue={user && user?.user_lname}
                      type="text"
                      className="input"
                      {...register("user_lname", {
                        required: "Last Name is required.",
                        minLength: {
                          value: 2,
                          message:
                            "Last Name should contains atleast 2 letters."
                        }
                      })}
                    />
                    <span className="validation-error">
                      {errors.user_lname?.message}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <h6>Email</h6>
                  <div className="form_blk">
                    <input
                      defaultValue={user && user?.user_email}
                      type="text"
                      className="input"
                      {...register("user_email", {
                        required: "Email is required.",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email"
                        }
                      })}
                      readOnly={user && true}
                    />
                    <span className="validation-error">
                      {errors.user_email?.message}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <h6>Phone</h6>
                  <div className="form_blk">
                    <input
                      defaultValue={user && user?.user_phone}
                      type="text"
                      className="input"
                      {...register("user_phone")}
                    />
                  </div>
                </div>
              </div>
              <div className="from_row row permisions_blk">
                <div className="col-sm-12">
                  <h5 className="small_font_user">Manage Permissions</h5>
                  <div className="lbl_btn">
                    <label>Exports Permission</label>
                    <div className="switch">
                      <input
                        defaultChecked={user?.have_permission_exports}
                        type="checkbox"
                        {...register("have_permission_exports")}
                      />
                      <em></em>
                    </div>
                  </div>
                  <div className="lbl_btn">
                    <label>Billing Permission</label>
                    <div className="switch">
                      <input
                        defaultChecked={user?.have_permission_billing}
                        type="checkbox"
                        {...register("have_permission_billing")}
                      />
                      <em></em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_blk text-center">
                <SubmitButton
                  classes="site_btn block"
                  isFormProcessing={isFormProcessing}
                  button_text="Submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveUserPopup;
