import {
  FETCH_ENTERPRISE_CHECKOUT_CONTENT,
  FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS,
  FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED,
  ENTERPRISE_CHECKOUT_MESSAGE,
  ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS,
  ENTERPRISE_CHECKOUT_MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  authToken: localStorage.getItem("authToken"),
  memData: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ENTERPRISE_CHECKOUT_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case ENTERPRISE_CHECKOUT_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS:
      return {
        ...state,
        authToken: payload.authToken,
        isFormProcessing: true
      };
    case ENTERPRISE_CHECKOUT_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
