import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendLink } from "../../../states/actions/fetchForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";

const Form = ({ content }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.fetchForgotPassword.isFormProcessing
  );
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    dispatch(sendLink(data));
  };
  return (
    <>
      <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
        <div className="blk_form">
          <h6>Email</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email"
                }
              })}
            />
            <span className="validation-error">{errors.email?.message}</span>
          </div>
        </div>
        <div className="btn_blk">
          <SubmitButton
            classes="site_btn"
            button_text={content.left_sec_button_text}
            isFormProcessing={isFormProcessing}
          />
        </div>
        <div className="dont_acc">
          <p>
            {content.signup_link_left_heading}{" "}
            <Link to="/signup">{content.signup_link_heading}</Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default Form;
