import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Filters from "../search/Filters-search";
import OwnerFilter from "./Owner-filter";
import TableData from "../search/Table-data";
import MobileAdvance from "../search/Mobile-advance";
import http from "../../../helpers/http";
import LoadingScreen from "../../common/LoadingScreen";
import {
  FETCH_OWNER_PROPERTYS,
  FETCH_OWNER_PROPERTYS_FAIL
} from "../../../states/actions/actionTypes";
import { doObjToFormData } from "../../../helpers/helpers";
import MapComponent from "../search/Map";
const fetchOwnerproperties = async (
  dispatch,
  setownerproperties,
  ownerEmail,
  setOwnerData,
  setStats,
  page
) => {
  http
    .post("/getownerpropertieslist", doObjToFormData({ ownerEmail, page }))
    .then(({ data }) => {
      setownerproperties(data.data);
      setOwnerData(data.owner);
      setStats(data.stats);
      dispatch({
        type: FETCH_OWNER_PROPERTYS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_OWNER_PROPERTYS_FAIL,
        payload: error
      });
    });
};
const OwnerProperties = () => {
  const [hideSearch, setHideSearch] = useState(false);
  const { ownerEmail } = useParams();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  const dispatch = useDispatch();
  const [ownerproperty, setownerproperties] = useState([]);
  const [ownerData, setOwnerData] = useState("");
  const [stats, setStats] = useState("");
  const { propertiesByTypes, key_stats } = stats;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchOwnerproperties(
      dispatch,
      setownerproperties,
      ownerEmail,
      setOwnerData,
      setStats,
      page
    );
  }, [ownerEmail, searchParams]);

  useEffect(() => {
    if (ownerproperty.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ownerproperty]);
  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };

  return (
    <>
      <MobileAdvance />
      <Filters ToggleHideSearch={ToggleHideSearch} hideSearch={hideSearch} />
      <OwnerFilter
        ownerData={ownerData}
        ownerEmail={ownerEmail}
        ToggleHideSearch={ToggleHideSearch}
        hideSearch={hideSearch}
      />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <section
          className={
            hideSearch
              ? "owned_property_page"
              : "owned_property_page with_filter_sec"
          }
        >
          <div className="contain-fluid">
            <h4 className="main_heading">Properties Owned</h4>
            <div className="flex owner_grid">
              <div className="colL">
                <div className="inner">
                  <h6 className="mini_heading">Property Types</h6>
                  <div className="tiles_flex">
                    {propertiesByTypes &&
                      propertiesByTypes.map((propertyTypeStat, i) => (
                        <div className="col" key={i + 12789100000000}>
                          <div className="inner">
                            <p>{propertyTypeStat.title}</p>
                            <h6>
                              ${propertyTypeStat.totalPrice.toLocaleString()} |{" "}
                              {propertyTypeStat.totalProperties.toLocaleString()}{" "}
                              {propertyTypeStat.totalProperties > 1
                                ? " Properties"
                                : "Property"}
                            </h6>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="br"></div>
                  <h6 className="mini_heading">Key Stats</h6>
                  <div className="key_state_flex flex">
                    <div className="col">
                      <div className="_inner">
                        <h6>
                          {key_stats &&
                            key_stats.totalareabuilding.toLocaleString()}{" "}
                          SF
                        </h6>
                        <p>Building Area</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="_inner">
                        <h6>
                          $
                          {key_stats &&
                            key_stats.totalassessedvalue.toLocaleString()}{" "}
                        </h6>
                        <p>Assessed Value</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="_inner">
                        <h6>01</h6>
                        <p>Acquisitions in Past Year</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="_inner">
                        <h6>25 Acres</h6>
                        <p>Total Lot Area</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="_inner">
                        <h6>10</h6>
                        <p>Total Units</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="_inner">
                        <h6>6 Jan 2022</h6>
                        <p>Recent Acquisition</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colR">
                <div
                  style={{
                    position: "relative",
                    height: 300,
                    overflow: "hidden"
                  }}
                >
                  <MapComponent page="owner" filterParcelIds={ownerproperty.map(p => p.parcel_id)} ownerProperties={ownerproperty} mapHeight={300} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <TableData
        data={ownerproperty}
        ownerData={ownerData}
        pageNo={page}
        ownerEmail={ownerEmail}
        setPageNo={setPage}
      />
    </>
  );
};

export default OwnerProperties;
