import React from "react";
import { motion } from "framer-motion";
import ReactCodeInput from "react-verification-code-input";
import {
  verifyEmail,
  resendOtpCode
} from "../../../states/actions/fetchSignup";
import { useDispatch, useSelector } from "react-redux";
import FetchingStatus from "../../common/FetchingStatus";

const Authentication = ({ data, member = false }) => {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.fetchSignup.isFormProcessing
  );
  const isResendingOtp = useSelector(
    (state) => state.fetchSignup.isResendingOtp
  );
  const handleComplete = (data) => {
    let formData = { otp: data, email: member !== false ? member?.mem_email : localStorage.getItem("email") };
    dispatch(verifyEmail(formData));
  };

  const handleResetOtpCode = () => {
    dispatch(resendOtpCode({ email: member !== false ? member?.mem_email : localStorage.getItem("email") }));
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
      >
        <fieldset disabled={isFormProcessing || isResendingOtp}>
          <h4>{data.title3}</h4>
          <p>{data.detail3}</p>
          <div className="blk_form">
            <div class="form_blk">
              <ul className="code_verify">
                <li>
                  <ReactCodeInput
                    autoFocus={true}
                    onComplete={handleComplete}
                  />
                </li>
              </ul>
              {isFormProcessing && (
                <FetchingStatus message="Verifying your email. Please wait..." />
              )}
              {isResendingOtp && (
                <FetchingStatus message="Resending otp. Please wait..." />
              )}
            </div>
          </div>
          <div className="dont_acc">
            <p>
              Didn’t get code?{" "}
              <a href="javascript:void(0)" onClick={handleResetOtpCode}>
                Resend code
              </a>
            </p>
          </div>
          {/* <div className="br"></div>
          <div className="btn_blk">
            <button
              className="site_btn"
              type="button"
              onClick={() => {
                alert("You've successfully submitted this form");
              }}
            >
              Verify
            </button>
            </div> */}
        </fieldset>
      </motion.div>
    </>
  );
};

export default Authentication;
