import React, { useState, useRef, useEffect } from "react";
import FiltersSearch from "./Filters-search";
import MobileAdvance from "./Mobile-advance";
import SearchMap from "./Search-map";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

const SearchProperty = () => {
  const [searchParams] = useSearchParams();
  const search_keyword = searchParams.get("search_p");
  const [hideSearch, setHideSearch] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [resultView, setResultView] = useState(true);
  const [drawnFeatures, setDrawnFeatures] = useState(null);

  const searchRef = useRef(null);
  const handleSearchClick = () => {
    if (searchRef.current) {
      searchRef.current.click();
    }
  };
  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };

  const Maptoggle = () => {
    setShowMap(!showMap);
  };
  useDocumentTitle("Search Properties -  Rhino Recon");
  return (
    <>
      <MobileAdvance />

      <FiltersSearch
        ToggleHideSearch={ToggleHideSearch}
        Maptoggle={Maptoggle}
        hideSearch={hideSearch}
        showMap={showMap}
        setShowMap={setShowMap}
        drawnFeatures={drawnFeatures}
        setDrawnFeatures={setDrawnFeatures}
        searchRef={searchRef}
        search_keyword={search_keyword}
      />

      <SearchMap
        hideSearch={hideSearch}
        showMap={showMap}
        setDrawnFeatures={setDrawnFeatures}
        drawnFeatures={drawnFeatures}
        handleSearchClick={handleSearchClick}
      />
    </>
  );
};

export default SearchProperty;
