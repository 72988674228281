import { TOGGLE_SHOW_SEARCH_RESULTS } from "../actions/actionTypes";

const initialState = {
  showSearchResults: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_SHOW_SEARCH_RESULTS:
      return {
        ...state,
        showSearchResults: payload
      };
    default:
      return state;
  }
}
