import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function usePreviousURLParams() {
    const [previousURLParams, setPreviousURLParams] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const referrerURL = document.referrer;
        if (referrerURL) {
            const url = new URL(referrerURL);
            setPreviousURLParams(url?.pathname);
        }
    }, []);

    return previousURLParams;
}

export default usePreviousURLParams;
