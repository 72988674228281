import {
  FETCH_SAVED_SEARCHES_CONTENT,
  FETCH_SAVED_SEARCHES_CONTENT_SUCCESS,
  FETCH_SAVED_SEARCHES_CONTENT_FAILED,
  FETCH_SAVED_SEARCHES_HEADER,
  FETCH_SAVED_SEARCHES_HEADER_SUCCESS,
  FETCH_SAVED_SEARCHES_HEADER_FAILED,
  DELETE_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY_SUCCESS,
  DELETE_SEARCH_HISTORY_FAILED,
  UPDATE_SEARCH_HISTORY,
  UPDATE_SEARCH_HISTORY_SUCCESS,
  UPDATE_SEARCH_HISTORY_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isProcessing: false,
  isFetchingForHeader: false,
  content: {},
  searches: {},
  searchesHeader: {},
  error: false,
  sort: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SAVED_SEARCHES_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SAVED_SEARCHES_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searches: payload.searches,
        sort: payload?.sort,
        content: payload,
      };
    case FETCH_SAVED_SEARCHES_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SAVED_SEARCHES_HEADER:
      return {
        ...state,
        isFetchingForHeader: true,
      };
    case FETCH_SAVED_SEARCHES_HEADER_SUCCESS:
      return {
        ...state,
        isFetchingForHeader: false,
        searchesHeader: payload.searchesHeader,
      };
    case FETCH_SAVED_SEARCHES_HEADER_FAILED:
      return {
        ...state,
        isFetchingForHeader: false,
        error: payload,
      };
    case DELETE_SEARCH_HISTORY:
      return {
        ...state,
        isProcessing: true,
      };
    case DELETE_SEARCH_HISTORY_SUCCESS:
      return {
        ...state,
        searches: payload.searches,
        isProcessing: false,
      };
    case DELETE_SEARCH_HISTORY_FAILED:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    case UPDATE_SEARCH_HISTORY:
      return {
        ...state,
        isProcessing: true,
      };
    case UPDATE_SEARCH_HISTORY_SUCCESS:
      return {
        ...state,
        searches: payload.searches,
        isProcessing: false,
      };
    case UPDATE_SEARCH_HISTORY_FAILED:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    default:
      return state;
  }
}
