import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SIGN_UP_CONTENT,
  FETCH_SIGN_UP_CONTENT_SUCCESS,
  FETCH_SIGN_UP_CONTENT_FAILED,
  CREATE_ACCOUNT_MESSAGE,
  CREATE_ACCOUNT_MESSAGE_SUCCESS,
  CREATE_ACCOUNT_MESSAGE_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  RESEND_OTP_CODE,
  RESEND_OTP_CODE_SUCCESS,
  RESEND_OTP_CODE_FAILED,
  SWITCH_SIGNUP_STEPS
} from "./actionTypes";

export const fetchSignup = () => (dispatch) => {
  dispatch({
    type: FETCH_SIGN_UP_CONTENT,
    payload: null
  });
  http
    .get("signup-page")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_FAILED,
        payload: error
      });
    });
};

export const createAccount = (formData) => (dispatch) => {  
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: CREATE_ACCOUNT_MESSAGE,
    payload: null
  });
  http
    .post("create-account", formData)
    .then(({ data }) => {
      if (data.status) {
        // toast.success(
        //   "You are registered successfully. And We’ve sent a OTP code to your email which will be exipre after 12 hours. If you don’t see the email, check your spam folder.",
        //   TOAST_SETTINGS
        // );
        toast.success(
          "You have registered successfully. Redirectly...",
          TOAST_SETTINGS
        );
        dispatch({
          type: CREATE_ACCOUNT_MESSAGE_SUCCESS,
          payload: data
        });
        dispatch({
          type: SWITCH_SIGNUP_STEPS,
          payload: "next"
        });
        // setTimeout(() => {
        //   window.location.replace("/dashboard");
        // }, 3000);
      } else {
        if (data.msg) {
          if (data.back_to_first) {
            dispatch({
              type: SWITCH_SIGNUP_STEPS,
              payload: "back"
            });
          }
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: CREATE_ACCOUNT_MESSAGE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const swithSignupSteps = (type) => (dispatch) => {
  dispatch({
    type: SWITCH_SIGNUP_STEPS,
    payload: type
  });
};

export const verifyEmail = (formData) => (dispatch) => {
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: VERIFY_EMAIL,
    payload: null
  });
  http
    .post("verify-email", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "The email has been verified successfully. Redirecting…",
          TOAST_SETTINGS
        );
        dispatch({
          type: VERIFY_EMAIL_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.replace("/dashboard");
        }, 2500);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: VERIFY_EMAIL_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_EMAIL_FAILED,
        payload: error
      });
    });
};

export const resendOtpCode = (formData) => (dispatch) => {
  console.log(formData)
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: RESEND_OTP_CODE,
    payload: null
  });
  http
    .post("resend-otp-code-email", formData)
    .then(({ data }) => {
      console.log(data)
      if (data.status) {
        toast.success("New OTP has been sent to your email.", TOAST_SETTINGS);
        dispatch({
          type: RESEND_OTP_CODE_SUCCESS,
          payload: data
        });
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: RESEND_OTP_CODE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: RESEND_OTP_CODE_FAILED,
        payload: error
      });
    });
};
