import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const PropertyPost = ({ data }) => {
  const option = {
    margin: 20,
    loop: true,
    dots: false,
    nav: true,
    smartSpeed: 1000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2
      },
      580: {
        items: 2
      },
      991: {
        items: 2
      },
      1200: {
        items: 3
      }
    }

  };
  return (
    <>
      <section className="property_post">
        <div className="contain">
          <h4 className="light_heading f_s_24">{data.heading}</h4>
          <OwlCarousel
            id="owl-post"
            className="owl-carousel owl-theme brand_lst"
            {...option}
          >
            {data.post?.map((val, i) => {
              return (
                <div className="item" key={i}>
                  <div className="inner">
                    <Link to={"/dashboard-blog-detail/" + val?.encoded_id + "/" + val?.slug}></Link>
                    <div className="image">
                      <img src={val.image} alt="" />
                    </div>
                    <div className="cntnt">
                      <h4>{val.title}</h4>
                      {
                        val?.detail ?
                          <Text string={val?.detail} length={80} />
                          :
                          ""
                      }
                    </div>
                  </div>
                </div>
              );
            })}

          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default PropertyPost;
