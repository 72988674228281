import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleChangePasswordPopupAction,
  toggleConfirmDeleteAccountPopupAction
} from "../../../states/actions/fetchProfileSettings";
import ChangePasswordPopup from "./ChangePasswordPopup";
import ConfimDeletePassword from "./confirmDeletePopup";

const MyAccount = () => {
  const dispatch = useDispatch();

  const toggleChangePasswordPopup = () => {
    dispatch(toggleChangePasswordPopupAction());
  };
  const toggleConfirmDeleteAccountPopup = () => {
    dispatch(toggleConfirmDeleteAccountPopupAction());
  };

  const isChangePasswordPopupShowing = useSelector(
    (state) => state.fetchProfileSettings.isChangePasswordPopupShowing
  );
  const isDeleteAccountPopupShowing = useSelector(
    (state) => state.fetchProfileSettings.isDeleteAccountPopupShowing
  );
  const mem = useSelector((state) => state.fetchProfileSettings.mem);
  return (
    <>
      <div className="setting_blk wide_sm">
        <h4>My Account</h4>
        <form action="">
          <div className="from_row row">
            <div className="col-sm-12">
              <div className="blk_form">
                <h6>Email</h6>
                <div className="form_blk email_verify">
                  <input
                    type="text"
                    defaultValue={mem?.mem_email}
                    className="input"
                    disabled={true}
                  />
                  <span className="verify_lbl">Verified</span>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="blk_form">
                <h6>Password</h6>
                <div className="form_blk">
                  <button
                    className="input pass_lnk"
                    type="button"
                    onClick={toggleChangePasswordPopup}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12" hidden>
              <div className="blk_form">
                <div className="switch_flex">
                  <div className="cntnt">
                    <h6>Enable 2-steps verification</h6>
                    <div className="dim_lbl">
                      Make your account extra secure. Along with your password,
                      you’ll need to enter a code that we text to your phone
                      each time you sign in.
                    </div>
                  </div>
                  <div className="switch">
                    <input type="checkbox" name="part" id="part-time" />
                    <em></em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="link_accnt" hidden>
            <h5 className="sub_acc_heading">Linked Accounts</h5>
            <div className="dim_lbl1">
              We use this to let you sign in easily.
            </div>
            <div className="lnk_acc_flex">
              <div className="social_img">
                <img src="/images/google.svg" alt="" />
              </div>
              <h6>Sign in with Google</h6>
              <div className="btn_blk">
                <button className="site_btn blank yellow_blank">Remove</button>
              </div>
            </div>
          </div>
          <div className="divide"></div>
          <div className="link_accnt">
            <div className="lnk_del_flex">
              <div className="cntnt">
                <h5 className="sub_acc_heading">Delete Account</h5>
                <div className="dim_lbl1">
                  Delete your account and all the data
                </div>
              </div>
              <div className="btn_blk">
                <button
                  className="site_btn blank red_blank"
                  type="button"
                  onClick={toggleConfirmDeleteAccountPopup}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
          <div className="divide"></div>
        </form>
      </div>

      {isChangePasswordPopupShowing && (
        <ChangePasswordPopup
          toggleChangePasswordPopup={toggleChangePasswordPopup}
        />
      )}
      {isDeleteAccountPopupShowing && (
        <ConfimDeletePassword
          toggleConfirmDeleteAccountPopup={toggleConfirmDeleteAccountPopup}
        />
      )}
    </>
  );
};

export default MyAccount;
