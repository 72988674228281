import React, { useEffect, useState } from "react";
import CommercialTabs from "./Commercial-tabs";
import ResidentialTabs from "./Residential-tabs";
import Text from "../../common/Text";
import Industrial from "./industrial";
import Land from "./land";

function Intro({ data, content, key_index }) {
  const [currentTab, setCurrentTab] = useState(1);
  const handleTabClick = (val) => {
    setCurrentTab(val);
  };

  const commercial_tabs = {
    button_link: "/",
    image: "/images/commercial.svg",
    play_img: "/images/play.svg",
    play_title: "Virtual builidng inspection",
    play_txt: "View the property in detail",
    home_img: "/images/home.svg",
    home_title: "Be more informed",
    home_txt: "Contact your prospect with confidence.",
    title: "Access property records for commercial properties",
    detail: "Identify target property owners with contact information",
    button_label: "Free Trial ",
  };
  return (
    <>
      <section className="type_sec">
        <div className="contain">
          <div className="sec_heading text-center">
            <h4>{content?.section2_main_heading}</h4>
            <div className="br"></div>
          </div>
          <div className="outer_nav_tabs">
            <ul className="nav nav-tabs">
              <li key={key_index + 1100000}>
                <button
                  key={key_index + 1100000}
                  id={key_index + 1100000}
                  disabled={currentTab === 1}
                  onClick={() => handleTabClick(1)}
                >
                  <Text string={content?.section2_left_section_heading} />
                </button>
              </li>
              <li key={key_index + 11000000}>
                <button
                  key={key_index + 11000000}
                  id={key_index + 11000000}
                  disabled={currentTab === 2}
                  onClick={() => handleTabClick(2)}
                >
                  <Text string={content?.section2_right_section_heading} />
                </button>
              </li>
              <li key={key_index + 11000000}>
                <button
                  key={key_index + 11000000}
                  id={key_index + 11000000}
                  disabled={currentTab === 3}
                  onClick={() => handleTabClick(3)}
                >
                  {content?.section2_industrial_section_heading_main}
                </button>
              </li>
              <li key={key_index + 11000000}>
                <button
                  key={key_index + 11000000}
                  id={key_index + 11000000}
                  disabled={currentTab === 4}
                  onClick={() => handleTabClick(4)}
                >
                  {content?.section2_land_section_heading_main}
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            {currentTab == 1 && (
              <>
                <div key={key_index + 110000000}>
                  <div>
                    <ResidentialTabs content={content} />
                  </div>
                </div>
              </>
            )}
            {currentTab == 2 && (
              <>
                <div key={key_index + 110000000}>
                  <div>
                    <CommercialTabs data={commercial_tabs} content={content} />
                  </div>
                </div>
              </>
            )}
            {currentTab == 3 && (
              <>
                <div key={key_index + 110000000}>
                  <div>
                    <Industrial content={content} />
                  </div>
                </div>
              </>
            )}
            {currentTab == 4 && (
              <>
                <div key={key_index + 110000000}>
                  <div>
                    <Land content={content} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Intro;
