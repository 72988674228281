import React from "react";
import Form from "./Form";

const Intro = ({ data, content, industries, primary_interests }) => {
  return (
    <>
      <div className="colL">
        <div className="inner">
          <Form data={data} content={content} industries={industries} primary_interests={primary_interests} />
        </div>
      </div>
    </>
  );
};

export default Intro;
