import React from "react";

const Banner = ({ content }) => {
  return (
    <>
      {content && (
        <section className="data_banner blank_banner">
          <div className="dim_img">
            <img src="/images/half_rahino.svg" alt="" />
          </div>
          <div className="contain">
            <h3>{content?.page_title}</h3>
          </div>
        </section>
      )}
    </>
  );
};

export default Banner;
