import React from "react";
import Form from "./Form";

const Intro = ({ content, primary_interests, industries, handleEnterpriseSubmit, isFormProcessing, member }) => {
  return (
    <>
      <div className="colL">
        <div className="inner">
          <h4>{content.left_sec_heading}</h4>
          <p>{content.left_sec_text}</p>
          <Form content={content} primary_interests={primary_interests} industries={industries} handleEnterpriseSubmit={handleEnterpriseSubmit} isFormProcessing={isFormProcessing} member={member} />
        </div>
      </div>
    </>
  );
};

export default Intro;
