import React from "react";
import Faq from "react-faq-component";
import Text from "../../common/Text";

const Faqs = ({ content, faqs }) => {
  return (
    <>
      {content && (
        <section className="faq">
          <div className="contain">
            <div className="text-center">
              <h4>
                <Text string={content.faq_heading} />
              </h4>
            </div>
            <Faq data={faqs} />
          </div>
        </section>
      )}
    </>
  );
};

export default Faqs;
