import React from "react";
import ImageControl from "../../common/ImageControl";

const Banner = ({ content }) => {
  return (
    <>
      <section className="data_banner">
        <div className="dim_img">
          <img src="/images/half_rahino.svg" alt="" />
        </div>
        <div className="contain">
          <div className="upload_icon">
            <ImageControl src={content.image1} folder="images" />
          </div>
          <h3>{content.banner_heading}</h3>
          <p>{content.banner_text}</p>
        </div>
      </section>
    </>
  );
};

export default Banner;
