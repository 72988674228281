import * as React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
const PropertyChart = ({ labels, datasets }) => {
  const data = {
    labels,
    datasets: [
      {
        data: datasets,
        backgroundColor: [
          "#2F2300",
          "#DFC7B0",
          "#67773F",
          "#f4f59e",
          "#9fa3e8",
          "#eca6cb",
          "#ee9a9f"
        ],
        borderWidth: 0
      }
    ]
  };
  return (
    <>
      <Doughnut
        options={{
          cutout: 80,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
                pointStyle: "rect",
                padding: 10
              }
            }
          }
        }}
        data={data}
      />
    </>
  );
};

export default PropertyChart;
