import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../../states/actions/fetchResetPassword";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";
import ShowPasswordIcon from "../../common/ShowPasswordIcon";

const Form = ({ content }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const isFormProcessing = useSelector(
    (state) => state.fetchResetPassword.isFormProcessing
  );
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    data = { ...data, resetToken: token };
    dispatch(resetPassword(data));
  };
  return (
    <>
      <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
        <div className="blk_form">
          <h6>Password</h6>
          <div className="form_blk pass_blk">
            <input
              type={showPassword ? "text" : "password"}
              className="input"
              placeholder="Enter password"
              {...register("password", {
                required: "Password is required.",
                minLength: {
                  value: 6,
                  message: "Password should be atleast 6 characters long."
                }
              })}
            />
            <span className="validation-error">{errors.password?.message}</span>
            <ShowPasswordIcon
              showPassword={showPassword}
              handleShowPassword={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Confirm Password</h6>
          <div className="form_blk pass_blk">
            <input
              type={showPassword2 ? "text" : "password"}
              className="input"
              placeholder="Enter password again"
              {...register("confirm_password", {
                required: "Confirm Password is required.",
                validate: (val) => {
                  if (watch("password") != val) {
                    return "Your passwords do no match.";
                  }
                },
                minLength: {
                  value: 6,
                  message: "Password should be atleast 6 characters long."
                }
              })}
            />
            <ShowPasswordIcon
              showPassword={showPassword2}
              handleShowPassword={() => setShowPassword2(!showPassword2)}
            />
            <span className="validation-error">
              {errors.confirm_password?.message}
            </span>
          </div>
        </div>
        <div className="forgot">
          <Link to="/forgot-password">Get Link Again?</Link>
        </div>
        <div className="btn_blk">
          <SubmitButton
            classes="site_btn"
            button_text={content.left_sec_button_text}
            isFormProcessing={isFormProcessing}
          />
        </div>
        <div className="dont_acc">
          <p>
            {content.signup_link_left_heading}{" "}
            <Link to="/signup">{content.signup_link_heading}</Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default Form;
