import React from "react";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const Intro = ({ content }) => {
  return (
    <>
      {content && (
        <section className="about">
          <div className="contain">
            <div className="main_image">
              <ImageControl src={content.image1} folder="images" />
            </div>
            <div className="main_cntnt">
              <h2>
                <Text string={content.banner_heading} />
              </h2>
              <p>
                <Text string={content.banner_text} />
              </p>
            </div>
            <div className="abt_flex flex">
              <div className="colL">
                <h2>
                  <Text string={content.section2_heading} />
                </h2>
                <Text string={content.section2_text} />
              </div>
              <div className="colR">
                <div className="image">
                  <ImageControl src={content.image2} folder="images" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Intro;
