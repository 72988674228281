import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoRecrdFound from "../../common/NoRecordFound";
import {
  toggleUserPopupAction,
  deleteUser
} from "../../../states/actions/fetchProfileSettings";

import { getInvitationStatus, getWordInitials } from "../../../helpers/helpers";
import SaveUser from "./SaveUserPopup";

const Users = () => {
  const dispatch = useDispatch();
  const [actionDropdown, setActionDropdown] = useState(0);
  const [user, setUser] = useState(null);
  const users = useSelector((state) => state.fetchProfileSettings.users);
  const showUserPopup = useSelector(
    (state) => state.fetchProfileSettings.showUserPopup
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );

  const handleActionDropdown = (id) => {
    if (actionDropdown === id) setActionDropdown(0);
    else setActionDropdown(id);
  };

  const handleSetUser = (index) => {
    handleToggleUserPopup();
    setUser(users[index]);
  };

  const handleToggleUserPopup = () => {
    setUser(null);
    dispatch(toggleUserPopupAction());
  };

  const handleDeleteUser = (id) => {
    dispatch(deleteUser({ id }));
  };

  useEffect(() => {
    users && users.map((user) => console.log(user.mem_fname));
  }, [users]);

  return (
    <>
      <div className="setting_blk cell_min_padding" disabled={isFormProcessing}>
        <div className="flex_user_head">
          <h4>Users</h4>
          <div className="btn_blk">
            <button className="site_btn" onClick={handleToggleUserPopup}>
              Add
            </button>
          </div>
        </div>
        <div className="table_blk">
          <div className="inner_tbl">
            <table className="cell_hide_dowload_lbl users_table">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users && users.length > 0 ? (
                  users.map((user, i) => (
                    <tr key={i+211000000000}>
                      <td>{++i}</td>
                      <td>
                        <div className="user_icon_flex flex">
                          <div className="icon_lbl profile_btn">
                            {getWordInitials(
                              `${user.user_fname} ${user.user_lname}`
                            )}
                          </div>
                          <p>{`${user.user_fname} ${user.user_lname}`}</p>
                        </div>
                      </td>
                      <td>{user.user_email}</td>
                      <td>{user.user_phone}</td>
                      <td>{getInvitationStatus(user.subuser_status)} </td>
                      <td>
                        <div className="drop">
                          <button
                            className="drop_btn"
                            onClick={() => handleActionDropdown(user.id)}
                          >
                            <img src="/images/three-dots.png" alt="" />
                          </button>
                          <div
                            className={
                              actionDropdown === user.id ? "sub active" : "sub"
                            }
                          >
                            <ul className="lst">
                              <li>
                                <button
                                  type="button"
                                  className="red"
                                  onClick={() => handleDeleteUser(user.id)}
                                  disabled={isFormProcessing}
                                >
                                  Delete
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() => handleSetUser(--i)}
                                  isFormProcessing={isFormProcessing}
                                >
                                  Manage User
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <NoRecrdFound message="No user found" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showUserPopup && (
        <SaveUser handleToggleUserPopup={handleToggleUserPopup} user={user} />
      )}
    </>
  );
};

export default Users;
