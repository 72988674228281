import React, { useEffect } from "react";
import Banner from "./saved-searches/Banner";
import { ToastContainer, toast } from "react-toastify";

import { fetchSavedSearches } from "../../states/actions/fetchSavedSearches";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import { Link } from "react-router-dom";
import http from "../../helpers/http";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { FETCH_WISHLIST_CONTENT_SUCCESS } from "../../states/actions/actionTypes";
import Text from "../common/Text";

const Favorites = ({ member }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchSavedSearches.content);
    const isLoading = useSelector((state) => state.fetchSavedSearches.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    const handleLikeClick = (e, propertyData, dispatch) => {
        e.preventDefault();
        if (!propertyData) {
            return; // or show an error message
        }
        let elem = e.target;
        //const { property_id } = propertyData; // or whatever property ID field name you have

        http
            .post("/removeuserwishlist", propertyData)
            .then((response) => {
                console.log(response?.data)
                if (response?.data?.status) {

                    toast.success(response.data.msg, TOAST_SETTINGS);

                }
                else {
                    toast.error(response.data.msg, TOAST_SETTINGS);
                }
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
                elem.classList.toggle("active");
                elem.parentNode.classList.toggle("active");

                dispatch({
                    type: FETCH_WISHLIST_CONTENT_SUCCESS,
                    payload: response
                });
            })
            .catch((error) => {
                toast.error(<Text id="wishlist.error" />, TOAST_SETTINGS);
            });
    };
    useEffect(() => {
        dispatch(fetchSavedSearches());
    }, []);
    const wishlist_properties = data?.wishlist_properties
    useDocumentTitle('Favorites');

    return (
        <>
            {isLoading || isSiteSettingsLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <ToastContainer />
                    {/* <Banner content={content} /> */}
                    {
                        wishlist_properties?.length > 0 ?
                            <section className="property_list_grid property_list_sec favorite_property_grid">
                                <div className="contain-fluid">
                                    <h4 className="light_heading f_s_24">Favorite Properties</h4>
                                    <div className="flex">
                                        {wishlist_properties?.map((property, i) => {
                                            return (
                                                <div className="_col" key={i + 78965412789100000000}>
                                                    {property.popular == "true" ? (
                                                        <div className="property_badge">
                                                            <img src="/images/star.svg" alt="" />
                                                            <span>POPULAR</span>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <div className="inner">
                                                        <Link
                                                            to={`/property-details/${property.property_id}`}
                                                            className="abst_link"
                                                        ></Link>
                                                        <div className="cntnt">
                                                            <h4 className="black_text color_text f_s_24">
                                                                ${Number(property.price)?.toLocaleString()} <sub>| {property.date}</sub>
                                                            </h4>
                                                            <h4 className="bold_text f_s_24">{property.title}</h4>
                                                            <p>{property.address}</p>
                                                            <div className="like_btn"></div>
                                                        </div>

                                                        <div className="divide_line"></div>
                                                        <ul>
                                                            {property.accommodation?.map((acc, index) => {
                                                                return (
                                                                    <li key={index + 1278910000000065435}>
                                                                        <img src={acc.img} alt={acc.accommo} />
                                                                        <span
                                                                            dangerouslySetInnerHTML={{ __html: acc.accommo }}
                                                                        />
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                        <div className="cta_opt_lbl">
                                                            <button
                                                                type="button"
                                                                onClick={(e) => handleLikeClick(e, property.property_id, dispatch)
                                                                }
                                                                className={property.is_wish == 1 ? 'active' : ""}
                                                            >
                                                                <img

                                                                    src="/images/heart_ico.svg"
                                                                    alt=""
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </section>
                            :
                            ""
                    }
                </>
            )}
        </>
    );
};

export default Favorites;
