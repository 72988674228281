import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_SITE_SETTINGS,
  FETCH_SITE_SETTINGS_SUCCESS,
  FETCH_SITE_SETTINGS_FAILED,
  UPDATE_USER_CREDITS
} from "./actionTypes";

export const fetchSiteSettings = () => (dispatch) => {
  dispatch({
    type: FETCH_SITE_SETTINGS,
    payload: null
  });
  http
    .post(
      "site-settings",
      doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      if(localStorage.getItem("authToken") && (data?.not_logged || (data?.member===null || data?.member===undefined || data?.member==='' || data?.member===false))){
        
        localStorage.removeItem("authToken");
        window.location.relaod()
      }
      dispatch({
        type: FETCH_SITE_SETTINGS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SITE_SETTINGS_FAILED,
        payload: error
      });
    });
};
export const updateMEmberCredits = (credits) => (dispatch) => {
  console.log(credits,'action')
  dispatch({
    type: UPDATE_USER_CREDITS,
    payload: credits
  });
};
