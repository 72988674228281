import React from "react";
import { price_format } from "../../../helpers/helpers";
import Text from "../../common/Text";

const PricingCard = ({ plan }) => {
  return (
    <>
      <div className="col col_p">
        <div className={plan?.best_status === 1 ? "inner active" : "inner"}>
          <div className="head_pricing">
            <h5>{plan.plan_name}</h5>
            <h2 className="big_price">
              {plan.plan_interval === "year" ? (
                <>
                  {price_format(plan.annual_price)}
                  <sub>/ mo</sub>
                </>
              ) : (
                <>
                  {price_format(plan.price)}
                  <sub>/ {plan.plan_interval === "year" ? "yr" : "mo"}</sub>
                </>
              )}
            </h2>
            <p>{plan.tagline}</p>
            {/* {plan?.plan_interval === "year" ? (
              <p>{price_format(parseFloat(plan.price) / 12)} / mo</p>
            ) : (
              // :
              // plan?.plan_alternative?.plan_interval === 'month' ?
              //   <p>{price_format(plan?.plan_alternative?.price)} / mo</p>
              ""
            )} */}
          </div>
          <div className="price_body">
            {/* <Text string={plan.description} /> */}
            <ul>
              <li>
                {parseInt(plan?.clicks_count)} <span>clicks</span>
              </li>
              <li>
                {parseInt(plan?.downloads_count)} <span>downloads</span>
              </li>
              <li>
                {parseInt(plan?.user_count)} <span>User</span>
              </li>
            </ul>
          </div>
          <div className="price_footer">
            {/* {plan.plan_interval === "year" ? ( */}
            <>
              <h4>
                <strong>Total: </strong>
                {price_format(plan.price)}
                <sub>/ {plan.plan_interval === "year" ? "yr" : "mo"}</sub>
              </h4>
              {/* <h6>{plan?.annual_price_sub_title}</h6> */}
            </>
            {/* ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCard;
