import React from "react";
import Text from "../../common/Text";

const States = ({ content }) => {
  return (
    <>
      <section className="abt_states">
        <div className="contain">
          <div className="flex">
            <div className="col" key={100000000}>
              <h5>
                <Text string={content.section3_upper_heading_1} />
              </h5>
              <p>
                <Text string={content.section3_upper_text_1} />{" "}
              </p>
            </div>
            <div className="col" key={2100000000}>
              <h5>
                <Text string={content.section3_upper_heading_2} />
              </h5>
              <p>
                <Text string={content.section3_upper_text_2} />{" "}
              </p>
            </div>
            <div className="col" key={3100000000}>
              <h5>
                <Text string={content.section3_upper_heading_3} />
              </h5>
              <p>
                <Text string={content.section3_upper_text_3} />{" "}
              </p>
            </div>
            <div className="col" key={4100000000}>
              <h5>
                <Text string={content.section3_upper_heading_4} />
              </h5>
              <p>
                <Text string={content.section3_upper_text_4} />{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default States;
