import React, { useEffect } from "react";
import Intro from "./Intro";
import Card from "./Card";
import { useParams } from "react-router-dom";
import { fetchEnterpriseCheckout, postEnterpriseCheckout } from "../../../states/actions/fetchEnterpriseCheckout";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import MetaGenerator from "../../common/meta-generator";
const EnterpriseCheckout = ({ member }) => {
  const { plan_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchEnterpriseCheckout.content);
  const isLoading = useSelector(
    (state) => state.fetchEnterpriseCheckout.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchEnterpriseCheckout.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, primary_interests, industries, meta } = data;

  useEffect(() => {
    dispatch(fetchEnterpriseCheckout());
  }, []);
  const handleEnterpriseSubmit = (formData) => {
    let newFormData = { ...formData, plan_id: plan_id }
    dispatch(postEnterpriseCheckout(newFormData));
  };
  // useDocumentTitle(data.page_title);
  const log = {
    card: {
      image: "/images/property.png",
      popLbl: "POPULAR",
      infoLbl: "Also owns",
      infoLnkLbl: "3 other properties",
      infoLnk: "/",
      phone: "+1234567890",
      email: "john@doe.com",
      position: "Owner",
      name: "John Doe",
      btnLbl: "Contact Now",
      btnLnk: "/"
    },
    intro: {
      title: "Enterprise",
      detail: "Fill out a form and submit to the managment."
    },
    right_detail: {
      details:
        "<h3>Why Rhino Recon?</h3><p>Find more opportunities, connect with owners instantly, and analyze properties with ease.</p><ul><li>Our interactive map will help you find target properties.</li><li>Get the right data, including sales, tax, and debt details.</li><li>Find property owners and talk to the right person in seconds.</li></ul>"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <section className="logon">
            <div className="contain">
              <div className="flex">
                <Intro content={content} primary_interests={primary_interests} industries={industries} handleEnterpriseSubmit={handleEnterpriseSubmit} isFormProcessing={isFormProcessing} member={member} />
                <div className="colR self_center">
                  <Text string={content.right_sec_text} />
                  <div className="br"></div>
                  <Card data={log.card} />
                </div>
              </div>
            </div>
            <div className="lg_image">
              <ImageControl src={content.image1} folder="images" />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EnterpriseCheckout;
