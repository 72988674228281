import React from "react";
import { Link } from "react-router-dom";

const Strip = (props) => {
  const { activePage, mobActive, activeApply } = props;
  return (
    <>
      <div
        className={mobActive ? "bread_crumb active not_move" : "bread_crumb"}
      >
        <div className="contain-fluid">
          <p className="hide_cell_bread">Account Setting</p>
          <Link
            onClick={() => activeApply()}
            to="#"
            className="hide_desk_bread"
          >
            <img src="/images/back_angle.svg" alt="" />
            <span>
              {activePage == "Profile" && "Profile"}
              {activePage == "MyAccount" && "My Account"}
              {activePage == "Notifications" && "Notfications"}
              {activePage == "MembershipManagement" && "Membership management"}
              {activePage == "Exports" && "Exports"}
              {activePage == "Billing" && "Billing"}
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Strip;
