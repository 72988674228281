import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_HELP_DETAIL_CONTENT,
  FETCH_HELP_DETAIL_CONTENT_SUCCESS,
  FETCH_HELP_DETAIL_CONTENT_FAILED,
  SEARCH_HELP_RESULT_CONTENT,
  SEARCH_HELP_RESULT_CONTENT_SUCCESS,
  SEARCH_HELP_RESULT_CONTENT_FAILED
} from "./actionTypes";

export const fetchHelpDetail = (post) => (dispatch) => {
  dispatch({
    type: FETCH_HELP_DETAIL_CONTENT,
    payload: null
  });
  http
    .post("help-result", helpers.doObjToFormData(post))
    .then(({ data }) => {
      dispatch({
        type: FETCH_HELP_DETAIL_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_HELP_DETAIL_CONTENT_FAILED,
        payload: error
      });
    });
};

export const searchHelpResults = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: SEARCH_HELP_RESULT_CONTENT,
    payload: null
  });
  http
    .post("search-help-results", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: SEARCH_HELP_RESULT_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_HELP_RESULT_CONTENT_FAILED,
        payload: error
      });
    });
};
