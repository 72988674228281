import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FetchingStatus from "../../common/FetchingStatus";
import NoRecordFound from "../../common/NoRecordFound";

const Questions = () => {
  const isSearching = useSelector((state) => state.fetchHelp.isSearching);
  const help_records = useSelector((state) => state.fetchHelp.help_records);

  return (
    <>
      <section className="question_sec">
        <div className="contain">
          {isSearching ? (
            <FetchingStatus />
          ) : (
            <div className="flex">
              {help_records.length > 0 ? (
                help_records?.map((row, i) => {
                  return (
                    <div className="col" key={i+9876100000000}>
                      <h4>{row.cat_title}</h4>
                      <ul>
                        {row.questions?.map((question, i) => {
                          return (
                            <li>
                              <Link to={`/help-result/${question.encoded_id}`}>
                                {question.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })
              ) : (
                <NoRecordFound />
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Questions;
