import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_HOME_CONTENT,
  FETCH_HOME_CONTENT_SUCCESS,
  FETCH_HOME_CONTENT_FAILED,
  FETCH_INDUSTRIES_CONTENT,
  FETCH_INDUSTRIES_CONTENT_SUCCESS,
  FETCH_INDUSTRIES_CONTENT_FAILED,
  FETCH_INDUSTRIES_DETAILS_CONTENT,
  FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS,
  FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED,
  SAVE_JOB_HOME_1,
  SAVE_JOB_HOME_1_SUCCESS,
  SAVE_JOB_HOME_1_FAILED,
  SAVE_JOB_HOME_2,
  SAVE_JOB_HOME_2_SUCCESS,
  SAVE_JOB_HOME_2_FAILED,
  JOB_APPLIED_HOME_1,
  JOB_APPLIED_HOME_1_SUCCESS,
  JOB_APPLIED_HOME_1_FAILED,
  JOB_APPLIED_HOME_2,
  JOB_APPLIED_HOME_2_SUCCESS,
  JOB_APPLIED_HOME_2_FAILED,
  REPORT_AN_JOB_HOME,
  REPORT_AN_JOB_HOME_SUCCESS,
  REPORT_AN_JOB_HOME_FAILED,
  REPORT_AN_JOB_HOME_POPUP,
} from "./actionTypes";

export const fetchHome = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_HOME_CONTENT,
    payload: null,
  });
  http
    .post(
      "home-page",
      helpers.doObjToFormData({
        token: localStorage.getItem("authToken"),
      })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_HOME_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_HOME_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchIndustries = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_INDUSTRIES_CONTENT,
    payload: null,
  });
  http
    .post(
      "industries-page",
      helpers.doObjToFormData({
        token: localStorage.getItem("authToken"),
      })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_INDUSTRIES_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_INDUSTRIES_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchIndustryDetails = (slug) => (dispatch) => {
  dispatch({
    type: FETCH_INDUSTRIES_DETAILS_CONTENT,
    payload: null,
  });
  console.log("industry-details/" + slug);
  http
    .post(
      "industry-details/" + slug,
      helpers.doObjToFormData({
        token: localStorage.getItem("authToken"),
        slug: slug,
      })
    )
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED,
        payload: error,
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
export const saveJobActionFirst = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_JOB_HOME_1,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("save-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_JOB_HOME_1_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SAVE_JOB_HOME_1_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_JOB_HOME_1_FAILED,
          payload: error,
        });
        const { data } = error.response;
        // localStorage.removeItem("authToken");
        // window.location.replace("/signin");
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const saveJobActionSecond = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_JOB_HOME_2,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("save-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_JOB_HOME_2_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SAVE_JOB_HOME_2_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_JOB_HOME_2_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.replace("/signin");
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const applyJobFirst = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: JOB_APPLIED_HOME_1,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/job-applied", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Applied Successfully.", TOAST_SETTINGS);
          dispatch({
            type: JOB_APPLIED_HOME_1_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: JOB_APPLIED_HOME_1_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: JOB_APPLIED_HOME_1_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.replace("/signin");
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const applyJobSecond = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: JOB_APPLIED_HOME_2,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/job-applied", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Applied Successfully.", TOAST_SETTINGS);
          dispatch({
            type: JOB_APPLIED_HOME_2_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: JOB_APPLIED_HOME_2_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: JOB_APPLIED_HOME_2_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.replace("/signin");
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
export const reportAnJobPopup = () => (dispatch) => {
  dispatch({
    type: REPORT_AN_JOB_HOME_POPUP,
    payload: null,
  });
};
export const reportAnJob = (formData) => (dispatch) => {
  // let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: REPORT_AN_JOB_HOME,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/report-an-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          // data = { ...data, id: jobId };
          toast.success(
            "You have successfully reported the job.",
            TOAST_SETTINGS
          );
          setTimeout(() => {
            dispatch({
              type: REPORT_AN_JOB_HOME_SUCCESS,
              payload: data,
            });
          }, 800);
        } else {
          dispatch({
            type: REPORT_AN_JOB_HOME_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: REPORT_AN_JOB_HOME_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.replace("/signin");
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
