import React, { useState, useRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ImageControl from "../../common/ImageControl";
import { saveProfileSettingsAction } from "../../../states/actions/fetchProfileSettings";
import SubmitButton from "../../common/SubmitButton";

const Profile = () => {
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const profilePicRef = useRef(null);

  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );
  const mem = useSelector((state) => state.fetchProfileSettings.mem);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    if (image !== null) data.profile = image.target.files;
    dispatch(saveProfileSettingsAction(data));
  };

  const team_size = [
    {
      id: 1,
      range: "1-10"
    },
    {
      id: 2,
      range: "10-15"
    },
    {
      id: 3,
      range: "15-25"
    },
    {
      id: 4,
      range: "25-50"
    },
    {
      id: 5,
      range: "50+"
    }
  ];

  const handlePhotoSelect = (e) => {
    e.preventDefault();
    profilePicRef.current.click();
  };

  const handleProfilePicChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImage(e);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <>
      <div className="setting_blk wide_sm">
        <h4>Personal Info</h4>
        <form onSubmit={handleSubmit(firstSubmit)} method="POST">
          <input
            className="sm small"
            type="file"
            id="profile"
            {...register("profile")}
            onChange={handleProfilePicChange}
            ref={profilePicRef}
            hidden={true}
          />
          <p>Logo</p>
          <div className="dp_flex">
            <div className="dp_icon">
              {previewImage != null ? (
                <img src={previewImage} alt={`Error Loading Preview Image`} />
              ) : mem.mem_image == null || mem.mem_image == "" ? (
                <img
                  src="/images/user_dp.svg"
                  alt={`Error Loading Preview Image`}
                />
              ) : (
                <ImageControl
                  src={mem.mem_image}
                  folder="members"
                  alt={`Error Loading Preview Image`}
                />
              )}
            </div>
            <div className="btn_blk">
              <button
                type="button"
                className="site_btn"
                onClick={handlePhotoSelect}
              >
                Upload
              </button>
              {/*<button type="button" className="site_btn blank yellow_blank">
                Remove
              </button>*/}
            </div>
          </div>
          <div className="divide"></div>
          <div className="from_row row">
            <div className="col-sm-6">
              <div className="blk_form">
                <h6>
                  Display Name <span>(Visible to others)</span>
                </h6>
                <div className="form_blk">
                  <input
                    defaultValue={mem.mem_display_name}
                    type="text"
                    className="input"
                    {...register("mem_display_name", {
                      required: "Display Name is required.",
                      minLength: {
                        value: 2,
                        message:
                          "Display Name should contains atleast 2 letters."
                      }
                    })}
                  />
                  <span className="validation-error">
                    {errors.mem_display_name?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="blk_form">
                <h6>
                  Name <span> (Your given name)</span>
                </h6>
                <div className="form_blk">
                  <input
                    type="text"
                    defaultValue={`${mem.mem_fname} ${mem.mem_lname}`}
                    className="input"
                    {...register("name", {
                      required: "Name is required.",
                      minLength: {
                        value: 2,
                        message: "Name should contains atleast 2 letters."
                      }
                    })}
                  />
                  <span className="validation-error">
                    {errors.name?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="blk_form">
                <h6>Job Title</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    defaultValue={mem?.mem_job_title}
                    className="input"
                    {...register("mem_job_title", {
                      required: "Job title is required.",
                      minLength: {
                        value: 2,
                        message: "Job title should contains atleast 2 letters."
                      }
                    })}
                  />
                  <span className="validation-error">
                    {errors.mem_job_title?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="blk_form">
                <h6>Team size</h6>
                <div class="form_blk">
                  <select
                    defaultValue={mem?.mem_team_size}
                    className="input"
                    {...register("mem_team_size", {
                      required: "Team size is required."
                    })}
                  >
                    <option value="">Select</option>
                    {team_size &&
                      team_size.map((size, i) => (
                        <option value={size.range}>{size.range}</option>
                      ))}
                  </select>
                  <span className="validation-error">
                    {errors.mem_team_size?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="blk_form">
                <h6>Phone Number</h6>
                <div className="form_blk">
                  <PhoneInput
                    defaultCountry="US"
                    className="input"
                    value={mem?.mem_phone}
                    {...register("mem_phone")}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12" hidden>
              <div className="blk_form">
                <h6>Reviews</h6>
                <div className="form_blk">
                  <div className="dim_lbl">
                    Manage the reviews you’ve written for professionals, lands,
                    and more.
                  </div>
                </div>
              </div>
              <Link to="/" className="manage_lnk">
                Manage
              </Link>
            </div>
          </div>
          <div className="divide"></div>
          <div className="btn_blk text-right cell_wide_full">
            <SubmitButton
              classes="site_btn"
              isFormProcessing={isFormProcessing}
              button_text="Save Changes"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
