export const FETCH_SITE_SETTINGS = "FETCH_SITE_SETTINGS";
export const FETCH_SITE_SETTINGS_SUCCESS = "FETCH_SITE_SETTINGS_SUCCESS";
export const FETCH_SITE_SETTINGS_FAILED = "FETCH_SITE_SETTINGS_FAILED";

export const FETCH_HOME_CONTENT = "FETCH_HOME_CONTENT";
export const FETCH_HOME_CONTENT_SUCCESS = "FETCH_HOME_CONTENT_SUCCESS";
export const FETCH_HOME_CONTENT_FAILED = "FETCH_HOME_CONTENT_FAILED";

export const FETCH_WISHLIST_CONTENT_FAILED = "FETCH_WISHLIST_CONTENT_FAILED";
export const FETCH_WISHLIST_CONTENT_SUCCESS = "FETCH_WISHLIST_CONTENT_SUCCESS";
export const FETCH_LABEL_CONTENT_SUCCESS = "ADD_LABEL_TO_PROPERTIES";
export const FETCH_LABEL_CONTENT_FAILED = "ADD_LABEL_TO_PROPERTIES_FAILED";

export const FETCH_Properties_CONTENT = "FETCH_PROPERTIES_CONTENT_SUCCESS";
export const FETCH_PROPERTIES_CONTENT_FAILED =
  "FETCH_PROPERTIES_CONTENT_FAILED";
export const FETCH_PROPERTY_DETAIL_CONTENT = "FETCH_PROPERTIES_DETAIL_SUCCESS";
export const FETCH_OWNER_PROPERTYS = "FETCH_OWNER_PROPERTIES";
export const FETCH_OWNER_PROPERTYS_FAIL = "FETCH_OWNER_PROPERTIES_FAIL";

export const FETCH_PROPERTY_DETAIL_FAILED = "FETCH_PROPERTIES_DETAIL_Failed";

export const FETCH_TERMS_AND_CONDITIONS_CONTENT =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT";
export const FETCH_TERMS_AND_CONDITIONS_CONTENT_SUCCESS =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT_SUCCESS";
export const FETCH_TERMS_AND_CONDITIONS_CONTENT_FAILED =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT_FAILED";

export const FETCH_PRIVACY_POLICY_CONTENT = "FETCH_PRIVACY_POLICY_CONTENT";
export const FETCH_PRIVACY_POLICY_CONTENT_SUCCESS =
  "FETCH_PRIVACY_POLICY_CONTENT_SUCCESS";
export const FETCH_PRIVACY_POLICY_CONTENT_FAILED =
  "FETCH_PRIVACY_POLICY_CONTENT_FAILED";

export const FETCH_DATA_UPLOAD_CONTENT = "FETCH_DATA_UPLOAD_CONTENT";
export const FETCH_DATA_UPLOAD_CONTENT_SUCCESS =
  "FETCH_DATA_UPLOAD_CONTENT_SUCCESS";
export const FETCH_DATA_UPLOAD_CONTENT_FAILED =
  "FETCH_DATA_UPLOAD_CONTENT_FAILED";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const UPLOAD_DATA_SUCCESS = "UPLOAD_DATA_SUCCESS";
export const UPLOAD_DATA_FAILED = "UPLOAD_DATA_FAILED";

export const FETCH_CHECKOUT_CONTENT = "FETCH_CHECKOUT_CONTENT";
export const FETCH_CHECKOUT_CONTENT_SUCCESS = "FETCH_CHECKOUT_CONTENT_SUCCESS";
export const FETCH_CHECKOUT_CONTENT_FAILED = "FETCH_CHECKOUT_CONTENT_FAILED";

export const FETCH_ENTERPRISE_CHECKOUT_CONTENT =
  "FETCH_ENTERPRISE_CHECKOUT_CONTENT";
export const FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS =
  "FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS";
export const FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED =
  "FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED";

export const FETCH_SUPPORT_CONTENT = "FETCH_SUPPORT_CONTENT";
export const FETCH_SUPPORT_CONTENT_SUCCESS = "FETCH_SUPPORT_CONTENT_SUCCESS";
export const FETCH_SUPPORT_CONTENT_FAILED = "FETCH_SUPPORT_CONTENT_FAILED";

export const FETCH_SAVED_SEARCHES_CONTENT = "FETCH_SAVED_SEARCHES_CONTENT";
export const FETCH_SAVED_SEARCHES_CONTENT_SUCCESS =
  "FETCH_SAVED_SEARCHES_CONTENT_SUCCESS";
export const FETCH_SAVED_SEARCHES_CONTENT_FAILED =
  "FETCH_SAVED_SEARCHES_CONTENT_FAILED";
export const FETCH_SAVED_SEARCHES_HEADER = "FETCH_SAVED_SEARCHES_HEADER";
export const FETCH_SAVED_SEARCHES_HEADER_SUCCESS =
  "FETCH_SAVED_SEARCHES_HEADER_SUCCESS";
export const FETCH_SAVED_SEARCHES_HEADER_FAILED =
  "FETCH_SAVED_SEARCHES_HEADER_FAILED";
export const DELETE_SEARCH_HISTORY = "DELETE_SEARCH_HISTORY";
export const DELETE_SEARCH_HISTORY_SUCCESS = "DELETE_SEARCH_HISTORY_SUCCESS";
export const DELETE_SEARCH_HISTORY_FAILED = "DELETE_SEARCH_HISTORY_FAILED";

export const FETCH_PRICING_CONTENT = "FETCH_PRICING_CONTENT";
export const FETCH_PRICING_CONTENT_SUCCESS = "FETCH_PRICING_CONTENT_SUCCESS";
export const FETCH_PRICING_CONTENT_FAILED = "FETCH_PRICING_CONTENT_FAILED";

export const FETCH_HELP_CONTENT = "FETCH_HELP_CONTENT";
export const FETCH_HELP_CONTENT_SUCCESS = "FETCH_HELP_CONTENT_SUCCESS";
export const FETCH_HELP_CONTENT_FAILED = "FETCH_HELP_CONTENT_FAILED";
export const SEARCH_HELP = "SEARCH_HELP";
export const SEARCH_HELP_SUCCESS = "SEARCH_HELP_SUCCESS";
export const SEARCH_HELP_FAILED = "SEARCH_HELP_FAILED";

export const FETCH_FAQ_CONTENT = "FETCH_FAQ_CONTENT";
export const FETCH_FAQ_CONTENT_SUCCESS = "FETCH_FAQ_CONTENT_SUCCESS";
export const FETCH_FAQ_CONTENT_FAILED = "FETCH_FAQ_CONTENT_FAILED";

export const FETCH_SUBSCRIPTION_CONTENT = "FETCH_SUBSCRIPTION_CONTENT";
export const FETCH_SUBSCRIPTION_CONTENT_SUCCESS =
  "FETCH_SUBSCRIPTION_CONTENT_SUCCESS";
export const FETCH_SUBSCRIPTION_CONTENT_FAILED =
  "FETCH_SUBSCRIPTION_CONTENT_FAILED";

export const FETCH_SUBSCRIPTION_OFFERS_CONTENT =
  "FETCH_SUBSCRIPTION_OFFERS_CONTENT";
export const FETCH_SUBSCRIPTION_OFFERS_CONTENT_SUCCESS =
  "FETCH_SUBSCRIPTION_OFFERS_CONTENT_SUCCESS";
export const FETCH_SUBSCRIPTION_OFFERS_CONTENT_FAILED =
  "FETCH_SUBSCRIPTION_OFFERS_CONTENT_FAILED";

export const FETCH_DISCLAIMER_CONTENT = "FETCH_DISCLAIMER_CONTENT";
export const FETCH_DISCLAIMER_CONTENT_SUCCESS =
  "FETCH_DISCLAIMER_CONTENT_SUCCESS";
export const FETCH_DISCLAIMER_CONTENT_FAILED =
  "FETCH_DISCLAIMER_CONTENT_FAILED";

export const FETCH_WORK_WITH_US_CONTENT = "FETCH_WORK_WITH_US_CONTENT";
export const FETCH_WORK_WITH_US_CONTENT_SUCCESS =
  "FETCH_WORK_WITH_US_CONTENT_SUCCESS";
export const FETCH_WORK_WITH_US_CONTENT_FAILED =
  "FETCH_WORK_WITH_US_CONTENT_FAILED";

export const FETCH_PARTNER_WITH_US_CONTENT = "FETCH_PARTNER_WITH_US_CONTENT";
export const FETCH_PARTNER_WITH_US_CONTENT_SUCCESS =
  "FETCH_PARTNER_WITH_US_CONTENT_SUCCESS";
export const FETCH_PARTNER_WITH_US_CONTENT_FAILED =
  "FETCH_PARTNER_WITH_US_CONTENT_FAILED";

export const FETCH_CAREERS_CONTENT = "FETCH_CAREERS_CONTENT";
export const FETCH_CAREERS_CONTENT_SUCCESS = "FETCH_CAREERS_CONTENT_SUCCESS";
export const FETCH_CAREERS_CONTENT_FAILED = "FETCH_CAREERS_CONTENT_FAILED";

export const FETCH_FETCH_JOB_CONTENT = "FETCH_FETCH_JOB_CONTENT";
export const FETCH_FETCH_JOB_CONTENT_SUCCESS =
  "FETCH_FETCH_JOB_CONTENT_SUCCESS";
export const FETCH_FETCH_JOB_CONTENT_FAILED = "FETCH_FETCH_JOB_CONTENT_FAILED";

export const FETCH_JOB_PROFILE = "FETCH_JOB_PROFILE";
export const FETCH_JOB_PROFILE_SUCCESS = "FETCH_JOB_PROFILE_SUCCESS";
export const FETCH_JOB_PROFILE_FAILED = "FETCH_JOB_PROFILE_FAILED";
export const APPLY_INTERNAL_JOB = "APPLY_INTERNAL_JOB";
export const APPLY_INTERNAL_JOB_SUCCESS = "APPLY_INTERNAL_JOB_SUCCESS";
export const APPLY_INTERNAL_JOB_FAILED = "APPLY_INTERNAL_JOB_FAILED";
export const SAVE_JOB_INTERNAL = "SAVE_JOB_INTERNAL";
export const SAVE_JOB_INTERNAL_SUCCESS = "SAVE_JOB_INTERNAL_SUCCESS";
export const SAVE_JOB_INTERNAL_FAILED = "SAVE_JOB_INTERNAL_FAILED";

export const FETCH_JOBS_CONTENT = "FETCH_JOBS_CONTENT";
export const FETCH_JOBS_CONTENT_SUCCESS = "FETCH_JOBS_CONTENT_SUCCESS";
export const FETCH_JOBS_CONTENT_FAILED = "FETCH_JOBS_CONTENT_FAILED";
export const FETCH_JOBS_SEARCH = "FETCH_JOBS_SEARCH";
export const FETCH_JOBS_SEARCH_SUCCESS = "FETCH_JOBS_SEARCH_SUCCESS";
export const FETCH_JOBS_SEARCH_FAILED = "FETCH_JOBS_SEARCH_FAILED";
export const SAVE_JOB = "SAVE_JOB";
export const SAVE_JOB_SUCCESS = "SAVE_JOB_SUCCESS";
export const SAVE_JOB_FAILED = "SAVE_JOB_FAILED";
export const APPLY_JOB = "APPLY_JOB";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAILED = "APPLY_JOB_FAILED";
export const JOB_APPLIED = "JOB_APPLIED";
export const JOB_APPLIED_SUCCESS = "JOB_APPLIED_SUCCESS";
export const JOB_APPLIED_FAILED = "JOB_APPLIED_FAILED";
export const REPORT_AN_JOB = "REPORT_AN_JOB";
export const REPORT_AN_JOB_SUCCESS = "REPORT_AN_JOB_SUCCESS";
export const REPORT_AN_JOB_FAILED = "REPORT_AN_JOB_FAILED";
export const REPORT_AN_JOB_POPUP = "REPORT_AN_JOB_POPUP";
export const REPORT_AN_JOB_POPUP_SUCCESS = "REPORT_AN_JOB_POPUP_SUCCESS";
export const REPORT_AN_JOB_POPUP_FAILED = "REPORT_AN_JOB_POPUP_FAILED";

export const SAVE_JOB_HOME_1 = "SAVE_JOB_HOME_1";
export const SAVE_JOB_HOME_1_SUCCESS = "SAVE_JOB_HOME_1_SUCCESS";
export const SAVE_JOB_HOME_1_FAILED = "SAVE_JOB_HOME_1_FAILED";

export const JOB_APPLIED_HOME_1 = "JOB_APPLIED_HOME_1";
export const JOB_APPLIED_HOME_1_SUCCESS = "JOB_APPLIED_HOME_1_SUCCESS";
export const JOB_APPLIED_HOME_1_FAILED = "JOB_APPLIED_HOME_1_FAILED";

export const JOB_APPLIED_HOME_2 = "JOB_APPLIED_HOME_2";
export const JOB_APPLIED_HOME_2_SUCCESS = "JOB_APPLIED_HOME_2_SUCCESS";
export const JOB_APPLIED_HOME_2_FAILED = "JOB_APPLIED_HOME_2_FAILED";

export const REPORT_AN_JOB_HOME = "REPORT_AN_JOB_HOME";
export const REPORT_AN_JOB_HOME_SUCCESS = "REPORT_AN_JOB_HOME_SUCCESS";
export const REPORT_AN_JOB_HOME_FAILED = "REPORT_AN_JOB_HOME_FAILED";
export const REPORT_AN_JOB_HOME_POPUP = "REPORT_AN_JOB_HOME_POPUP";

export const SAVE_SUBSCRIPTION_PAYMENT = "SAVE_SUBSCRIPTION_PAYMENT";
export const SAVE_SUBSCRIPTION_PAYMENT_SUCCESS =
  "SAVE_SUBSCRIPTION_PAYMENT_SUCCESS";
export const SAVE_SUBSCRIPTION_PAYMENT_FAILED =
  "SAVE_SUBSCRIPTION_PAYMENT_FAILED";

export const SAVE_JOB_HOME_2 = "SAVE_JOB_HOME_2";
export const SAVE_JOB_HOME_2_SUCCESS = "SAVE_JOB_HOME_2_SUCCESS";
export const SAVE_JOB_HOME_2_FAILED = "SAVE_JOB_HOME_2_FAILED";

export const FETCH_ABOUT_US_CONTENT = "FETCH_ABOUT_US_CONTENT";
export const FETCH_ABOUT_US_CONTENT_SUCCESS = "FETCH_ABOUT_US_CONTENT_SUCCESS";
export const FETCH_ABOUT_US_CONTENT_FAILED = "FETCH_ABOUT_US_CONTENT_FAILED";
export const SAVE_CONTACT_MESSAGE = "SAVE_CONTACT_MESSAGE";
export const SAVE_CONTACT_MESSAGE_SUCCESS = "SAVE_CONTACT_MESSAGE_SUCCESS";
export const SAVE_CONTACT_MESSAGE_FAILED = "SAVE_CONTACT_MESSAGE_FAILED";

export const FETCH_SIGN_UP_CONTENT = "FETCH_SIGN_UP_CONTENT";
export const FETCH_SIGN_UP_CONTENT_SUCCESS = "FETCH_SIGN_UP_CONTENT_SUCCESS";
export const FETCH_SIGN_UP_CONTENT_FAILED = "FETCH_SIGN_UP_CONTENT_FAILED";
export const CREATE_ACCOUNT_MESSAGE = "CREATE_ACCOUNT_MESSAGE";
export const CREATE_ACCOUNT_MESSAGE_SUCCESS = "CREATE_ACCOUNT_MESSAGE_SUCCESS";
export const CREATE_ACCOUNT_MESSAGE_FAILED = "CREATE_ACCOUNT_MESSAGE_FAILED";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "VERIFY_EMAIL_FAILED";
export const RESEND_OTP_CODE = "RESEND_OTP_CODE";
export const RESEND_OTP_CODE_SUCCESS = "RESEND_OTP_CODE_SUCCESS";
export const RESEND_OTP_CODE_FAILED = "RESEND_OTP_CODE_FAILED";
export const BACK_TO_SIGNUP = "BACK_TO_SIGNUP";
export const SWITCH_SIGNUP_STEPS = "SWITCH_SIGNUP_STEPS";

export const FETCH_FORGOT_PASSWORD_CONTENT = "FETCH_FORGOT_PASSWORD_CONTENT";
export const FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS =
  "FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS";
export const FETCH_FORGOT_PASSWORD_CONTENT_FAILED =
  "FETCH_FORGOT_PASSWORD_CONTENT_FAILED";
export const FORGOT_PASSWORD_LINK = "FORGOT_PASSWORD_LINK";
export const FORGOT_PASSWORD_LINK_SUCCESS = "FORGOT_PASSWORD_LINK_SUCCESS";
export const FORGOT_PASSWORD_LINK_FAILED = "FORGOT_PASSWORD_LINK_FAILED";

export const FETCH_RESET_PASSWORD_CONTENT = "FETCH_RESET_PASSWORD_CONTENT";
export const FETCH_RESET_PASSWORD_CONTENT_SUCCESS =
  "FETCH_RESET_PASSWORD_CONTENT_SUCCESS";
export const FETCH_RESET_PASSWORD_CONTENT_FAILED =
  "FETCH_RESET_PASSWORD_CONTENT_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const FETCH_ACCOUNT_INVITATION_CONTENT =
  "FETCH_ACCOUNT_INVITATION_CONTENT";
export const FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS =
  "FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS";
export const FETCH_ACCOUNT_INVITATION_CONTENT_FAILED =
  "FETCH_ACCOUNT_INVITATION_CONTENT_FAILED";
export const HANDLE_REJECT_ACCOUNT_INVITATION =
  "HANDLE_REJECT_ACCOUNT_INVITATION";
export const HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS =
  "HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS";
export const HANDLE_REJECT_ACCOUNT_INVITATION_FAILED =
  "HANDLE_REJECT_ACCOUNT_INVITATION_FAILED";
export const HANDLE_ACCEPT_ACCOUNT_INVITATION =
  "HANDLE_ACCEPT_ACCOUNT_INVITATION";
export const HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS =
  "HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS";
export const HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED =
  "HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED";
export const TOGGLE_INVITATION_STATE = "TOGGLE_INVITATION_STATE";

export const FETCH_SIGN_IN_CONTENT = "FETCH_SIGN_IN_CONTENT";
export const FETCH_SIGN_IN_CONTENT_SUCCESS = "FETCH_SIGN_IN_CONTENT_SUCCESS";
export const FETCH_SIGN_IN_CONTENT_FAILED = "FETCH_SIGN_IN_CONTENT_FAILED";
export const SIGN_IN_ACCOUNT_MESSAGE = "SIGN_IN_ACCOUNT_MESSAGE";
export const SIGN_IN_ACCOUNT_MESSAGE_SUCCESS =
  "SIGN_IN_ACCOUNT_MESSAGE_SUCCESS";
export const SIGN_IN_ACCOUNT_MESSAGE_FAILED = "SIGN_IN_ACCOUNT_MESSAGE_FAILED";
export const SIGN_IN_VERIFICATION_MESSAGE = "SIGN_IN_VERIFICATION_MESSAGE";
export const SIGN_IN_VERIFICATION_MESSAGE_SUCCESS =
  "SIGN_IN_VERIFICATION_MESSAGE_SUCCESS";
export const SIGN_IN_VERIFICATION_MESSAGE_FAILED =
  "SIGN_IN_VERIFICATION_MESSAGE_FAILED";
export const SIGN_IN_ACCOUNT_POPUP_MESSAGE = "SIGN_IN_ACCOUNT_POPUP_MESSAGE";
export const SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS =
  "SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS";
export const SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED =
  "SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED";

export const FETCH_VIDEO_INTERVIEW_CONTENT = "FETCH_VIDEO_INTERVIEW_CONTENT";
export const FETCH_VIDEO_INTERVIEW_CONTENT_SUCCESS =
  "FETCH_VIDEO_INTERVIEW_CONTENT_SUCCESS";
export const FETCH_VIDEO_INTERVIEW_CONTENT_FAILED =
  "FETCH_VIDEO_INTERVIEW_CONTENT_FAILED";
export const FETCH_CATEGORY_QUESTIONS = "FETCH_CATEGORY_QUESTIONS";
export const FETCH_CATEGORY_QUESTIONS_SUCCESS =
  "FETCH_CATEGORY_QUESTIONS_SUCCESS";
export const FETCH_CATEGORY_QUESTIONS_FAILED =
  "FETCH_CATEGORY_QUESTIONS_FAILED";
export const SAVE_INTERVIEW_VIDEO = "SAVE_INTERVIEW_VIDEO";
export const SAVE_INTERVIEW_VIDEO_SUCCESS = "SAVE_INTERVIEW_VIDEO_SUCCESS";
export const SAVE_INTERVIEW_VIDEO_FAILED = "SAVE_INTERVIEW_VIDEO_FAILED";

export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
export const SAVE_JOB_STAT = "SAVE_JOB_STAT";
export const SAVE_JOB_STAT_SUCCESS = "SAVE_JOB_STAT_SUCCESS";
export const SAVE_JOB_STAT_FAILED = "SAVE_JOB_STAT_FAILED";
export const SAVE_APPLIED_JOB_STAT = "SAVE_APPLIED_JOB_STAT";
export const SAVE_APPLIED_JOB_STAT_SUCCESS = "SAVE_APPLIED_JOB_STAT_SUCCESS";
export const SAVE_APPLIED_JOB_STAT_FAILED = "SAVE_APPLIED_JOB_STAT_FAILED";
export const MARK_AS_APPLIED = "MARK_AS_APPLIED";
export const MARK_AS_APPLIED_SUCCESS = "MARK_AS_APPLIED_SUCCESS";
export const MARK_AS_APPLIED_FAILED = "MARK_AS_APPLIED_FAILED";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILED = "DELETE_JOB_FAILED";

export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILED = "FETCH_ARTICLE_FAILED";

export const FETCH_TALENT_PROFILE = "FETCH_TALENT_PROFILE";
export const FETCH_TALENT_PROFILE_SUCCESS = "FETCH_TALENT_PROFILE_SUCCESS";
export const FETCH_TALENT_PROFILE_FAILED = "FETCH_TALENT_PROFILE_FAILED";
export const SAVE_TALENT_PROFILE = "SAVE_TALENT_PROFILE";
export const SAVE_TALENT_PROFILE_SUCCESS = "SAVE_TALENT_PROFILE_SUCCESS";
export const SAVE_TALENT_PROFILE_FAILED = "SAVE_TALENT_PROFILE_FAILED";

export const FETCH_AMBASSADOR_PROPGRAMME = "FETCH_AMBASSADOR_PROPGRAMME";
export const FETCH_AMBASSADOR_PROPGRAMME_SUCCESS =
  "FETCH_AMBASSADOR_PROPGRAMME_SUCCESS";
export const FETCH_AMBASSADOR_PROPGRAMME_FAILED =
  "FETCH_AMBASSADOR_PROPGRAMME_FAILED";
export const SAVE_REFERRAL_LINK = "SAVE_REFERRAL_LINK";
export const SAVE_REFERRAL_LINK_SUCCESS = "SAVE_REFERRAL_LINK_SUCCESS";
export const SAVE_REFERRAL_LINK_FAILED = "SAVE_REFERRAL_LINK_FAILED";
export const REQUEST_WITHDRAW_EARNINGS = "REQUEST_WITHDRAW_EARNINGS";
export const REQUEST_WITHDRAW_EARNINGS_SUCCESS =
  "REQUEST_WITHDRAW_EARNINGS_SUCCESS";
export const REQUEST_WITHDRAW_EARNINGS_FAILED =
  "REQUEST_WITHDRAW_EARNINGS_FAILED";

export const FETCH_DASHBOARD_SUBSCRIPTION = "FETCH_DASHBOARD_SUBSCRIPTION";
export const FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS =
  "FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS";
export const FETCH_DASHBOARD_SUBSCRIPTION_FAILED =
  "FETCH_DASHBOARD_SUBSCRIPTION_FAILED";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED";

export const FETCH_PROFILE_SETTINGS = "FETCH_PROFILE_SETTINGS";
export const FETCH_PROFILE_SETTINGS_SUCCESS = "FETCH_PROFILE_SETTINGS_SUCCESS";
export const FETCH_PROFILE_SETTINGS_FAILED = "FETCH_PROFILE_SETTINGS_FAILED";
export const SAVE_PROFILE_SETTINGS = "SAVE_PROFILE_SETTINGS";
export const SAVE_PROFILE_SETTINGS_SUCCESS = "SAVE_PROFILE_SETTINGS_SUCCESS";
export const SAVE_PROFILE_SETTINGS_FAILED = "SAVE_PROFILE_SETTINGS_FAILED";
export const TOGGLE_CHANGE_PASSWORD_POPUP = "TOGGLE_CHANGE_PASSWORD_POPUP";
export const TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP =
  "TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILED = "SAVE_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  "UPDATE_NOTIFICATION_SETTINGS_SUCCESS";
export const UPDATE_NOTIFICATION_SETTINGS_FAILED =
  "UPDATE_NOTIFICATION_SETTINGS_FAILED";
export const SHOW_USER_POPUP = "SHOW_USER_POPUP";

export const FETCH_ARTICLE_DETAIL_CONTENT = "FETCH_ARTICLE_DETAIL_CONTENT";
export const FETCH_ARTICLE_DETAIL_CONTENT_SUCCESS =
  "FETCH_ARTICLE_DETAIL_CONTENT_SUCCESS";
export const FETCH_ARTICLE_DETAIL_CONTENT_FAILED =
  "FETCH_ARTICLE_DETAIL_CONTENT_FAILED";

export const FETCH_EVENTS_CONTENT = "FETCH_EVENTS_CONTENT";
export const FETCH_EVENTS_CONTENT_SUCCESS = "FETCH_EVENTS_CONTENT_SUCCESS";
export const FETCH_EVENTS_CONTENT_FAILED = "FETCH_EVENTS_CONTENT_FAILED";
export const FETCH_EVENT_DETAIL_CONTENT = "FETCH_EVENT_DETAIL_CONTENT";
export const FETCH_EVENT_DETAIL_CONTENT_SUCCESS =
  "FETCH_EVENT_DETAIL_CONTENT_SUCCESS";
export const FETCH_EVENT_DETAIL_CONTENT_FAILED =
  "FETCH_EVENT_DETAIL_CONTENT_FAILED";
export const FETCH_EVENTS_SEARCH = "FETCH_EVENTS_SEARCH";
export const FETCH_EVENTS_SEARCH_SUCCESS = "FETCH_EVENTS_SEARCH_SUCCESS";
export const FETCH_EVENTS_SEARCH_FAILED = "FETCH_EVENTS_SEARCH_FAILED";

export const FETCH_BLOGS_CONTENT = "FETCH_BLOGS_CONTENT";
export const FETCH_BLOGS_CONTENT_SUCCESS = "FETCH_BLOGS_CONTENT_SUCCESS";
export const FETCH_BLOGS_CONTENT_FAILED = "FETCH_BLOGS_CONTENT_FAILED";
export const FETCH_BLOGS_SEARCH = "FETCH_BLOGS_SEARCH";
export const FETCH_BLOGS_SEARCH_SUCCESS = "FETCH_BLOGS_SEARCH_SUCCESS";
export const FETCH_BLOGS_SEARCH_FAILED = "FETCH_BLOGS_SEARCH_FAILED";

export const FETCH_BLOG_DETAIL_CONTENT = "FETCH_BLOG_DETAIL_CONTENT";
export const FETCH_BLOG_DETAIL_CONTENT_SUCCESS =
  "FETCH_BLOG_DETAIL_CONTENT_SUCCESS";
export const FETCH_BLOG_DETAIL_CONTENT_FAILED =
  "FETCH_BLOG_DETAIL_CONTENT_FAILED";

export const FETCH_HELP_DETAIL_CONTENT = "FETCH_HELP_DETAIL_CONTENT";
export const FETCH_HELP_DETAIL_CONTENT_SUCCESS =
  "FETCH_HELP_DETAIL_CONTENT_SUCCESS";
export const FETCH_HELP_DETAIL_CONTENT_FAILED =
  "FETCH_HELP_DETAIL_CONTENT_FAILED";
export const SEARCH_HELP_RESULT_CONTENT = "SEARCH_HELP_RESULT_CONTENT";
export const SEARCH_HELP_RESULT_CONTENT_SUCCESS =
  "SEARCH_HELP_RESULT_CONTENT_SUCCESS";
export const SEARCH_HELP_RESULT_CONTENT_FAILED =
  "SEARCH_HELP_RESULT_CONTENT_FAILED";

export const FETCH_QUESTION_ARTICLE_CONTENT = "FETCH_QUESTION_ARTICLE_CONTENT";
export const FETCH_QUESTION_ARTICLE_CONTENT_SUCCESS =
  "FETCH_QUESTION_ARTICLE_CONTENT_SUCCESS";
export const FETCH_QUESTION_ARTICLE_CONTENT_FAILED =
  "FETCH_QUESTION_ARTICLE_CONTENT_FAILED";

export const REGISTER_EVENT = "REGISTER_EVENT";
export const REGISTER_EVENT_SUCCESS = "REGISTER_EVENT_SUCCESS";
export const REGISTER_EVENT_FAILED = "REGISTER_EVENT_FAILED";

export const FETCH_SUBSCRIPTION_PAYMENT_CONTENT =
  "FETCH_SUBSCRIPTION_PAYMENT_CONTENT";
export const FETCH_SUBSCRIPTION_PAYMENT_CONTENT_SUCCESS =
  "FETCH_SUBSCRIPTION_PAYMENT_CONTENT_SUCCESS";
export const FETCH_SUBSCRIPTION_PAYMENT_CONTENT_FAILED =
  "FETCH_SUBSCRIPTION_PAYMENT_CONTENT_FAILED";

export const FETCH_JOB_PROFILE_DETAIL_CONTENT =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT";
export const FETCH_JOB_PROFILE_DETAIL_CONTENT_SUCCESS =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT_SUCCESS";
export const FETCH_JOB_PROFILE_DETAIL_CONTENT_FAILED =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT_FAILED";

export const FETCH_UK_CORPORATE_CONTENT = "FETCH_UK_CORPORATE_CONTENT";
export const FETCH_UK_CORPORATE_CONTENT_SUCCESS =
  "FETCH_UK_CORPORATE_CONTENT_SUCCESS";
export const FETCH_UK_CORPORATE_CONTENT_FAILED =
  "FETCH_UK_CORPORATE_CONTENT_FAILED";

export const FETCH_TESTIMONIALS_CONTENT = "FETCH_TESTIMONIALS_CONTENT";
export const FETCH_TESTIMONIALS_CONTENT_SUCCESS =
  "FETCH_TESTIMONIALS_CONTENT_SUCCESS";
export const FETCH_TESTIMONIALS_CONTENT_FAILED =
  "FETCH_TESTIMONIALS_CONTENT_FAILED";

export const FETCH_RECR_PROCESS_CONTENT = "FETCH_RECR_PROCESS_CONTENT";
export const FETCH_RECR_PROCESS_CONTENT_SUCCESS =
  "FETCH_RECR_PROCESS_CONTENT_SUCCESS";
export const FETCH_RECR_PROCESS_CONTENT_FAILED =
  "FETCH_RECR_PROCESS_CONTENT_FAILED";

export const FETCH_CV_COVER_LETTER_CONTENT = "FETCH_CV_COVER_LETTER_CONTENT";
export const FETCH_CV_COVER_LETTER_CONTENT_SUCCESS =
  "FETCH_CV_COVER_LETTER_CONTENT_SUCCESS";
export const FETCH_CV_COVER_LETTER_CONTENT_FAILED =
  "FETCH_CV_COVER_LETTER_CONTENT_FAILED";

export const FETCH_CV_GUIDANCE_CONTENT = "FETCH_CV_GUIDANCE_CONTENT";
export const FETCH_CV_GUIDANCE_CONTENT_SUCCESS =
  "FETCH_CV_GUIDANCE_CONTENT_SUCCESS";
export const FETCH_CV_GUIDANCE_CONTENT_FAILED =
  "FETCH_CV_GUIDANCE_CONTENT_FAILED";

export const FETCH_COVER_LETTER_GUIDANCE_CONTENT =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT";
export const FETCH_COVER_LETTER_GUIDANCE_CONTENT_SUCCESS =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT_SUCCESS";
export const FETCH_COVER_LETTER_GUIDANCE_CONTENT_FAILED =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT_FAILED";

export const FETCH_CV_BUILDER_CONTENT = "FETCH_CV_BUILDER_CONTENT";
export const FETCH_CV_BUILDER_CONTENT_SUCCESS =
  "FETCH_CV_BUILDER_CONTENT_SUCCESS";
export const FETCH_CV_BUILDER_CONTENT_FAILED =
  "FETCH_CV_BUILDER_CONTENT_FAILED";
export const SAVE_CV = "SAVE_CV";
export const SAVE_CV_SUCCESS = "SAVE_CV_SUCCESS";
export const SAVE_CV_FAILED = "SAVE_CV_FAILED";

export const FETCH_COVER_LETTER_BUILDER_CONTENT =
  "FETCH_COVER_LETTER_BUILDER_CONTENT";
export const FETCH_COVER_LETTER_BUILDER_CONTENT_SUCCESS =
  "FETCH_COVER_LETTER_BUILDER_CONTENT_SUCCESS";
export const FETCH_COVER_LETTER_BUILDER_CONTENT_FAILED =
  "FETCH_COVER_LETTER_BUILDER_CONTENT_FAILED";
export const SAVE_COVER = "SAVE_COVER";
export const SAVE_COVER_SUCCESS = "SAVE_COVER_SUCCESS";
export const SAVE_COVER_FAILED = "SAVE_COVER_FAILED";

export const FETCH_ASSESSMENT_CENTER_CONTENT =
  "FETCH_ASSESSMENT_CENTER_CONTENT";
export const FETCH_ASSESSMENT_CENTER_CONTENT_SUCCESS =
  "FETCH_ASSESSMENT_CENTER_CONTENT_SUCCESS";
export const FETCH_ASSESSMENT_CENTER_CONTENT_FAILED =
  "FETCH_ASSESSMENT_CENTER_CONTENT_FAILED";

export const FETCH_INTERVIEW_CONTENT = "FETCH_INTERVIEW_CONTENT";
export const FETCH_INTERVIEW_CONTENT_SUCCESS =
  "FETCH_INTERVIEW_CONTENT_SUCCESS";
export const FETCH_INTERVIEW_CONTENT_FAILED = "FETCH_INTERVIEW_CONTENT_FAILED";

export const FETCH_ONLINE_TEST_CONTENT = "FETCH_ONLINE_TEST_CONTENT";
export const FETCH_ONLINE_TEST_CONTENT_SUCCESS =
  "FETCH_ONLINE_TEST_CONTENT_SUCCESS";
export const FETCH_ONLINE_TEST_CONTENT_FAILED =
  "FETCH_ONLINE_TEST_CONTENT_FAILED";

export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT";
export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT_SUCCESS =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT_SUCCESS";
export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT_FAILED =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT_FAILED";

export const FETCH_TEST_CATEGORY_DETAIL_CONTENT =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT";
export const FETCH_TEST_CATEGORY_DETAIL_CONTENT_SUCCESS =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT_SUCCESS";
export const FETCH_TEST_CATEGORY_DETAIL_CONTENT_FAILED =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT_FAILED";

export const FETCH_CAREER_OPTIONS_CONTENT = "FETCH_CAREER_OPTIONS_CONTENT";
export const FETCH_CAREER_OPTIONS_CONTENT_SUCCESS =
  "FETCH_CAREER_OPTIONS_CONTENT_SUCCESS";
export const FETCH_CAREER_OPTIONS_CONTENT_FAILED =
  "FETCH_CAREER_OPTIONS_CONTENT_FAILED";

export const FETCH_UNI_VS_EMP_CONTENT = "FETCH_UNI_VS_EMP_CONTENT";
export const FETCH_UNI_VS_EMP_CONTENT_SUCCESS =
  "FETCH_UNI_VS_EMP_CONTENT_SUCCESS";
export const FETCH_UNI_VS_EMP_CONTENT_FAILED =
  "FETCH_UNI_VS_EMP_CONTENT_FAILED";

export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION";
export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION_SUCCESS =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION_SUCCESS";
export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION_FAILED =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION_FAILED";

export const ENTERPRISE_CHECKOUT_MESSAGE = "ENTERPRISE_CHECKOUT_MESSAGE";
export const ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS =
  "ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS";
export const ENTERPRISE_CHECKOUT_MESSAGE_FAILED =
  "ENTERPRISE_CHECKOUT_MESSAGE_FAILED";
export const CANCEL_SUBSCRIPTION_MESSAGE = "CANCEL_SUBSCRIPTION_MESSAGE";
export const CANCEL_SUBSCRIPTION_MESSAGE_FAILED =
  "CANCEL_SUBSCRIPTION_MESSAGE_FAILED";
export const CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS =
  "CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS";

export const UPDATE_SUBSCRIPTION_MESSAGE = "UPDATE_SUBSCRIPTION_MESSAGE";
export const UPDATE_SUBSCRIPTION_MESSAGE_FAILED =
  "UPDATE_SUBSCRIPTION_MESSAGE_FAILED";
export const UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS =
  "UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS";

export const SEARCH_NEWSLETTER_EMAIL = "Enter Your Email";
export const SEARCH_NEWSLETTER_EMAIL_SUCCESS = "Email Registered Successfully";
export const SEARCH_NEWSLETTER_EMAIL_FAILED = " Email Registration Failed";

export const TOGGLE_RESULT_VIEW_STATE = "TOGGLE_RESULT_VIEW_STATE";
export const TOGGLE_SHOW_SEARCH_RESULTS = "TOGGLE_SHOW_SEARCH_RESULTS";

export const FETCH_FEEDBACK_CONTENT = "FETCH_FEEDBACK_CONTENT";
export const FETCH_FEEDBACK_CONTENT_SUCCESS = "FETCH_FEEDBACK_CONTENT_SUCCESS";
export const FETCH_FEEDBACK_CONTENT_FAILED = "FETCH_FEEDBACK_CONTENT_FAILED";
export const FEEDBACK_MESSAGE = "FEEDBACK_MESSAGE";
export const FEEDBACK_MESSAGE_SUCCESS = "FEEDBACK_MESSAGE_SUCCESS";
export const FEEDBACK_MESSAGE_FAILED = "FEEDBACK_MESSAGE_FAILED";

export const UPDATE_SEARCH_HISTORY = "UPDATE_SEARCH_HISTORY";
export const UPDATE_SEARCH_HISTORY_SUCCESS = "UPDATE_SEARCH_HISTORY_SUCCESS";
export const UPDATE_SEARCH_HISTORY_FAILED = "UPDATE_SEARCH_HISTORY_FAILED";

export const FETCH_EXPORT_CONTENT = "FETCH_EXPORT_CONTENT";
export const FETCH_EXPORT_CONTENT_SUCCESS = "FETCH_EXPORT_CONTENT_SUCCESS";
export const FETCH_EXPORT_CONTENT_FAILED = "FETCH_EXPORT_CONTENT_FAILED";
export const EXPORT_MESSAGE = "EXPORT_MESSAGE";
export const EXPORT_MESSAGE_SUCCESS = "EXPORT_MESSAGE_SUCCESS";
export const EXPORT_MESSAGE_FAILED = "EXPORT_MESSAGE_FAILED";


export const FETCH_INDUSTRIES_CONTENT = "FETCH_INDUSTRIES_CONTENT";
export const FETCH_INDUSTRIES_CONTENT_SUCCESS = "FETCH_INDUSTRIES_CONTENT_SUCCESS";
export const FETCH_INDUSTRIES_CONTENT_FAILED = "FETCH_INDUSTRIES_CONTENT_FAILED";

export const FETCH_INDUSTRIES_DETAILS_CONTENT = "FETCH_INDUSTRIES_DETAILS_CONTENT";
export const FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS = "FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS";
export const FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED = "FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED";


export const UPDATE_USER_CREDITS = "UPDATE_USER_CREDITS";