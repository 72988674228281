import React, { useEffect } from "react";
import Intro from "./Intro";
import States from "./States";
import Team from "./Team";
import Story from "./Story";
import Video from "./Video";

import { fetchAboutUs } from "../../../states/actions/fetchAboutUs";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import MetaGenerator from "../../common/meta-generator";

const About = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchAboutUs.content);
  const isLoading = useSelector((state) => state.fetchAboutUs.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, meta } = data;

  useEffect(() => {
    dispatch(fetchAboutUs());
  }, []);

  // useDocumentTitle(data.page_title);

  const about = {
    intro: {
      image: "/images/about.png",
      heading: "Connecting the world of commercial real estate!",
      detail: "Our Company",
      abt_heading: "About Us",
      abt_detail:
        "<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p><p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.</p>",
      abt_image: "/images/abt_img.png"
    },
    states: [
      {
        id: 1,
        number: "2500+",
        title: "Completed Data"
      },
      {
        id: 2,
        number: "7500+",
        title: "Searches/ hour"
      },
      {
        id: 3,
        number: "1200+",
        title: "Exclusive information"
      },
      {
        id: 4,
        number: "150m+",
        title: "Happy Clients"
      }
    ],
    team: [
      {
        id: 1,
        name: "John Doe",
        position: "Chief Executive Officer ",
        intro:
          "Belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.",
        image: "/images/team1.png"
      },
      {
        id: 2,
        name: "Ezzine",
        position: "VP Data & analytics",
        intro:
          "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
        image: "/images/team2.png"
      },
      {
        id: 3,
        name: "Pattrick Rafferly",
        position: "VP Product",
        intro:
          "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
        image: "/images/team3.png"
      }
    ],
    story: {
      left_image: "/images/story1.png",
      story_detail:
        "<p>Brandomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,</p><p>you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to.</p>",
      heading: "Our Story of constant experimentations!",
      right_image: "/images/story2.png",
      lnk_lbl: "More We Works > "
    },
    video: {
      heading: "Let's get to know our cheerful workers",
      thumbnail: "/images/video.png",
      detail:
        "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
      lnk_lbl: "More About Worker >"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Intro content={content} />
          <States content={content} />
          <Team content={content} />
          <Story data={about.story} content={content} />
          {/* <Video data={about.video} content={content} /> */}
        </>
      )}
    </>
  );
};

export default About;
