import {
  FETCH_ACCOUNT_INVITATION_CONTENT,
  FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS,
  FETCH_ACCOUNT_INVITATION_CONTENT_FAILED,
  HANDLE_REJECT_ACCOUNT_INVITATION,
  HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS,
  HANDLE_REJECT_ACCOUNT_INVITATION_FAILED,
  HANDLE_ACCEPT_ACCOUNT_INVITATION,
  HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS,
  HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED,
  TOGGLE_INVITATION_STATE
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  action: 0
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ACCOUNT_INVITATION_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_ACCOUNT_INVITATION_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case HANDLE_REJECT_ACCOUNT_INVITATION:
      return {
        ...state,
        isFormProcessing: true
      };
    case HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case HANDLE_REJECT_ACCOUNT_INVITATION_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case HANDLE_ACCEPT_ACCOUNT_INVITATION:
      return {
        ...state,
        isFormProcessing: true
      };
    case HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case TOGGLE_INVITATION_STATE:
      return {
        ...state,
        action: payload
      };
    default:
      return state;
  }
}
