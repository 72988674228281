import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Search from "./Search";

import { fetchQuestionArticle } from "../../../states/actions/fetchQuestionArticle";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Text from "../../common/Text";
import { timeAgo, getWordInitials } from "../../../helpers/helpers";
import MetaGenerator from "../../common/meta-generator";

const SearchArticle = () => {
  const dispatch = useDispatch();
  const { question_id } = useParams();
  const data = useSelector((state) => state.fetchQuestionArticle.content);
  const isLoading = useSelector(
    (state) => state.fetchQuestionArticle.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const records = useSelector((state) => state.fetchQuestionArticle.records);
  const { content, question, meta } = data;

  useEffect(() => {
    dispatch(fetchQuestionArticle({ question_id }));
  }, []);
  // useDocumentTitle(data.page_title);

  const search = {
    banner: {
      heading: "How can we help?"
    },
    question: {
      title: "What ownership information is provided on each property?",
      pera: "<p>The Owner tab is comprised owners and contacts collected from public sources as well as data from our exclusive data partners. We have been able to pierce through shell LLCs and identify true owners to ensure you are granted the most accurate access to property ownership details.</p><p>You will see two sections on the owner tab -- Owners and Contacts. The Owner section includes the company and/or people owners that we have identified as being the owner of the property. Here, if applicable, you will be able to see if a property is owned directly or through a subsidiary, branch or shell company. We have also been able to identify and indicate if an owner is affiliated with a religious or government entity.</p><p>The Contacts section includes names, job titles, addresses, phone numbers and/or emails for individuals that are associated with the identified company owner.</p><p>We always include the Reported Owner information on this tab which is details derived from the tax assessor record as well as Mortgage Signatory information.</p><p>To learn more about everything the owner tab has to offer, check out the article, Get the most out of the new owner tab!</p>",
      dp: "DR",
      auth_name: "Diana Richards",
      time: "Updated a over a week ago"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Search data={search.banner} content={content} />
          <section className="ans_blk">
            <div className="contain">
              <div className="main_title">
                <p>
                  {content.search_result_for}: <span>{question.question}</span>
                </p>
              </div>
              <ul className="ques_lst detail_article_help">
                <li>
                  <h5>{question.question}</h5>
                  <div className="br"></div>
                  <div className="auther_lbl">
                    <div className="auther_icon">
                      <span>{getWordInitials(question.written_by)}</span>
                    </div>
                    <div className="auther_cntnt">
                      <h6>
                        Written by <span>{question.written_by}</span>
                      </h6>
                      <p>{timeAgo(question.created_at)}</p>
                    </div>
                  </div>
                  <div className="br"></div>
                  <p><Text string={question.answer} /></p>
                  <div className="review_blk text-center">
                    <h4>Did this answer your question?</h4>
                    <div className="btn_blk">
                      <button type="button">
                        <img src="/images/excelent.svg" alt="" />
                      </button>
                      <button type="button">
                        <img src="/images/angry.svg" alt="" />
                      </button>
                      <button type="button">
                        <img src="/images/sad.svg" alt="" />
                      </button>
                      <button type="button">
                        <img src="/images/good.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SearchArticle;
