import React from "react";
import { useSelector } from "react-redux";
import { eventDateFormat } from "../../../helpers/helpers";
import { useState } from "react";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";

const Exports = () => {
  const [isExporting, setIsExporting] = useState(false);
  const exportsData = useSelector(
    (state) => state.fetchProfileSettings.exportsData
  );

  const handleDownloadCsv = (id) => {
    setIsExporting(true);
    http
      .post(`export-filtered-results-dashboard`, doObjToFormData({ id }))
      .then(({ data }) => {
        const { properties, filename } = data;
        if (properties.length > 0) {
          const columnNames = ["sr", ...Object.keys(properties[0])];
          const csvContent =
            "data:text/csv;charset=utf-8," +
            [columnNames.map((column) => `"${column}"`).join(",")]
              .concat(
                properties.map((item, index) =>
                  [
                    `"${index + 1}"`, // Index as column
                    ...Object.values(item).map((value) => `"${value}"`) // Data as rows
                  ].join(",")
                )
              )
              .join("\n");

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${filename}.csv`);
          document.body.appendChild(link);
          link.click();
        }

        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
      });
  };

  return (
    <>
      <div className="setting_blk cell_min_padding">
        <h4>Exports</h4>
        <div className="table_blk">
          <div className="inner_tbl">
            <table className="cell_hide_dowload_lbl">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {exportsData && exportsData.length > 0 ? (
                  exportsData.map((row, i) => (
                    <tr key={i+100000000000}>
                      <td>{eventDateFormat(row.created_date)}</td>
                      <td>{row.file_name}.csv</td>
                      <td>Completed</td>
                      <td>
                        <div
                          className="down_load_lbl"
                          onClick={() => handleDownloadCsv(row.id)}
                          disabled={isExporting}
                        >
                          <span>Download</span>
                          <img src="/images/download_icon.svg" alt="" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>The properties you have exported will show here.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exports;
