import {
  FETCH_SIGN_IN_CONTENT,
  FETCH_SIGN_IN_CONTENT_SUCCESS,
  FETCH_SIGN_IN_CONTENT_FAILED,
  SIGN_IN_ACCOUNT_MESSAGE,
  SIGN_IN_ACCOUNT_MESSAGE_SUCCESS,
  SIGN_IN_ACCOUNT_MESSAGE_FAILED,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED,
  SIGN_IN_VERIFICATION_MESSAGE,
  SIGN_IN_VERIFICATION_MESSAGE_SUCCESS,
  SIGN_IN_VERIFICATION_MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  is: false,
  authToken: localStorage.getItem("authToken"),
  authentication: false,
  memData: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SIGN_IN_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_SIGN_IN_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_SIGN_IN_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SIGN_IN_ACCOUNT_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SIGN_IN_ACCOUNT_MESSAGE_SUCCESS:
      localStorage.setItem("email", payload.mem_email);
      return {
        ...state,
        isFormProcessing: false,
        authentication: true
      };
    case SIGN_IN_ACCOUNT_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case SIGN_IN_VERIFICATION_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SIGN_IN_VERIFICATION_MESSAGE_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      return {
        ...state,
        isFormProcessing: false,
        authentication: true
      };
    case SIGN_IN_VERIFICATION_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case SIGN_IN_ACCOUNT_POPUP_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      return {
        ...state,
        authToken: payload.authToken,
        isFormProcessing: true
      };
    case SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
