import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format_amount } from "../../../helpers/helpers";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import CustomPopup from "../../../states/actions/CancelPopup";

const MembershipManagement = ({
  subscription,
  site_settings,
  member,
  handleCancelSubscription,
  isFormProcessing,
}) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };
  console.log(subscription);
  return (
    <>
      <div className="setting_blk wide_md">
        <h4>Membership management</h4>
        <div className="mini_text_sm">
          Contact Sales at{" "}
          <Link to={"tel:" + site_settings?.site_phone}>
            {site_settings?.site_phone}
          </Link>{" "}
          or{" "}
          <Link to={"mailto:" + site_settings?.site_email}>
            {site_settings?.site_email}
          </Link>
        </div>
        <div className="table_blk">
          <div className="inner_tbl">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>License</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="mini_color_text">
                      {member?.mem_fname + " " + member?.mem_lname} (
                      {member?.mem_email})
                    </div>
                  </td>
                  <td>{subscription?.plan_name} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="pkg_upgrade_blk pro_upgrade_blk">
        <div className="pkg_badge">
          <img src="/images/green-star.svg" alt="" />
          <span>{subscription?.plan_name}</span>
        </div>
        <div className="cntnt">
          <p>
            {format_amount(subscription?.price)} /{" "}
            {subscription?.plan_type === "monthly"
              ? "mo"
              : subscription?.plan_type === "yearly"
              ? "yr"
              : ""}
          </p>
          <div className="price_body">
            <ul>
              <li>
                {parseInt(subscription?.clicks_count)} <span>clicks</span>
              </li>
              <li>
                {parseInt(subscription?.downloads_count)} <span>downloads</span>
              </li>
              <li>
                {parseInt(subscription?.user_count)} <span>User</span>
              </li>
            </ul>
          </div>
          <p>
            <small>
              {subscription?.subscription_status === 1
                ? "Reset"
                : subscription?.subscription_status === 0
                ? "Expired"
                : "Reset"}{" "}
              on {subscription?.expiry_date}
            </small>
          </p>
        </div>
        {subscription?.subscription_status === 1 ? (
          <Link to="/pricing" className="site_btn">
            Upgrade Now
          </Link>
        ) : (
          ""
        )}

        {subscription !== undefined &&
        subscription !== null &&
        subscription !== "" &&
        subscription?.id > 0 &&
        subscription?.subscription_status === 1 ? (
          // <Link to="#!" className="site_btn sm_btn cancel_subscription_btn" onClick={() => { if (window.confirm('Are you sure you wish to cancel subscription?')) handleCancelSubscription(subscription?.id) }} disabled={isFormProcessing ? true : false}>
          <Link to="#!" className="site_btn sm_btn" onClick={handleToggle}>
            Cancel Subscription
            <FormProcessingSpinner isFormProcessing={isFormProcessing} />
          </Link>
        ) : (
          ""
        )}
      </div>
      {show ? (
        <>
          <div>
            <CustomPopup onCancel={handleToggle} />
          </div>
        </>
      ) : null}
      {/* <div className="pkg_upgrade_blk simple_upgrade_blk">
        <div className="cntnt text-center">
            <p>Exports Remaining</p>
            <h4>1000</h4>
            <p><small>Reset on 28 Sep 2022 | 04:59 GMT+5</small></p>
        </div>
        <Link to="/" className="site_btn">Upgrade Now</Link>
      </div> */}
    </>
  );
};

export default MembershipManagement;
