import React, { useEffect } from "react";
import Packages from "./Packages";
import Faqs from "./Faqs";

import {
  fetchPricing,
  cancelSubscription,
  updateSubscription
} from "../../../states/actions/fetchPricing";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Features from "./features";
import MetaGenerator from "../../common/meta-generator";

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.fetchPricing.content);
  const isLoading = useSelector((state) => state.fetchPricing.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchPricing.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, faqs, meta } = data;

  useEffect(() => {
    dispatch(fetchPricing());
  }, []);
  const handleCancelSubscription = (data) => {
    dispatch(cancelSubscription(data));
  };
  const handleUpdateSubscription = (data) => {
    // let newData = { plan_id: data };
    // navigate(`/checkout/${data}`);
    window.location.href = `/checkout/${data}?action=upgrade`;

    // dispatch(updateSubscription(newData));
  };

  // useDocumentTitle(data.page_title);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <ToastContainer />
          <Packages
            handleCancelSubscription={handleCancelSubscription}
            isFormProcessing={isFormProcessing}
            handleUpdateSubscription={handleUpdateSubscription}
          />
          <Features />
          <Faqs content={content} faqs={faqs} />
        </>
      )}
    </>
  );
};

export default Pricing;
