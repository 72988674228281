import React, { useState, Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MultiRangeSlider from "../multirangeslider/MultiRangeSlider";

const MobileAdvance = () => {
  const [moreFilters, setMoreFilters] = useState(false);

  const ToggleMoreFilters = () => {
    setMoreFilters(!moreFilters);
  };

  return (
    <>
      <div className="mobile_top_filter_sec">
        <div className="contain-fluid">
          <div className="mobile_filter_nav">
            <div className="colL">
              <span>Home</span> <span>{">"}</span> <span>Search</span>
            </div>
            {/* <div className="colR drop_filter">
              <button onClick={ToggleMoreFilters}>
                <img src="/images/ad_filter.svg" alt="" />
              </button>
              <div
                className={
                  moreFilters
                    ? "drop_cnt wide_lg filter_height active"
                    : "drop_cnt filter_height wide_lg"
                }
              >
                <div className="more_filter_drop tabs_filter">
                  <div className="sub_filter">
                    <div className="tabs_main">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <button className="site_btn blank">
                              Property type
                            </button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">
                              Building & Lot
                            </button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">Owner</button>
                          </Tab>
                          
                          <Tab>
                            <button className="site_btn blank">Sales</button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">Dept</button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">
                              Distressed
                            </button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">Tax</button>
                          </Tab>
                          <Tab>
                            <button className="site_btn blank">
                              My Properties
                            </button>
                          </Tab>
                        </TabList>
                        <TabPanel>
                          <Tabs>
                            <TabList>
                              <Tab>
                                <button className="mini_btn_tab">
                                  Commercial
                                </button>
                              </Tab>
                              <Tab>
                                <button className="mini_btn_tab">
                                  Industrial
                                </button>
                              </Tab>
                              <Tab>
                                <button className="mini_btn_tab">
                                  Multifamily
                                </button>
                              </Tab>
                              <Tab>
                                <button className="mini_btn_tab">
                                  Spacial purpose
                                </button>
                              </Tab>
                              <Tab>
                                <button className="mini_btn_tab">
                                  Vacant land
                                </button>
                              </Tab>
                              <Tab>
                                <button className="mini_btn_tab">Other</button>
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <form action="">
                                <div className="check_flex flex">
                                  <div className="col">
                                    <ul>
                                      <li>
                                        <div className="lbl_btn">
                                          <input
                                            type="checkbox"
                                            name="commecial"
                                            id="ch_1"
                                          />
                                          <label htmlFor="ch_1">
                                            <span>
                                              Commercial General/misc.
                                            </span>
                                            <span>55,543,56</span>
                                          </label>
                                        </div>
                                        <ul>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_1_1"
                                              />
                                              <label htmlFor="ch_1_1">
                                                <span>
                                                  Commercial (General)
                                                </span>
                                                <span>4,43,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_1_2"
                                              />
                                              <label htmlFor="ch_1_2">
                                                <span>
                                                  Commercial Condeminium
                                                </span>
                                                <span>10,2,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_1_3"
                                              />
                                              <label htmlFor="ch_1_3">
                                                <span>Self-Storage</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <div className="lbl_btn">
                                          <input
                                            type="checkbox"
                                            name="commecial"
                                            id="ch_2"
                                          />
                                          <label htmlFor="ch_2">
                                            <span>Office</span>
                                            <span>55,543,56</span>
                                          </label>
                                        </div>
                                        <ul>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_2_1"
                                              />
                                              <label htmlFor="ch_2_1">
                                                <span>Bussiness park</span>
                                                <span>4,43,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_2_2"
                                              />
                                              <label htmlFor="ch_2_2">
                                                <span>Financial building</span>
                                                <span>10,2,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_2_3"
                                              />
                                              <label htmlFor="ch_2_3">
                                                <span>Medical condeminium</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_2_4"
                                              />
                                              <label htmlFor="ch_2_4">
                                                <span>Office building</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_2_5"
                                              />
                                              <label htmlFor="ch_2_5">
                                                <span>Office condeminium</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col">
                                    <ul>
                                      <li>
                                        <div className="lbl_btn">
                                          <input
                                            type="checkbox"
                                            name="commecial"
                                            id="ch_3"
                                          />
                                          <label htmlFor="ch_3">
                                            <span>Hospital</span>
                                            <span>55,543,56</span>
                                          </label>
                                        </div>
                                        <ul>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_3_1"
                                              />
                                              <label htmlFor="ch_3_1">
                                                <span>Hotel</span>
                                                <span>4,43,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_3_2"
                                              />
                                              <label htmlFor="ch_3_2">
                                                <span>Hotel Condeminium</span>
                                                <span>10,2,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_3_3"
                                              />
                                              <label htmlFor="ch_3_3">
                                                <span>Motel</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_3_4"
                                              />
                                              <label htmlFor="ch_3_4">
                                                <span>Resort</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_3_5"
                                              />
                                              <label htmlFor="ch_3_5">
                                                <span>Transient Lodging</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <div className="lbl_btn">
                                          <input
                                            type="checkbox"
                                            name="commecial"
                                            id="ch_4"
                                          />
                                          <label htmlFor="ch_4">
                                            <span>Mixed Use</span>
                                            <span>55,543,56</span>
                                          </label>
                                        </div>
                                        <ul>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_1"
                                              />
                                              <label htmlFor="ch_4_1">
                                                <span>
                                                  Mixed Use:
                                                  Commercial/Industrial
                                                </span>
                                                <span>4,43,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_2"
                                              />
                                              <label htmlFor="ch_4_2">
                                                <span>
                                                  Mixed Use: Hotel/Residential
                                                </span>
                                                <span>10,2,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_3"
                                              />
                                              <label htmlFor="ch_4_3">
                                                <span>
                                                  Mixed Use: Multiple Uses
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_4"
                                              />
                                              <label htmlFor="ch_4_4">
                                                <span>
                                                  Mixed Use: Office/Residential
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_5"
                                              />
                                              <label htmlFor="ch_4_5">
                                                <span>
                                                  Mixed Use: Office/Retail
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_4_6"
                                              />
                                              <label htmlFor="ch_4_6">
                                                <span>
                                                  Mixed Use: Retail/Residential
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col">
                                    <ul>
                                      <li>
                                        <div className="lbl_btn">
                                          <input
                                            type="checkbox"
                                            name="commecial"
                                            id="ch_5"
                                          />
                                          <label htmlFor="ch_5">
                                            <span>Retail</span>
                                            <span>55,543,56</span>
                                          </label>
                                        </div>
                                        <ul>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_1"
                                              />
                                              <label htmlFor="ch_5_1">
                                                <span>
                                                  Automative Repair Shop
                                                </span>
                                                <span>4,43,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_2"
                                              />
                                              <label htmlFor="ch_5_2">
                                                <span>Automative Sales</span>
                                                <span>10,2,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_3"
                                              />
                                              <label htmlFor="ch_5_3">
                                                <span>Bar/Nightclub</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_4"
                                              />
                                              <label htmlFor="ch_5_4">
                                                <span>Car Wash</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_5"
                                              />
                                              <label htmlFor="ch_5_5">
                                                <span>
                                                  Convenience/Service Station
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_6"
                                              />
                                              <label htmlFor="ch_5_6">
                                                <span>Department Store</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_7"
                                              />
                                              <label htmlFor="ch_5_7">
                                                <span>
                                                  Fast Food Restaurent
                                                </span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_8"
                                              />
                                              <label htmlFor="ch_5_8">
                                                <span>Laundry</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_9"
                                              />
                                              <label htmlFor="ch_5_9">
                                                <span>Restaurants</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_10"
                                              />
                                              <label htmlFor="ch_5_10">
                                                <span>Retail store</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_11"
                                              />
                                              <label htmlFor="ch_5_11">
                                                <span>Shopping center</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_12"
                                              />
                                              <label htmlFor="ch_5_12">
                                                <span>Supermarket</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="commecial"
                                                id="ch_5_13"
                                              />
                                              <label htmlFor="ch_5_13">
                                                <span>Wholesales</span>
                                                <span>3,56</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="btn_blk text-right">
                                  <button className="site_btn blank yellow_blank">
                                    Close
                                  </button>
                                  <button className="site_btn">Apply</button>
                                </div>
                              </form>
                            </TabPanel>
                            <TabPanel>
                              <p>text is here</p>
                            </TabPanel>
                          </Tabs>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex">
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Year built</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">From</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">until</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Lot size (SF)</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Year renovated</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">From</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">until</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Lot size (Acreage)</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Total unite</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <h6>Year renovated</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form nested_half_flex">
                                <div className="form_blk">
                                  <h6>Zoning</h6>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <input
                                        type="text"
                                        className="input"
                                        placeholder="Example: C2, I3, R5"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form_blk">
                                  <h6>Opportunity zone?</h6>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner"
                                        id="yes"
                                        class="hidden"
                                      />
                                      <label
                                        for="yes"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner"
                                        id="no"
                                        class="hidden"
                                      />
                                      <label
                                        for="no"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button className="site_btn blank yellow_blank">
                                Clear
                              </button>
                              <button className="site_btn">Apply</button>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Owner</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="input"
                                    placeholder="Person or Company name"
                                  />
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Properties in portfolio</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Owner type</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_type"
                                        id="Company"
                                        class="hidden"
                                      />
                                      <label
                                        for="Company"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Company
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_type"
                                        id="Person"
                                        class="hidden"
                                      />
                                      <label
                                        for="Person"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Person
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Owner Occupied</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="lbl_btn">
                                    <input
                                      type="checkbox"
                                      name="owner_type"
                                      id="Person"
                                    />
                                    <label for="Person">
                                      Owner Occupied Property
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Owner contact information</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_info"
                                        id="phone_include"
                                        class="hidden"
                                      />
                                      <label
                                        for="phone_include"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Include phone number
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_info"
                                        id="email_include"
                                        class="hidden"
                                      />
                                      <label
                                        for="email_include"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Include email address
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_info"
                                        id="mailing_include"
                                        class="hidden"
                                      />
                                      <label
                                        for="mailing_include"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Include mailing address
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>
                                      In-state reports owner mailing address
                                    </h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_mailing_address"
                                        id="yes"
                                        class="hidden"
                                      />
                                      <label
                                        for="yes"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="owner_mailing_address"
                                        id="no"
                                        class="hidden"
                                      />
                                      <label
                                        for="no"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Portfolio assessed value</h6>
                                  </div>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="br"></div>
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Reported owner</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="input"
                                    placeholder="Reported owner"
                                  />
                                </div>
                                <div className="br"></div>
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Reported owner mailing address</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="input"
                                    placeholder="Mailing address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button className="site_btn blank yellow_blank">
                                Close
                              </button>
                              <button className="site_btn">Apply</button>
                            </div>
                          </form>
                        </TabPanel>
                        
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col half_flex_form">
                                <div className="form_blk padding_right_span">
                                  <div className="info_lbl_lbl">
                                    <h6>Sales date</h6>
                                    <div className="span_type_lbl">
                                      <span className="btn_sm_span fil_btn">
                                        Within
                                      </span>
                                      <span className="btn_sm_span">
                                        Not within
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="90_days"
                                        class="hidden"
                                      />
                                      <label
                                        for="90_days"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 90 days
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="past_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="past_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="2_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="2_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 2 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="5_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="5_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 5 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="10_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="10_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 10 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_date"
                                        id="15_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="15_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 15 year
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk range_slide">
                                  <div className="info_lbl_lbl">
                                    <h6>Sales price</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="span_type_lbl">
                                      <span className="btn_sm_span fil_btn">
                                        Preset
                                      </span>
                                      <span className="btn_sm_span">
                                        Custom
                                      </span>
                                    </div>
                                  </div>
                                  <MultiRangeSlider
                                    min={0}
                                    max={674333}
                                    onChange={({ min, max }) =>
                                      console.log(`min = ${min}, max = ${max}`)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col half_flex_form mrgn_3rem">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Multi-parcel sales?</h6>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_multi_parcel"
                                        id="yes"
                                        class="hidden"
                                      />
                                      <label
                                        for="yes"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="sale_multi_parcel"
                                        id="no"
                                        class="hidden"
                                      />
                                      <label
                                        for="no"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk range_slide">
                                  <div className="info_lbl_lbl">
                                    <h6>Sales price / SF</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="span_type_lbl">
                                      <span className="btn_sm_span fil_btn">
                                        Preset
                                      </span>
                                      <span className="btn_sm_span">
                                        Custom
                                      </span>
                                    </div>
                                  </div>
                                  <MultiRangeSlider
                                    min={0}
                                    max={674333}
                                    onChange={({ min, max }) =>
                                      console.log(`min = ${min}, max = ${max}`)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col half_flex_form mrgn_3rem">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Sales price / Acre</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="br"></div>
                                <div className="br"></div>
                                <div className="doted_flex purple_clr">
                                  <span className="site_btn">High</span>
                                  <span className="doted_line"></span>
                                  <p>55,543,56</p>
                                </div>
                                <div className="sm_br_gap"></div>
                                <div className="doted_flex green_clr">
                                  <span className="site_btn">Medium</span>
                                  <span className="doted_line"></span>
                                  <p>23,543,52</p>
                                </div>
                              </div>
                            </div>
                            <div className="footer_filter_btn">
                              <div className="text_left">
                                <p>Filters apply to most recent transaction</p>
                              </div>
                              <div className="btn_blk text-right">
                                <button className="site_btn blank yellow_blank">
                                  Close
                                </button>
                                <button className="site_btn">Apply</button>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col half_flex_form">
                                <div className="form_blk range_slide">
                                  <div className="info_lbl_lbl">
                                    <h6>Mortage amount</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="span_type_lbl">
                                      <span className="btn_sm_span fil_btn">
                                        Preset
                                      </span>
                                      <span className="btn_sm_span">
                                        Custom
                                      </span>
                                    </div>
                                  </div>
                                  <MultiRangeSlider
                                    min={0}
                                    max={674333}
                                    onChange={({ min, max }) =>
                                      console.log(`min = ${min}, max = ${max}`)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col half_flex_form mrgn_3rem">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Maturity Date</h6>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="90_days"
                                        class="hidden"
                                      />
                                      <label
                                        for="90_days"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 90 days
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="past_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="past_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="2_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="2_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 2 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="5_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="5_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 5 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="10_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="10_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Past 10 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="maturity_date"
                                        id="custom"
                                        class="hidden"
                                      />
                                      <label
                                        for="custom"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Custom
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Mortgage origination</h6>
                                    <div className="span_type_lbl">
                                      <span className="btn_sm_span fil_btn">
                                        Within
                                      </span>
                                      <span className="btn_sm_span">
                                        Not within
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="last_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="last_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="1_2_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="1_2_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last 1-2 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="2_3_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="2_3_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last 2-3 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="3_5_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="3_5_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last 3-5 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="5_7_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="5_7_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last 5-7 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="7_8_year"
                                        class="hidden"
                                      />
                                      <label
                                        for="7_8_year"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Last 7-8 year
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="mortage_origination"
                                        id="custom"
                                        class="hidden"
                                      />
                                      <label
                                        for="custom"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Custom
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>CMBS Loan</h6>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="CMBS_loan"
                                        id="Current"
                                        class="hidden"
                                      />
                                      <label
                                        for="Current"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Current
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="CMBS_loan"
                                        id="Historical"
                                        class="hidden"
                                      />
                                      <label
                                        for="Historical"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Historical
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="footer_filter_btn">
                              <div className="text_left">
                                <p>Filters apply to most recent transaction</p>
                              </div>
                              <div className="btn_blk text-right">
                                <button className="site_btn blank yellow_blank">
                                  Close
                                </button>
                                <button className="site_btn">Apply</button>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Auction date</h6>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="auction_date"
                                        id="next_week"
                                        class="hidden"
                                      />
                                      <label
                                        for="next_week"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Next 2 weeks
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="auction_date"
                                        id="next_month"
                                        class="hidden"
                                      />
                                      <label
                                        for="next_month"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Next month
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="auction_date"
                                        id="next_3_month"
                                        class="hidden"
                                      />
                                      <label
                                        for="next_3_month"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Next 3 months
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="auction_date"
                                        id="custom"
                                        class="hidden"
                                      />
                                      <label
                                        for="custom"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Custom
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>On CMBS Watchlist</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="CMBS_watchlist"
                                        id="currently"
                                        class="hidden"
                                      />
                                      <label
                                        for="currently"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Currently
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="CMBS_watchlist"
                                        id="previously"
                                        class="hidden"
                                      />
                                      <label
                                        for="previously"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Previously
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Pre-foreclosure Category</h6>
                                  </div>
                                  <div class="boolean_flex">
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="pre-foreclosure"
                                        id="all"
                                        class="hidden"
                                      />
                                      <label
                                        for="all"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        All
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="pre-foreclosure"
                                        id="judgement"
                                        class="hidden"
                                      />
                                      <label
                                        for="judgement"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Final Judgement of Foreclosure
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="pre-foreclosure"
                                        id="lis_pendens"
                                        class="hidden"
                                      />
                                      <label
                                        for="lis_pendens"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Lis Pendens
                                      </label>
                                    </div>
                                    <div class="lbl_btn">
                                      <input
                                        type="radio"
                                        name="pre-foreclosure"
                                        id="notice_default"
                                        class="hidden"
                                      />
                                      <label
                                        for="notice_default"
                                        class="site_btn blank yellow_blank main_color"
                                      >
                                        Notice of Default
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button className="site_btn blank yellow_blank">
                                Close
                              </button>
                              <button className="site_btn">Apply</button>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Tax Year</h6>
                                  </div>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="br"></div>
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Tax amount</h6>
                                  </div>
                                  <div className="half_flex">
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Min</option>
                                      </select>
                                    </div>
                                    <span></span>
                                    <div className="half_field">
                                      <select name="" id="" className="input">
                                        <option value="">Max</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button className="site_btn blank yellow_blank">
                                Close
                              </button>
                              <button className="site_btn">Apply</button>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form action="">
                            <div className="mix_half_flex flex owner_flex">
                              <div className="col half_flex_form">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Labels</h6>
                                    <div className="info_span">
                                      <span className="ic_on">
                                        <img
                                          src="/images/info-circle.svg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="input"
                                    placeholder="Search for label"
                                  />
                                  <div className="sm_br_gap"></div>
                                  <div className="form_blk lbl_table_search">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Labels</th>
                                          <th>Includes</th>
                                          <th>Exclude</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="lbl_dot blue_dot">
                                            Good one (1)
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              name="lbl_good"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              name="lbl_good"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="lbl_dot pink_dot">
                                            Unlocked owners (5)
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              name="lbl_good"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              name="lbl_good"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="br"></div>
                                  <div className="form_blk">
                                    <div className="info_lbl_lbl">
                                      <h6>Viewed Date</h6>
                                      <div className="span_type_lbl">
                                        <span className="btn_sm_span fil_btn">
                                          Within
                                        </span>
                                        <span className="btn_sm_span">
                                          Not within
                                        </span>
                                      </div>
                                    </div>
                                    <div class="boolean_flex">
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="past_day"
                                          class="hidden"
                                        />
                                        <label
                                          for="past_day"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Past day
                                        </label>
                                      </div>
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="past_7_days"
                                          class="hidden"
                                        />
                                        <label
                                          for="past_7_days"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Past 7 days
                                        </label>
                                      </div>
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="30_days"
                                          class="hidden"
                                        />
                                        <label
                                          for="30_days"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Past 30 days
                                        </label>
                                      </div>
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="3_mnth"
                                          class="hidden"
                                        />
                                        <label
                                          for="3_mnth"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Past 3 months
                                        </label>
                                      </div>
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="custom"
                                          class="hidden"
                                        />
                                        <label
                                          for="custom"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Custom
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="br br_mrgn_10"></div>
                                  <div className="form_blk">
                                    <div className="info_lbl_lbl">
                                      <h6>Exported properties</h6>
                                    </div>
                                    <div class="boolean_flex">
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="properteis"
                                          id="include"
                                          class="hidden"
                                        />
                                        <label
                                          for="include"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Include
                                        </label>
                                      </div>
                                      <div class="lbl_btn">
                                        <input
                                          type="radio"
                                          name="viewed_date"
                                          id="exclude"
                                          class="hidden"
                                        />
                                        <label
                                          for="exclude"
                                          class="site_btn blank yellow_blank main_color"
                                        >
                                          Exclude
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Note text</h6>
                                  </div>
                                  <input
                                    type="text"
                                    placeholder="Text"
                                    className="input"
                                  />
                                </div>
                                <div className="br br_mrgn_10"></div>
                                <div className="form_blk">
                                  <div className="info_lbl_lbl">
                                    <h6>Has a note?</h6>
                                  </div>
                                  <div className="lbl_btn">
                                    <input
                                      type="checkbox"
                                      name="note"
                                      id="note"
                                    />
                                    <label htmlFor="note">Yes</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button className="site_btn blank yellow_blank">
                                Close
                              </button>
                              <button className="site_btn">Apply</button>
                            </div>
                          </form>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAdvance;
