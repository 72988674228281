import React, { useEffect } from "react";
import Faqs from "../pricing/Faqs";
import Banner from "./Banner";
import SocialInfo from "./Social-info";

import { fetchSupport } from "../../../states/actions/fetchSupport";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import MetaGenerator from "../../common/meta-generator";

const Support = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSupport.content);
  const isLoading = useSelector((state) => state.fetchSupport.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, faqs, meta } = data;

  useEffect(() => {
    dispatch(fetchSupport());
  }, []);

  // useDocumentTitle(data.page_title);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Banner content={content} />
          <SocialInfo content={content} />
          <Faqs content={content} faqs={faqs} />
        </>
      )}
    </>
  );
};

export default Support;
