import {
  FETCH_HOME_CONTENT,
  FETCH_HOME_CONTENT_SUCCESS,
  FETCH_HOME_CONTENT_FAILED,
  FETCH_INDUSTRIES_CONTENT,
  FETCH_INDUSTRIES_CONTENT_SUCCESS,
  FETCH_INDUSTRIES_CONTENT_FAILED,
  FETCH_INDUSTRIES_DETAILS_CONTENT,
  FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS,
  FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED,
  SAVE_JOB_HOME_1,
  SAVE_JOB_HOME_1_SUCCESS,
  SAVE_JOB_HOME_1_FAILED,
  SAVE_JOB_HOME_2,
  SAVE_JOB_HOME_2_SUCCESS,
  SAVE_JOB_HOME_2_FAILED,
  JOB_APPLIED_HOME_1,
  JOB_APPLIED_HOME_1_SUCCESS,
  JOB_APPLIED_HOME_1_FAILED,
  JOB_APPLIED_HOME_2,
  JOB_APPLIED_HOME_2_SUCCESS,
  JOB_APPLIED_HOME_2_FAILED,
  REPORT_AN_JOB_HOME,
  REPORT_AN_JOB_HOME_SUCCESS,
  REPORT_AN_JOB_HOME_FAILED,
  REPORT_AN_JOB_HOME_POPUP
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isJobSavingFirst: false,
  isJobSavingSecond: false,
  student_jobs: null,
  experienced_jobs: null,
  isJobApplyingFirst: false,
  isJobApplyingSecond: false,
  isJobReporting: false,
  showReportPopup: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HOME_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
        student_jobs: null,
        experienced_jobs: null
      };
    case FETCH_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        student_jobs: payload.student_jobs,
        experienced_jobs: payload.experienced_jobs
      };
    case FETCH_HOME_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        student_jobs: null,
        experienced_jobs: null
      };
    case FETCH_INDUSTRIES_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_INDUSTRIES_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_INDUSTRIES_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_INDUSTRIES_DETAILS_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_INDUSTRIES_DETAILS_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_INDUSTRIES_DETAILS_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SAVE_JOB_HOME_1:
      return {
        ...state,
        isJobSavingFirst: true
      };
    case SAVE_JOB_HOME_1_SUCCESS:
      const newJobs = state.student_jobs.map((job) => {
        if (job.id === payload.id) {
          job.saved = true;
        }
        return job;
      });
      return {
        ...state,
        isJobSavingFirst: false,
        student_jobs: newJobs
      };
    case SAVE_JOB_HOME_1_FAILED:
      return {
        ...state,
        isJobSavingFirst: false,
        error: payload
      };
    case SAVE_JOB_HOME_2:
      return {
        ...state,
        isJobSavingSecond: true
      };
    case SAVE_JOB_HOME_2_SUCCESS:
      const newJobs2 = state.experienced_jobs.map((job) => {
        if (job.id === payload.id) {
          job.saved = true;
        }
        return job;
      });
      return {
        ...state,
        isJobSavingSecond: false,
        experienced_jobs: newJobs2
      };
    case SAVE_JOB_HOME_2_FAILED:
      return {
        ...state,
        isJobSavingSecond: false,
        error: payload
      };
    case JOB_APPLIED_HOME_1:
      return {
        ...state,
        isJobApplyingFirst: true
      };
    case JOB_APPLIED_HOME_1_SUCCESS:
      const newJobs3 = state.student_jobs.map((job, index) => {
        if (job.id === payload.id) {
          job.applied = true;
        }
        return job;
      });
      return {
        ...state,
        isJobApplyingFirst: false,
        student_jobs: newJobs3
      };
    case JOB_APPLIED_HOME_1_FAILED:
      return {
        ...state,
        isJobApplyingFirst: false,
        error: payload
      };
    case JOB_APPLIED_HOME_2:
      return {
        ...state,
        isJobApplyingSecond: true
      };
    case JOB_APPLIED_HOME_2_SUCCESS:
      const newJobs4 = state.experienced_jobs.map((job, index) => {
        if (job.id === payload.id) {
          job.applied = true;
        }
        return job;
      });
      return {
        ...state,
        isJobApplyingSecond: false,
        experienced_jobs: newJobs4
      };
    case JOB_APPLIED_HOME_2_FAILED:
      return {
        ...state,
        isJobApplyingSecond: false,
        error: payload
      };
    case REPORT_AN_JOB_HOME_POPUP:
      return {
        ...state,
        showReportPopup: !state.showReportPopup
      };
    case REPORT_AN_JOB_HOME:
      return {
        ...state,
        isJobReporting: true
      };
    case REPORT_AN_JOB_HOME_SUCCESS:
      return {
        ...state,
        isJobReporting: false,
        showReportPopup: !state.showReportPopup
      };
    case REPORT_AN_JOB_HOME_FAILED:
      return {
        ...state,
        isJobReporting: false,
        error: payload
      };
    default:
      return state;
  }
}
