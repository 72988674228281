import React from "react";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const Team = ({ content }) => {
  return (
    <>
      <section className="abt_team">
        <div className="contain">
          <div className="flex">
            <div className="col" key={100000000}>
              <h3>
                <Text string={content.section3_lower_heading_3} />
              </h3>
              <h4>
                <Text string={content.section3_lower_short_heading_3} />
              </h4>
              <p>
                <Text string={content.section3_lower_text3} />
              </p>
              <div className="image">
                <ImageControl src={content.image3} folder="images" />
              </div>
            </div>
            <div className="col" key={2000000}>
              <h3>
                <Text string={content.section3_lower_heading_4} />
              </h3>
              <h4>
                <Text string={content.section3_lower_short_heading_4} />
              </h4>
              <p>
                <Text string={content.section3_lower_text4} />
              </p>
              <div className="image">
                <ImageControl src={content.image4} folder="images" />
              </div>
            </div>
            <div className="col" key={3000000}>
              <h3>
                <Text string={content.section3_lower_heading_5} />
              </h3>
              <h4>
                <Text string={content.section3_lower_short_heading_5} />
              </h4>
              <p>
                <Text string={content.section3_lower_text5} />
              </p>
              <div className="image">
                <ImageControl src={content.image5} folder="images" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
