import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import ImageControl from "../common/ImageControl";
import { logout, getWordInitials } from "../../helpers/helpers";
import { useSelector } from "react-redux";

function UserInfoDropdown({
  is_public,
  credit = false,
  total_available_credits = null,
}) {
  const memData = useSelector((state) => state.fetchSiteSettings.memData);
  const [dropShow, setDropShow] = useState(false);
  const ToggleDrop = () => {
    setDropShow(!dropShow);
  };

  const userInfoDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!userInfoDrop.current.contains(e.target)) {
        setDropShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  return (
    <>
      {memData && (
        <li
          className={
            dropShow ? "drop dashboard_dp active" : "drop dashboard_dp"
          }
          ref={userInfoDrop}
        >
          <NavLink to="#" onClick={ToggleDrop} className="profile_btn drop_btn">
            {getWordInitials(`${memData.mem_fname} ${memData.mem_lname}`)}
          </NavLink>
          <div className="sub">
            <div className="head_user">
              <div className="user_dp">
                <ImageControl
                  src={memData.mem_image}
                  folder="members"
                  isUser={true}
                />
              </div>
              <div className="user_name">
                <h6>{`${memData.mem_fname} ${memData.mem_lname}`}</h6>
                <p className="hide_ipad_ipad">Member</p>
                {credit == true ? (
                  <>
                    <li className="credit_nav show_ipad_only">
                      <div>
                        Credits :
                        <em>
                          {total_available_credits ? total_available_credits : 0}
                        </em>
                      </div>
                    </li>
                  </>
                ) : (
                  " "
                )}
              </div>
            </div>
            <ul className="lst">
              {is_public ? (
                <li onClick={ToggleDrop}>
                  <span>
                    <img src="/images/profile.svg" alt="" />
                  </span>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
              ) : (
                <React.Fragment>
                  <li onClick={ToggleDrop}>
                    <span>
                      <img src="/images/profile.svg" alt="" />
                    </span>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li onClick={ToggleDrop}>
                    <span>
                      <img src="/images/profile_setting.svg" alt="" />
                    </span>
                    <Link to="/account-settings">Profile Settings</Link>
                  </li>
                </React.Fragment>
              )}
              <li>
                <span>
                  <img src="/images/heart.svg" alt="" />
                </span>
                <Link to="/favorites">Favorites</Link>
              </li>
              <li onClick={ToggleDrop}>
                <span>
                  <img src="/images/support.svg" alt="" />
                </span>
                <Link to="/support">Support</Link>
              </li>
              
              <li>
                <span>
                  <img src="/images/logout.svg" alt="" />
                </span>
                <a href="#" onClick={() => logout()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </li>
      )}
    </>
  );
}

export default UserInfoDropdown;
