import MapboxDraw from '@mapbox/mapbox-gl-draw';
import length from '@turf/length';
import numeral from 'numeral';

function createVertex(parentId, coordinates, path, selected) {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: selected ? true : false
    },
    geonetry: {
      type: 'Point',
      coordinates
    }
  };
}

function createGeoJSONCircle(center, radiusInKm, parentId, points = 100) {
  const coords = {
    latitude: center[1],
    longitude: center[0]
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }

  ret.push(ret[0]);

  return {
    type: 'Feature',
    properties: {
      parent: parentId,
      active: 'true'
    },
    geometry: {
      type: 'Polygon',
      coordinates: [ret]
    }
  };
}

function getDisplayMeasurements(feature) {
  const drawnLength = length(feature) * 1000; // meters
  let kmUnits = 'm';
  let kmFormat = '0,0';
  let kmMeasurement;

  let miUnits = 'feet';
  let miFormat = '0,0';
  let miMeasurement;

  kmMeasurement = drawnLength;
  if (drawnLength >= 1000) {
    kmMeasurement = drawnLength / 1000;
    kmUnits = 'km';
    kmFormat = '0.00';
  }

  miMeasurement = drawnLength * 3.28084;
  if (miMeasurement >= 5280) {
    miMeasurement /= 5280;
    miUnits = 'mi';
    miFormat = '0.00';
  }

  const displayMeasurements = {
    km: {
      key: kmUnits,
      value: `${numeral(kmMeasurement).format(kmFormat)}`
    },
    mi: {
      key: miUnits,
      value: `${numeral(miMeasurement).format(miFormat)}`
    }
  };

  return displayMeasurements;
}

const doubleClickZoom = {
  enable: (ctx) => {
    setTimeout(() => {
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      )
        return;

      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;

      ctx.map.doubleClickZoom.enable();
    }, 0);
  }
};

const CircleDrawControl = MapboxDraw.modes.draw_line_string;

CircleDrawControl.clickAnywhere = function (state, e) {
  const data = state.line || state.polygon;

  if (state.currentVertexPosition === 1) {
    data.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    return this.changeMode('simple_select', { featureIds: [data.id] });
  }

  this.updateUIClasses({ mouse: 'add' });

  data.updateCoordinate(
    state.currentVertexPosition,
    e.lngLat.lng,
    e.lngLat.lat
  );

  if (state.direction === 'forward') {
    state.currentVertexPosition += 1;

    data.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
  } else {
    data.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }

  return null;
};

CircleDrawControl.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  const data = state.line;

  if (this.getFeature(data.id) === undefined) return;
  data.removeCoordinate('0');

  if (data.isValid()) {
    const lineGeoJson = data.toGeoJSON();
    const displayMeasurements = getDisplayMeasurements(lineGeoJson);

    const circleFeature = createGeoJSONCircle(
      lineGeoJson.geometry.coordinates[0],
      length(lineGeoJson),
      null,
      100
    );

    const circleWithRadius = {
      ...circleFeature,
      properties: {
        ...circleFeature.properties,
        ...displayMeasurements
      }
    };

    this.map.fire('draw.create', {
      features: [circleWithRadius]
    });
  } else {
    this.deleteFeature([data.id], { silent: true });
    this.changeMode('simple_select', {}, { silent: true });
  }
};

CircleDrawControl.toDisplayFeatures = function (state, geojson, display) {
  const data = state.line || state.polygon;
  const isActiveLine = geojson.properties.id === data.id;

  geojson.properties.active = isActiveLine ? 'true' : 'false';

  if (!isActiveLine) return display(geojson);

  if (geojson.geometry.coordinates.length < 2) return null;

  geojson.properties.meta = 'feature';

  display(
    createVertex(
      data.id,
      geojson.geometry.coordinates[
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      ],
      `${
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      }`,
      false
    )
  );

  display(geojson);

  const displayMeasurements = getDisplayMeasurements(geojson);

  // create custom feature for the current pointer position
  const currentVertex = {
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radiusMetric: displayMeasurements.metric,
      radiusStandard: displayMeasurements.standard,
      parent: data.id,
      active: 'true'
    },
    geometry: {
      type: 'Point',
      coordinates: geojson.geometry.coordinates[1]
    }
  };

  display(currentVertex);

  const center = geojson.geometry.coordinates[0];
  const radiusInKm = length(geojson, { units: 'kilometers' });
  const circleFeature = createGeoJSONCircle(center, radiusInKm, data.id);
  circleFeature.properties.meta = 'radius';

  display(circleFeature);

  return null;
};

export default CircleDrawControl;
