import {
    FETCH_FEEDBACK_CONTENT,
    FETCH_FEEDBACK_CONTENT_SUCCESS,
    FETCH_FEEDBACK_CONTENT_FAILED,
    FEEDBACK_MESSAGE,
    FEEDBACK_MESSAGE_SUCCESS,
    FEEDBACK_MESSAGE_FAILED,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isJobSavingFirst: false,
    isJobSavingSecond: false,
    student_jobs: null,
    experienced_jobs: null,
    isJobApplyingFirst: false,
    isJobApplyingSecond: false,
    isJobReporting: false,
    showReportPopup: false
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_FEEDBACK_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_FEEDBACK_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_FEEDBACK_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case FEEDBACK_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case FEEDBACK_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case FEEDBACK_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
