import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import http from "../../../helpers/http";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { FETCH_WISHLIST_CONTENT_SUCCESS } from "../../../states/actions/actionTypes";
import Text from "../../common/Text";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { doObjToFormData } from "../../../helpers/helpers";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";

const handleLikeClick = (e, propertyData, dispatch) => {
  e.preventDefault();
  if (!propertyData) {
    return; // or show an error message
  }
  let elem = e.target;
  //const { property_id } = propertyData; // or whatever property ID field name you have

  http
    .post("/adduserwishlist", propertyData)
    .then((response) => {
      if (response?.data?.status) {
        toast.success(response.data.msg, TOAST_SETTINGS);
      } else {
        toast.error(response.data.msg, TOAST_SETTINGS);
      }
      elem.classList.toggle("active");
      elem.parentNode.classList.toggle("active");

      dispatch({
        type: FETCH_WISHLIST_CONTENT_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      toast.error(<Text id="wishlist.error" />, TOAST_SETTINGS);
    });
};
export default function ResultPropertyBlk({
  val,
  handleHoverProperty,
  propertyLabelNames,
  i,
  handleToggleForLabel,
  searchCheckboxes,
  handleCheckboxChange
}) {
  const dispatch = useDispatch();
  const favoriteContent = useSelector(
    (state) => state.resultView.favoriteContent
  );
  const [isTypeLoading, setIsTypeLoading] = useState(false);

  const handleRequestMailOrTel = (e, type) => {
    e.preventDefault();
    setIsTypeLoading(true)
    http
      .post(`request-property-contact/${val?.id}`, doObjToFormData({ type: type }))
      .then(({ data }) => {
        setIsTypeLoading(false)
        if (data?.status === 1) {
          dispatch(updateMEmberCredits(data?.total_available_credits))
          if (type === 'email') {
            const link = document.createElement('a');
            link.href = `mailto:${data?.type}`;
            link.target = '_blank';

            // Programmatically click the link
            document.body.appendChild(link);
            link.click();
          }
          else if (type === 'call') {
            const link = document.createElement('a');
            link.href = `tel:${data?.type}`;
            link.target = '_blank';

            // Programmatically click the link
            document.body.appendChild(link);
            link.click();
          }
          else {
            toast.error("No response", TOAST_SETTINGS);
          }

        }
        else {
          toast.error(<Text string={data?.msg} />, TOAST_SETTINGS);
        }
      })
      .catch((error) => {
        setIsTypeLoading(false);
      });
  }
  return (
    <>
      <div
        className="inner_properties"
        key={i + 234 + 12789100000000}
        onMouseEnter={() => handleHoverProperty(val.parcel_id)}
      >
        <div className="dt_cnt">
          <div className="col">
            <div className="price_date">
              <div className="flex propBlkHeader">
                {
                  searchCheckboxes ?
                    <div className="cta_search_checkboxes">
                      <input type="checkbox"
                        name="owners[]"
                        checked={val?.checked}
                        onChange={(e) => handleCheckboxChange(e, i)} />
                      <span className="checkbox"></span>
                    </div>
                    :
                    ""
                }
                <h5>
                  <strong>${Number(val.price).toLocaleString()}</strong> |{" "}
                  {val.date}
                </h5>
              </div>
              <h3>
                <Link
                  to={`/property-details/${val.id}/?previousUrl=${encodeURIComponent(window.location.href)}`}
                // target="_blank"
                >
                  {val.title}
                </Link>{" "}
              </h3>
              <h5><strong>Last Viewed: </strong>{val?.last_viewed_date}</h5>
              <div className="custom_span">
                {
                  val?.labels_arr?.map((label_row, index) => {
                    return (

                      <span style={{ backgroundColor: label_row?.label_color }} key={index}>
                        {label_row?.label_name}
                      </span>

                    )
                  })
                }
              </div>

              {propertyLabelNames &&
                Object.keys(propertyLabelNames).map((labelKey, index) =>
                  (labelKey === val?.id || labelKey === val?.parcel_id) ? (
                    <span
                      key={index + 9870 + 12789100000000}
                      style={{
                        backgroundColor: propertyLabelNames[labelKey]
                          ?.label_color
                          ? propertyLabelNames[labelKey]?.label_color
                          : "black",
                      }}
                    >
                      {propertyLabelNames[labelKey]?.label_name}
                    </span>
                  ) : (
                    ""
                  )
                )}
              <p className="dim_text">{val.build}</p>
            </div>
          </div>
          <div className="col hide_cell_text_col">
            <h4>Owner</h4>
            <p className="dim_text">{val.owner}</p>
          </div>
        </div>
        <div className="dt_foot">
          <div className="col">
            <ul className="info">
              <li>
                <img src="/images/bed.svg" alt="4 Beds" />
                <span>{val.beds}</span>
              </li>
              <li>
                <img src="/images/bath.svg" alt="3 Bathrooms" />
                <span>{val.baths}</span>
              </li>
              <li>
                <img src="/images/dimension.svg" alt="8x10 m²" />
                <span>{Number(val.square).toLocaleString()}</span>
              </li>
            </ul>
          </div>
          <div className="col">
            <div className="show_cell_text_col">
              <h4>Owner</h4>
              <p className="dim_text">{val.owner}</p>
            </div>
            <ul className="social">
              <li>
                {val.email && (
                  <a href="#!" onClick={(e) => handleRequestMailOrTel(e, 'email')} disabled={isTypeLoading}>
                    <img src="/images/email-icon.svg" alt="" />
                  </a>
                )}
              </li>
              <li>
                {val.call && (
                  <a href="#!" onClick={(e) => handleRequestMailOrTel(e, 'call')} disabled={isTypeLoading}>
                    <img src="/images/call-icon.svg" alt="" />
                  </a>
                )}
              </li>

              {
                isTypeLoading ?
                  <div class="loadingio-spinner-eclipse-spin">
                    <div class="load-spinner">
                      <div></div>
                    </div>
                  </div>
                  :
                  ""
              }
            </ul>
          </div>
        </div>
        <div className="cta_opt_lbl">
          <ReactTooltip
            id="label-properties"
            place="bottom"
            content="Label Property"
          />
          <a
            href="javascript:void(0)"
            onClick={() => handleToggleForLabel(val.id)}
            data-tooltip-id="label-properties"
          >
            <img src="/images/ticket_star.svg" alt="" />
          </a>
          <ReactTooltip
            id="open-new-tab"
            place="bottom"
            content="View Property"
          />
          <Link
            to={`/property-details/${val.id}/?previousUrl=${encodeURIComponent(window.location.href)}`}
            target="_blank"
            data-tooltip-id="open-new-tab"
          >
            <img src="/images/maximize.svg" alt="" />
          </Link>
          <ReactTooltip
            id="add-favorite"
            place="bottom"
            content="Add To Favorites"
          />
          <button
            type="button"
            onClick={(e) => handleLikeClick(e, val.id, dispatch)}
            className={val.is_wish == 1 ? "active" : ""}
            data-tooltip-id="add-favorite"
          >
            <img src="/images/heart_ico.svg" alt="" />
          </button>
        </div>

      </div>
    </>
  );
}
