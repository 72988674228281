import React, { useEffect, useState } from "react";
import TabContent from "./Tab-content";
import Text from "../../common/Text";
import { convertToValidVideoLink, getUploadsUrl } from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import ReactPlayer from "react-player";
let index = 0;
function Banner({ data, content, key_index }) {
  const [bannerVideo, setBannerVideo] = useState(null);

  return (
    <>
      {
        bannerVideo !== null ?
          <div className="popup lg active">
            <div className="table_dv">
              <div className="table_cell">
                <div className="_inner">
                  <div className="x_btn" onClick={() => setBannerVideo(null)}></div>
                  <ReactPlayer
                    url={convertToValidVideoLink(bannerVideo)}
                    width="100%"
                    height="500px"
                    playing
                  />
                </div>
              </div>
            </div>
          </div>
          :
          ""
      }
      <section className="banner hide_cell_cnt_banner">
        <div>
          <div className="item">
            {/* <div className="videoBanner">
              <video
                preload="yes"
                autoPlay
                muted
                playsInline
                loop
                src={getUploadsUrl("videos", content?.video1)}
                width="100%"
                height="100%"
              >
                <source
                  src={getUploadsUrl("videos", content?.video1)}
                  type="video/mp4"
                />
              </video>
            </div> */}
            <div className="contain-fluid">
              <div className="flex">
                <div className="colL">
                  <div className="cntnt">
                    <h1>
                      <Text string={content?.banner_heading} />
                    </h1>
                  </div>
                  <div className="pera">
                    <p>
                      <Text string={content?.banner_text} />
                    </p>
                  </div>
                  <div className="flex">
                    <div className="_col" key={key_index + 1000}>
                      <div className="inner">
                        <h4 className="color">
                          {content?.banner_section_left_1}
                        </h4>
                        <p>{content?.banner_section_left_2}</p>
                      </div>
                    </div>
                    <div className="_col" key={key_index + 1}>
                      <div className="inner">
                        <h4 className="color">
                          {content?.banner_section_middle_1}
                        </h4>
                        <p>{content?.banner_section_middle_2}</p>
                      </div>
                    </div>
                    <div className="_col" key={key_index + 1}>
                      <div className="inner">
                        <h4 className="color">
                          {content?.banner_section_right_1}
                        </h4>
                        <p>{content?.banner_section_right_2}</p>
                      </div>
                    </div>
                  </div>
                  <div className="btn_blk">
                    {
                      content?.banner_search_button_text && content?.banner_search_button_link ?
                        <Link to={content?.banner_search_button_link} className="site_btn" >{content?.banner_search_button_text}</Link>
                        :
                        ""
                    }
                    {
                      content?.banner_search_button2_text && content?.banner_watch_demo ?
                        <Link to="#!" onClick={() => setBannerVideo(content?.banner_watch_demo)} className="site_btn" >{content?.banner_search_button2_text}</Link>
                        :
                        ""
                    }
                  </div>
                  {/* <form method="get" action="/search-property">
                    <div className="banner_form">
                      <div className="sm_form_ban">
                        <div className="flex ban_flex">
                          <div className="form_grp">
                            <label>
                              <Text string={content?.banner_search_left_1} />
                            </label>
                            <input
                              type="text"
                              placeholder={content?.banner_search_left_2}
                              className="input"
                              name="search_query"
                            />
                          </div>
                          <div className="form_grp">
                            <label>
                              <Text string={content?.banner_search_right_1} />
                            </label>
                            <div className="input_relative">
                              <input
                                type="text"
                                placeholder={content?.banner_search_right_2}
                                className="input"
                              />
                              <span>
                                <img src="/images/calendar.svg" alt="" />
                              </span>
                            </div>
                          </div>
                          <div className="form_grp">
                            <button className="site_btn" type="submit">
                              <Text string={content?.banner_search_button_text} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form> */}
                </div>
                <div className="colR">
                  <div className="image">
                    <ImageControl folder="images" src={content?.image11} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="banner show_cell_banner">
        <div>
          <div className="item">
            <div className="contain">
              <div className="flex">
                <div className="colL">
                  <div className="flex">
                    {data.stats?.map((val, i_index) => {
                      return (
                        <div className="_col" key={i_index + key_index}>
                          <div className="inner">
                            <h4 className="color">{val?.number}</h4>
                            <p>{val?.title}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="banner_form">
                    <div className="sm_form_ban">
                      <div className="flex ban_flex">
                        <TabContent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Banner;
