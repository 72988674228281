import { React } from "react";

export default function LoadingScreen() {
  
  return (
    <>
      <div className="upperlay"></div>
      <div id="pageloader" className="">
        <span className="loader"></span>
      </div>
    </>
  );
}
