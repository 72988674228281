import React, { useEffect } from "react";
import Banner from "./Banner";
import Feature from "./Feature";
import UploadTables from "./Uploads-tables";

import { fetchDataUpload } from "../../../states/actions/fetchDataUpload";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";

const UploadData = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchDataUpload.content);
  const isLoading = useSelector((state) => state.fetchDataUpload.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content } = data;

  useEffect(() => {
    dispatch(fetchDataUpload());
  }, []);

  useDocumentTitle(data.page_title);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Banner content={content} />
          <Feature content={content} />
          <UploadTables />
        </>
      )}
    </>
  );
};

export default UploadData;
