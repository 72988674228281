import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.history.scrollRestoration) {
                window.history.scrollRestoration = 'manual';
            }
            window.scrollTo(0, 0);
        };

        handleScroll();
    }, [pathname]);

    return null;
}
