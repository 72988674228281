import React from "react";

const Features = () => {
  return (
    <section className="feature_section">
        <div className="contain">
            <div className="sec_heading text-center">
                <h2>Each plan features</h2>
            </div>
            <div className="flex">
                <div className="col">
                    <div className="inner">
                        <p>Access to names, phone numbers, email addresses for more targeted marketing</p>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <p>Regularly updated property data for stronger investment opportunities</p>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <p>210k different combinations of filter criteria to narrow down great prospects</p>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <p>Increase productivity by saving your favorite searches</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Features;
