import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import http from "../../helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import {
  FETCH_LABEL_CONTENT_SUCCESS,
  FETCH_LABEL_CONTENT_FAILED,
} from "./actionTypes";

const popupStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '999'
};

const contentStyle = {
  position: 'relative',
  background: '#fff',
  borderRadius: '5px',
  width: '80%',
  maxWidth: '500px',
  padding: '20px',
  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)'
};

const LabelForm = (props) => {
  const [selectedOptionslabel, setSelectedOptionslabels] = useState([]);
  const propertyId = props.propertyIdforlabel;
  const [labelOptions, setLabelOptions] = useState([]);
  const popupRef = useRef();

  const handleSelectChange = (selectedOptionslabel) => {
    setSelectedOptionslabels(selectedOptionslabel);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedValues = selectedOptionslabel.value;

    if (selectedValues !== undefined && selectedValues !== '' && selectedValues !== null) {


      const myFormData = {
        labelProperties: selectedValues,
        property_id: propertyId
      };

      http.post("save-label-properties", myFormData)
        .then(({ data }) => {
          console.log(data);
          toast.success(data.msg);
          const updatedLabelNames = selectedValues;
          props.onLabelFormSubmit(propertyId, updatedLabelNames, data?.label_color);
          props.onCancel();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error('Please select at least one label property.');
    }
  };


  const handleOutsideClick = (e) => {
    if (!popupRef.current.contains(e.target)) {
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    // Fetch label names from the database or any other data source
    // and set the labelOptions state with the retrieved data
    const fetchLabelNames = async () => {

      http.post("get-user-label-properties", '')
        .then(({ data }) => {
          console.log(data);
          const labelNames = data.labels; // Assuming the label names are returned in the 'labels' field of the response
          const options = labelNames.map((name) => ({
            value: name.label_name,
            label: name.label_name
          }));
          setLabelOptions(options);

        })
        .catch((error) => {
          console.error(error);
        });


    };

    fetchLabelNames();

  }, []);
  return (
    <div style={popupStyle} >
      <div ref={popupRef} style={contentStyle} >
        <h3>Label Properties</h3>
        <div className="mini_mini_br"></div>
        <form onSubmit={handleSubmit} className="new_lbl_form_new">
          <input type="hidden" name="property_id" value={propertyId} />
          <Select
            options={labelOptions}
            value={selectedOptionslabel}
            onChange={handleSelectChange}

          />
          <div className="btn_blk center" style={{ display: 'flex', justifyContent: 'center' }}>
            <button type="submit" className="site_btn">
              Submit
            </button>
            <button type="button" onClick={props.onCancel} className="site_btn blank">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LabelForm;
