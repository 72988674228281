import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.scss";
import "./custom.css";
import App from "./App.jsx";

import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./states/store";
import Idle from "react-idle";

const root = ReactDOM.createRoot(document.getElementById("root"));

const IDLE_USER_TIMEOUT = 1000 * 60 * 150000; // 15 minutes

const handleIdleUser = (idle) => {
  if (idle && localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    window.location.replace("/login");
  }
};

root.render(
  <Provider store={store}>
    <Idle
      timeout={IDLE_USER_TIMEOUT}
      onChange={({ idle }) => handleIdleUser(idle)}
    />
    <App />
  </Provider>
);
