import {
    FETCH_EXPORT_CONTENT,
    FETCH_EXPORT_CONTENT_SUCCESS,
    FETCH_EXPORT_CONTENT_FAILED,
    EXPORT_MESSAGE,
    EXPORT_MESSAGE_SUCCESS,
    EXPORT_MESSAGE_FAILED,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_EXPORT_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_EXPORT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_EXPORT_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case EXPORT_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case EXPORT_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
            };
        case EXPORT_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
