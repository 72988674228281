import {
  FETCH_TALENT_PROFILE,
  FETCH_TALENT_PROFILE_SUCCESS,
  FETCH_TALENT_PROFILE_FAILED,
  SAVE_TALENT_PROFILE,
  SAVE_TALENT_PROFILE_SUCCESS,
  SAVE_TALENT_PROFILE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isFormProcessing: false,
  isPassChangeProcessing: false,
  content: {},
  mem: {},
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_TALENT_PROFILE:
      return {
        ...state,
        isLoading: true,
        content: {},
        mem: {}
      };
    case FETCH_TALENT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        mem: payload.mem
      };
    case FETCH_TALENT_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        mem: {},
        error: payload
      };
    case SAVE_TALENT_PROFILE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_TALENT_PROFILE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case SAVE_TALENT_PROFILE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };

    default:
      return state;
  }
}
