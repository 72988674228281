import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { createAccount } from "../../../states/actions/fetchSignup";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";

const Company = ({ content, industries, primary_interests }) => {
  const [defaultFormValues, setDefaultFormValues] = useState(
    JSON.parse(localStorage.getItem("secondFormStep"))
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSignup.isFormProcessing
  );
  const dispatch = useDispatch();
  const team_size = [
    {
      value: 1,
      label: "1-10"
    },
    {
      value: 2,
      label: "10-15"
    },
    {
      value: 3,
      label: "15-25"
    },
    {
      value: 4,
      label: "25-50"
    },
    {
      value: 5,
      label: "50+"
    }
  ];

  // const industries = [
  //   {
  //     id: 1,
  //     name: "Housing"
  //   },
  //   {
  //     id: 2,
  //     name: "Commercial"
  //   },
  //   {
  //     id: 3,
  //     name: "Apartments"
  //   }
  // ];

  // const interests = [
  //   {
  //     id: 1,
  //     name: "Interest 1"
  //   },
  //   {
  //     id: 2,
  //     name: "Interest 2"
  //   },
  //   {
  //     id: 3,
  //     name: "Interest 3"
  //   }
  // ];

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit, setValue, control
  } = useForm();

  const secondSubmit = (data, e) => {
    e.preventDefault();
    localStorage.setItem("secondFormStep", JSON.stringify(data));
    const firstForm = JSON.parse(localStorage.getItem("firstFormStep"));
    let formData = { ...firstForm, ...data, type: "web" };
    dispatch(createAccount(formData));
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
      >
        <fieldset>
          <div className="step_from">Step 2 of 2</div>
          <div className="option_lbl">
            <ul>
              <li className="active">Personal Info</li>
              <li className="active">Company</li>
            </ul>
          </div>
          <h4>{content.left_sec_heading_2}</h4>
          <p>{content.left_sec_text_2}</p>
          <form onSubmit={handleSubmit(secondSubmit)} method="POST">
            <div className="blk_form">
              <h6>Team size</h6>
              <div class="form_blk">
                {/* <select
                  defaultValue={defaultFormValues?.team_size}
                  className="input"
                  {...register("team_size", {
                    required: "Team size is required."
                  })}
                >
                  <option value="">Select</option>
                  {team_size &&
                    team_size.map((size, i) => (
                      <option value={size.range}>{size.range}</option>
                    ))}
                </select>
                <span className="validation-error">
                  {errors.team_size?.message}
                </span> */}
                <Controller
                  {...register("team_size", { required: "Required" })}
                  name="team_size"
                  id="team_size"
                  control={control}
                  defaultValue={defaultFormValues?.team_size}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={team_size}
                        onChange={val => {
                          onChange(val.value);
                          setValue("state_label", val.label);
                        }}
                        label="Select State"
                        defaultValue={team_size.filter(function (option) {
                          return option.value === value;
                        })}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="team_size"
                  render={({ message }) => <p className='validation_error'> {message}</p>}
                />
              </div>
            </div>
            <div className="blk_form">
              <h6>Industry</h6>
              <div class="form_blk">
                {/* <select
                  defaultValue={defaultFormValues?.industry}
                  className="input"
                  {...register("industry", {
                    required: "Industry is required."
                  })}
                >
                  <option value="">Select</option>
                  {industries &&
                    industries.map((industry, i) => (
                      <option value={industry.name}>{industry.name}</option>
                    ))}
                </select> */}
                <Controller
                  {...register("industry", { required: "Required" })}
                  name="industry"
                  id="industry"
                  control={control}
                  defaultValue={defaultFormValues?.industry}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={industries}
                        onChange={val => {
                          onChange(val.value);
                          setValue("state_label", val.label);
                        }}
                        label="Select State"
                        defaultValue={industries.filter(function (option) {
                          return option.value === value;
                        })}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="industry"
                  render={({ message }) => <p className='validation_error'> {message}</p>}
                />

              </div>
            </div>
            <div className="blk_form">
              <h6>Primary interest</h6>
              <div class="form_blk">
                {/* <select
                  defaultValue={defaultFormValues?.primary_interest}
                  className="input"
                  {...register("primary_interest", {
                    required: "Primary interest is required."
                  })}
                >
                  <option value="">Select</option>
                  {interests &&
                    interests.map((interest, i) => (
                      <option value={interest.name}>{interest.name}</option>
                    ))}
                </select>
                <span className="validation-error">
                  {errors.primary_interest?.message}
                </span> */}
                <Controller
                  {...register("primary_interest", { required: "Required" })}
                  name="primary_interest"
                  id="primary_interest"
                  control={control}
                  defaultValue={defaultFormValues?.primary_interest}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={primary_interests}
                        onChange={val => {
                          onChange(val.value);
                          setValue("state_label", val.label);
                        }}
                        label="Select State"
                        defaultValue={primary_interests.filter(function (option) {
                          return option.value === value;
                        })}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="primary_interest"
                  render={({ message }) => <p className='validation_error'> {message}</p>}
                />
              </div>
            </div>
            <div className="btn_blk">
              <SubmitButton
                classes="site_btn"
                isFormProcessing={isFormProcessing}
                button_text={content.left_sec_button_text_2}
              />

              <button className="site_btn blank" type="button">
                <img src="/images/google.svg" alt="" /> Sign up with Google
              </button>
            </div>
          </form>
          <div className="dont_acc">
            <p>
              {content.signin_link_left_text}{" "}
              <Link to="/login">{content.signin_link_text}</Link>
            </p>
          </div>
        </fieldset>
      </motion.div>
    </>
  );
};

export default Company;
