import React, { useEffect } from "react";
import Intro from "./Intro";
import PricingCard from "./Pricing-card";

import { fetchCheckout } from "../../../states/actions/fetchCheckout";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import { format_amount } from '../../../helpers/helpers';
import MetaGenerator from "../../common/meta-generator";

const Checkout = () => {
  const { plan_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchCheckout.content);
  const isLoading = useSelector((state) => state.fetchCheckout.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  // console.log(data?.plan)
  const { content, plan, terms_conditions, subscription_agreement, meta } = data;
  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const subscription = site_settings?.member?.mem_subscription;
  useEffect(() => {
    dispatch(fetchCheckout({ plan_id }));
  }, []);

  // useDocumentTitle(data.page_title);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  // console.log(data)
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Elements stripe={stripePromise}>
            <ToastContainer />
            <section className="logon">
              <div className="contain">
                <div className="flex">
                  <Intro subscription_plan_id={parseInt(subscription?.plan_id)} subscription={subscription} terms_conditions={terms_conditions} subscription_agreement={subscription_agreement} />
                  <div className="colR self_start pricing_check_out flex price_flex new_price_flex">
                    {/* {
                      subscription?.id > 0 ?

                        <div className="pkg_upgrade_blk pro_upgrade_blk checkout_current_plan_blk">
                          <h5>Active Plan</h5>
                          <div className="pkg_badge"><img src="/images/green-star.svg" alt="" /><span>{subscription?.plan_name}</span></div>
                          <div className="cntnt">
                            <p>{format_amount(subscription?.price)} / {subscription?.plan_type === 'monthly' ? 'mo' : subscription?.plan_type === 'yearly' ? 'yr' : ""}</p>
                            <div className="price_body">
                              <ul>
                                <li>{parseInt(subscription?.clicks_count)} <span>clicks</span></li>
                                <li>{parseInt(subscription?.downloads_count)} <span>downloads</span></li>
                                <li>{parseInt(subscription?.user_count)} <span>User</span></li>
                              </ul>
                            </div>

                            {subscription?.subscription_status === 0 ? <div className="alert alert-danger"><strong>Cancelled</strong> <p><small>{subscription?.subscription_status === 1 ? 'Reset' : subscription?.subscription_status === 0 ? 'Expired' : 'Reset'} on {subscription?.expiry_date}</small></p></div>
                              :
                              ""
                            }
                          </div>



                        </div>
                        :
                        ""
                    } */}
                    <PricingCard plan={plan} />
                  </div>
                </div>
              </div>
              <div className="lg_image">
                <ImageControl src={content.image1} folder="images" />
              </div>
            </section>
          </Elements>
        </>
      )}
    </>
  );
};

export default Checkout;
