import React from "react";
import { Link } from "react-router-dom";
function HowItWorks({ content }) {
    return (
        <>
            <section className="how_work_section">
                <div className="contain">
                    <div className="map_work">
                        <div className="inner_work">
                            <div className="inner">
                                <h3>{content?.sectionindustry_block_heading_1}</h3>
                                <p>{content?.sectionindustry_block_text_1}</p>
                            </div>
                        </div>
                        <div className="inner_work">
                            <div className="inner">
                                <h3>{content?.sectionindustry_block_heading_2}</h3>
                                <p>{content?.sectionindustry_block_text_2}</p>
                            </div>
                        </div>
                        <div className="inner_work">
                            <div className="inner">
                                <h3>{content?.sectionindustry_block_heading_3}</h3>
                                <p>{content?.sectionindustry_block_text_3}</p>
                            </div>
                        </div>
                        <div className="inner_work">
                            <div className="inner">
                                <h3>{content?.sectionindustry_block_heading_4}</h3>
                                <p>{content?.sectionindustry_block_text_4}</p>
                            </div>
                        </div>
                        <div className="inner_work">
                            <div className="inner">
                                <h3>{content?.sectionindustry_block_heading_5}</h3>
                                <p>{content?.sectionindustry_block_text_5}</p>
                            </div>
                        </div>
                    </div>
                    <div className="btn_blk">
                        <Link to={content?.sectionindustry_link_link ? content?.sectionindustry_link_link : "/"} className="site_btn" >{content?.sectionindustry_link_text}
                            <img src="/images/right-angle.svg" alt="" /></Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowItWorks;
