import {
  FETCH_JOBS_CONTENT,
  FETCH_JOBS_CONTENT_SUCCESS,
  FETCH_JOBS_CONTENT_FAILED,
  FETCH_JOBS_SEARCH,
  FETCH_JOBS_SEARCH_SUCCESS,
  FETCH_JOBS_SEARCH_FAILED,
  SAVE_JOB,
  SAVE_JOB_SUCCESS,
  SAVE_JOB_FAILED,
  JOB_APPLIED,
  JOB_APPLIED_SUCCESS,
  JOB_APPLIED_FAILED,
  REPORT_AN_JOB,
  REPORT_AN_JOB_SUCCESS,
  REPORT_AN_JOB_FAILED,
  REPORT_AN_JOB_POPUP,
  REPORT_AN_JOB_POPUP_SUCCESS,
  REPORT_AN_JOB_POPUP_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isSearching: false,
  isJobSaving: false,
  content: {},
  jobs: [],
  error: false,
  isJobApplying: false,
  isJobReporting: false,
  showReportPopup: false,
  totalJobs: 0,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_JOBS_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
        // jobs: []
      };
    case FETCH_JOBS_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        // jobs: payload.jobs
      };
    case FETCH_JOBS_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        // jobs: [],
        error: payload,
      };
    case FETCH_JOBS_SEARCH:
      return {
        ...state,
        isSearching: true,
      };
    case FETCH_JOBS_SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        jobs: payload.jobs,
        totalJobs: payload.totalJobs,
      };
    case FETCH_JOBS_SEARCH_FAILED:
      return {
        ...state,
        isSearching: false,
        error: payload,
      };
    case SAVE_JOB:
      return {
        ...state,
        isJobSaving: true,
      };
    case SAVE_JOB_SUCCESS:
      const newJobs = state.jobs.map((job, index) => {
        if (job.id === payload.id) {
          job.saved = true;
        }
        return job;
      });
      return {
        ...state,
        isJobSaving: false,
        jobs: newJobs,
      };
    case SAVE_JOB_FAILED:
      return {
        ...state,
        isJobSaving: false,
        error: payload,
      };
    case JOB_APPLIED:
      return {
        ...state,
        isJobApplying: true,
      };
    case JOB_APPLIED_SUCCESS:
      const jobsAfterApplied = state.jobs.map((job, index) => {
        if (job.id === payload.id) {
          job.applied = true;
        }
        return job;
      });
      return {
        ...state,
        isJobApplying: false,
        jobs: jobsAfterApplied,
      };
    case JOB_APPLIED_FAILED:
      return {
        ...state,
        isJobApplying: false,
        error: payload,
      };
    case REPORT_AN_JOB_POPUP:
      return {
        ...state,
        showReportPopup: !state.showReportPopup,
      };
    case REPORT_AN_JOB:
      return {
        ...state,
        isJobReporting: true,
      };
    case REPORT_AN_JOB_SUCCESS:
      return {
        ...state,
        isJobReporting: false,
        showReportPopup: !state.showReportPopup,
      };
    case REPORT_AN_JOB_FAILED:
      return {
        ...state,
        isJobReporting: false,
        error: payload,
      };
    default:
      return state;
  }
}
