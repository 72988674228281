import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const TableData = ({
  data,
  hideSearch,
  ownerData,
  pageNo,
  ownerEmail,
  setPageNo
}) => {
  const initialPage = pageNo - 1;
  const navigate = useNavigate();
  const { propertiesCount } = ownerData;
  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    navigate(`/owner-property/${ownerEmail}?page=${event.selected + 1}`);
  };

  useEffect(() => {
    setPageCount(Math.ceil(propertiesCount / 10));
  }, [propertiesCount]);

  return (
    <>
      <section
        className={
          hideSearch ? "table_view_sec filter_show_after" : "table_view_sec"
        }
      >
        <div className="contain-fluid">
          <div className="inner_tbl">
            <table>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Last Sale</th>
                  <th>Sales Price</th>
                  <th>Year Built</th>
                  <th>Building Area</th>
                  <th>Units</th>
                  <th>Property Type</th>
                  <th>Contact Info</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((val, i) => {
                  return (
                    <tr key={i+5612345+12789100000000}>
                      <td>{val.address}</td>
                      <td>{val.last_sale}</td>
                      <td>${Number(val.sale_price).toLocaleString()}</td>
                      <td>{val.year_built}</td>
                      <td>{Number(val.area).toLocaleString()} sqft</td>
                      <td>{val.units}</td>
                      <td>{val.type}</td>
                      <td>
                        <ul className="social">
                          <li>
                            <a href={`tel:${val.call}`}>
                              <img src="/images/call-icon.svg" alt="" />
                            </a>
                          </li>
                          <li>
                            <a href={`mailto:${val.email}`}>
                              <img src="/images/email-icon.svg" alt="" />
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination_blk">
              <div className="colL">
                <span>
                  {propertiesCount == 0
                    ? "0 property."
                    : "Showing " +
                      ((pageNo - 1) * 10 + 1) +
                      " to " +
                      ((pageNo - 1) * 10 + data.length) +
                      " of " +
                      propertiesCount +
                      (propertiesCount > 1 ? " properties" : " property")}
                </span>
              </div>
              <div className="colR">
                <div className="pagination_slider">
                  <ReactPaginate
                    forcePage={initialPage}
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TableData;
