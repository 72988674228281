import React from "react";
import Text from "../../common/Text";

const Detail = ({ content }) => {
  return (
    <>
      <section className="detail_sec">
        <div className="contain">
          <Text string={content?.policy_text} />
        </div>
      </section>
    </>
  );
};

export default Detail;
