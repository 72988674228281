import React, { useEffect } from "react";
import Banner from "./Banner";
import Searches from "./Searches";
import { ToastContainer, toast } from "react-toastify";

import { fetchSavedSearches } from "../../../states/actions/fetchSavedSearches";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { Link } from "react-router-dom";
import http from "../../../helpers/http";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { FETCH_WISHLIST_CONTENT_SUCCESS } from "../../../states/actions/actionTypes";
import Text from "../../common/Text";
import MetaGenerator from "../../common/meta-generator";

const SavedSearches = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSavedSearches.content);
  const isLoading = useSelector((state) => state.fetchSavedSearches.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, searches, lastestSearchTime, meta } = data;

  useEffect(() => {
    dispatch(fetchSavedSearches());
  }, []);
  // useDocumentTitle(data.page_title);
  const handleChangeSort = (frmData) => {
    dispatch(fetchSavedSearches(frmData))
  }
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <ToastContainer />
          <Banner content={content} />
          <Searches searches={searches} lastestSearchTime={lastestSearchTime} handleChangeSort={handleChangeSort} />

        </>
      )}
    </>
  );
};

export default SavedSearches;
