import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../common/Text";
import {
  acceptInvitation,
  handleActionState,
  rejectInvitation
} from "../../../states/actions/fetchAccountInvitation";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";
import ShowPasswordIcon from "../../common/ShowPasswordIcon";

const Form = ({ content }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const isFormProcessing = useSelector(
    (state) => state.fetchAccountInvitation.isFormProcessing
  );
  const action = useSelector((state) => state.fetchAccountInvitation.action);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    data = { ...data, invitationToken: token };
    dispatch(acceptInvitation(data));
  };

  const handleAcceptClick = () => {
    dispatch(handleActionState(1));
  };

  const handleRejectInvitation = () => {
    dispatch(rejectInvitation({ invitationToken: token }));
  };

  return (
    <>
      {action == 0 && (
        <React.Fragment>
          <p>Please select an option</p>
          <SubmitButton
            button_text="Accept"
            type="button"
            onClick={handleAcceptClick}
            disabled={isFormProcessing}
          />
          <SubmitButton
            button_text="Reject"
            classes="site_btn blank red_blank"
            type="button"
            onClick={handleRejectInvitation}
            isFormProcessing={isFormProcessing}
            disabled={isFormProcessing}
          />
        </React.Fragment>
      )}
      {action == 2 && (
        <Text string="Invitation accepted successfully, you have successfully created your password and your account is activated." />
      )}
      {action == 3 && <Text string="The invitation has been cancelled." />}
      {action == 1 && (
        <React.Fragment>
          <p>Please create a password to login into the account.</p>
          <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
            <div className="blk_form">
              <h6>Password</h6>
              <div className="form_blk pass_blk">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input"
                  placeholder="Enter password"
                  {...register("password", {
                    required: "Password is required.",
                    minLength: {
                      value: 6,
                      message: "Password should be atleast 6 characters long."
                    }
                  })}
                />
                <span className="validation-error">
                  {errors.password?.message}
                </span>
                <ShowPasswordIcon
                  showPassword={showPassword}
                  handleShowPassword={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            <div className="blk_form">
              <h6>Confirm Password</h6>
              <div className="form_blk pass_blk">
                <input
                  type={showPassword2 ? "text" : "password"}
                  className="input"
                  placeholder="Enter password again"
                  {...register("confirm_password", {
                    required: "Confirm Password is required.",
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match.";
                      }
                    },
                    minLength: {
                      value: 6,
                      message: "Password should be atleast 6 characters long."
                    }
                  })}
                />
                <ShowPasswordIcon
                  showPassword={showPassword2}
                  handleShowPassword={() => setShowPassword2(!showPassword2)}
                />
                <span className="validation-error">
                  {errors.confirm_password?.message}
                </span>
              </div>
            </div>
            <div className="btn_blk">
              <SubmitButton
                classes="site_btn"
                button_text={content.left_sec_button_text}
                isFormProcessing={isFormProcessing}
              />
            </div>
          </form>
        </React.Fragment>
      )}
    </>
  );
};

export default Form;
