import {
  FETCH_DATA_UPLOAD_CONTENT,
  FETCH_DATA_UPLOAD_CONTENT_SUCCESS,
  FETCH_DATA_UPLOAD_CONTENT_FAILED,
  UPLOAD_DATA,
  UPLOAD_DATA_SUCCESS,
  UPLOAD_DATA_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isProcessing: false,
  records: {},
  content: {},
  action: 0,
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DATA_UPLOAD_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_DATA_UPLOAD_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        records: payload.upload_records
      };
    case FETCH_DATA_UPLOAD_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case UPLOAD_DATA:
      return {
        ...state,
        isProcessing: true,
        action: 0
      };
    case UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        records: payload.upload_records,
        action: 1
      };
    case UPLOAD_DATA_FAILED:
      return {
        ...state,
        isProcessing: false
      };
    default:
      return state;
  }
}
