import React, { useEffect } from "react";
import Card from "./Card";
import Intro from "./Intro";

import { fetchForgotPassword } from "../../../states/actions/fetchForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import { ToastContainer } from "react-toastify";
import MetaGenerator from "../../common/meta-generator";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchForgotPassword.content);
  const isLoading = useSelector((state) => state.fetchForgotPassword.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, meta } = data;
  console.log(data);

  useEffect(() => {
    dispatch(fetchForgotPassword());
  }, []);

  // useDocumentTitle(data.page_title);

  const log = {
    card: {
      image: "/images/property.png",
      popLbl: "POPULAR",
      infoLbl: "Also owns",
      infoLnkLbl: "3 other properties",
      infoLnk: "/",
      phone: "+1234567890",
      email: "john@doe.com",
      position: "Owner",
      name: "John Doe",
      btnLbl: "Contact Now",
      btnLnk: "/"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <ToastContainer />
          <section className="logon">
            <div className="contain">
              <div className="flex">
                <Intro content={content} />
                <div className="colR">
                  <Card data={log.card} />
                </div>
              </div>
            </div>
            <div className="lg_image">
              <ImageControl src={content.image1} folder="images" />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
