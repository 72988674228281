import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_ACCOUNT_INVITATION_CONTENT,
  FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS,
  FETCH_ACCOUNT_INVITATION_CONTENT_FAILED,
  HANDLE_REJECT_ACCOUNT_INVITATION,
  HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS,
  HANDLE_REJECT_ACCOUNT_INVITATION_FAILED,
  TOGGLE_INVITATION_STATE,
  HANDLE_ACCEPT_ACCOUNT_INVITATION,
  HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS,
  HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED
} from "./actionTypes";

export const fetchAccountInvitation = () => (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_INVITATION_CONTENT,
    payload: null
  });
  http
    .get("account-invitation-page")
    .then(({ data }) => {
      dispatch({
        type: FETCH_ACCOUNT_INVITATION_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ACCOUNT_INVITATION_CONTENT_FAILED,
        payload: error
      });
    });
};

export const acceptInvitation = (formData) => (dispatch) => {
  dispatch({
    type: HANDLE_ACCEPT_ACCOUNT_INVITATION,
    payload: null
  });
  http
    .post("accept-account-invitation", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "Invitation accepted successfully, you have successfully created your password and your account is activated.",
          TOAST_SETTINGS
        );
        dispatch({
          type: HANDLE_ACCEPT_ACCOUNT_INVITATION_SUCCESS,
          payload: data
        });
        dispatch({
          type: TOGGLE_INVITATION_STATE,
          payload: 2
        });
      } else {
        if (!data.status) {
          if (data.msg) {
            toast.error(
              <Text string={data.msg} parse={true} />,
              TOAST_SETTINGS
            );
          } else if (data.notExist) {
            toast.error(
              "Unknown or expired link. Please contact to admin.",
              TOAST_SETTINGS
            );
          }
          dispatch({
            type: HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: HANDLE_ACCEPT_ACCOUNT_INVITATION_FAILED,
        payload: error
      });
    });
};

export const rejectInvitation = (formData) => (dispatch) => {
  dispatch({
    type: HANDLE_REJECT_ACCOUNT_INVITATION,
    payload: null
  });
  http
    .post("reject-account-invitation", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("The invitaton have been rejected.", TOAST_SETTINGS);
        dispatch({
          type: HANDLE_REJECT_ACCOUNT_INVITATION_SUCCESS,
          payload: data
        });
        dispatch({
          type: TOGGLE_INVITATION_STATE,
          payload: 3
        });
      } else {
        if (!data.status) {
          if (data.msg) {
            toast.error(
              <Text string={data.msg} parse={true} />,
              TOAST_SETTINGS
            );
          } else if (data.notExist) {
            toast.error(
              "Unknown or expired link. Please contact to admin.",
              TOAST_SETTINGS
            );
          }
          dispatch({
            type: HANDLE_REJECT_ACCOUNT_INVITATION_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: HANDLE_REJECT_ACCOUNT_INVITATION_FAILED,
        payload: error
      });
    });
};

export const handleActionState = (action) => (dispatch) => {
  dispatch({
    type: TOGGLE_INVITATION_STATE,
    payload: action
  });
};
