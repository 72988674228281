import React from "react";
import Text from "../../common/Text";
import { useForm } from "react-hook-form";

const Search = ({ content, handleSearch }) => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    handleSearch(data);
  };
  return (
    <>
      <section className="search_banner">
        <div className="contain">
          <h3>
            <Text string={content.banner_heading} />
          </h3>
          <form onSubmit={handleSubmit(firstSubmit)}>
            <div className="form_blk">
              <input
                type="text"
                placeholder={content.banner_search_heading}
                className="input"
                {...register("search_keyword")}
              />
              <img src="/images/search.svg" alt="" />
              <span className="validation-error">
                {errors.search_keyword?.message}
              </span>
            </div>
          </form>
          <div className="dim_img">
            <img src="/images/rahino.svg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;
