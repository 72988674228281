import React, { useState } from "react";
import SubmitButton from "../../common/SubmitButton";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../helpers/http";
import * as helpers from "../../../helpers/helpers";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Text from "../../common/Text";

export default function SaveSearchPopup({ setSaveSearchPopup }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  //   const isFormProcessing = useSelector(
  //     (state) => state.fetchProfileSettings.isFormProcessing
  //   );
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    let formData = {};

    // Iterate over the entries of urlParams and populate the params object
    for (const [key, value] of urlParams.entries()) {
      formData[key] = value;
    }
    formData = { ...formData, keyword: data?.keyword };
    setIsLoading(true);
    http
      .post("save-search", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        setIsLoading(false);
        if (data?.status) {
          toast.success(
            <Text string={data?.msg} parse={true} />,
            TOAST_SETTINGS
          );
          reset();
          setSaveSearchPopup(false);
        } else {
          toast.error(<Text string={data?.msg} parse={true} />, TOAST_SETTINGS);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(<Text string={error} parse={true} />, TOAST_SETTINGS);
      });
    // dispatch(changePasswordAction(data));
  };
  return (
    <>
      <ToastContainer />
      <div class="popup sm popup_password_change active">
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={() => setSaveSearchPopup(false)}
                ></button>
                <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
                  <div className="from_row row">
                    <div className="col-sm-12">
                      <div className="blk_form">
                        <h6>Search Title</h6>
                        <div className="form_blk">
                          <input
                            type="text"
                            className="input"
                            {...register("keyword", {
                              required: "Title is required.",
                            })}
                          />
                          <span className="validation-error">
                            {errors.keyword?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_blk text-center">
                    <SubmitButton
                      classes="site_btn"
                      isFormProcessing={isLoading}
                      button_text="Save Search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
