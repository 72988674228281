import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BiCircle } from 'react-icons/bi';
import { PiPolygon } from 'react-icons/pi';
import Select, { components as SelectComponents } from 'react-select';
import wellknown from 'wellknown';
import { arraysEqual } from '../../../../helpers/helpers';
import CircleMode from './Circle.DrawControl';
import { DrawControlWrapper, customSelectStyles } from './DrawControl.style';

const ALL_MODES = [
  {
    value: 'polygon',
    label: 'Polygon',
    mode: 'draw_polygon',
    icon: <PiPolygon size={18} />
  },
  {
    value: 'circle',
    label: 'Circle',
    mode: 'draw_circle',
    icon: <BiCircle size={18} />
  },
  {
    value: 'delete',
    label: 'Delete',
    mode: 'draw_delete',
    icon: <AiFillDelete size={18} />
  }
];

function DrawControl({
  map,
  controls = [],
  setDrawnFeatures,
  drawnFeatures,
  setIsDrawToolsActive
}) {
  const [selectedControl, selectControl] = useState(null);
  const [availableControls, setAvailableControls] = useState([]);
  // const [circleRadius, setCircleRadius] = useState(null);

  const drawRef = useRef(null);

  const deleteDrawnFeatures = useCallback(
    (shouldSetState = false) => {
      if (shouldSetState) {
        setDrawnFeatures(null);
      }

      if (drawRef.current) {
        const featureCollection = drawRef.current.getAll();

        if (featureCollection?.features?.length) {
          drawRef.current.deleteAll();
        }
      }
    },
    [setDrawnFeatures]
  );

  const updateGeometry = useCallback(
    (event) => {
      if (drawRef.current) {
        deleteDrawnFeatures(false);

        const feature = event.features[0];
        let wktGeom = wellknown.stringify(feature.geometry);

        if (drawnFeatures !== null & drawnFeatures !== 'null' && drawnFeatures !== wktGeom) {
          const existingFeatures = wellknown.parse(drawnFeatures);

          let coordinates = [];
          if (existingFeatures.type === 'MultiPolygon') {
            coordinates = [...existingFeatures.coordinates];
          } else {
            coordinates = [existingFeatures.coordinates];
          }

          const newPolygon = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'MultiPolygon',
              coordinates: coordinates.concat([feature.geometry.coordinates])
            }
          };

          wktGeom = wellknown.stringify(newPolygon)
        }

        drawRef.current.add(feature);

        setDrawnFeatures(wktGeom);
        // localStorage.setItem('drawnFeatures', wktGeom);
        selectControl(null);
      }
    },
    [deleteDrawnFeatures, setDrawnFeatures]
  );

  const onSelectControl = (option) => {
    deleteDrawnFeatures(true);
    // setCircleRadius(null);

    if (option?.value) {
      setIsDrawToolsActive(true);
      if (option.mode === 'draw_delete') {
        selectControl(null);
      } else {
        selectControl(option.value);

        if (drawRef.current) {
          drawRef.current.changeMode(option.mode);
        }
      }
    } else {
      setIsDrawToolsActive(false);
      selectControl(null);
      if (drawRef.current) {
        drawRef.current.changeMode(null);
      }
    }
  };

  useEffect(() => {
    if (!map || !map) return;

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode
      }
    });

    map.addControl(drawRef.current);

    map.on('draw.create', updateGeometry);
    map.on('draw.update', updateGeometry);
  }, [map, updateGeometry]);

  useEffect(() => {
    let newControls = [...controls];

    if (newControls?.length) {
      newControls.push('delete');
    }

    if (!arraysEqual(newControls, availableControls)) {
      setAvailableControls(newControls);
    }
  }, [availableControls, controls]);

  const IconOption = (props) => {
    return (
      <SelectComponents.Option {...props}>
        <span>{props.data.label}</span>
        {props.data.icon}
      </SelectComponents.Option>
    );
  };

  const MultiValueOption = (props) => (
    <SelectComponents.MultiValue {...props}>
      <img src={props.data.icon} style={{ width: 18 }} alt={props.data.label} />
      <span>{props.data.label}</span>
    </SelectComponents.MultiValue>
  );

  const availableControlOptions = ALL_MODES.filter((m) => {
    return availableControls.includes(m.value);
  });

  const selectedOption =
    availableControlOptions.find((m) => m.value === selectedControl) || null;

  return (
    <DrawControlWrapper>
      <Select
        placeholder='Draw'
        isClearable
        value={selectedOption}
        onChange={onSelectControl}
        options={availableControlOptions}
        components={{
          Option: IconOption,
          MultiValue: MultiValueOption
        }}
        styles={customSelectStyles}
      />

      {/* {selectedControl === 'circle' || circleRadius?.value ? (
        <div className='circle-radius-input'>
          <input
            type='text'
            disabled
            value={circleRadius?.value || '0'}
            id='circle-radius-input'
          />
          <span>{circleRadius?.key || 'mi'}</span>
        </div>
      ) : null} */}
    </DrawControlWrapper>
  );
}

export default DrawControl;
