import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";

import {
  FETCH_SUPPORT_CONTENT,
  FETCH_SUPPORT_CONTENT_SUCCESS,
  FETCH_SUPPORT_CONTENT_FAILED
} from "./actionTypes";  

export const fetchSupport = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_SUPPORT_CONTENT,
    payload: null
  });
  http
    .post("support-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_SUPPORT_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SUPPORT_CONTENT_FAILED,
        payload: error
      });
    }); 
};
