import React from "react";
import ImageControl from "../../common/ImageControl";
function LeadingIndustries({ content, industries }) {
    return (
        <>
            <section className="leading_industries">
                <div className="contain">
                    <div className="sec_heading text-center">
                        <h2>{content?.section_industries_heading_1}</h2>
                    </div>
                    <div className="flex">
                        {
                            industries?.map((industry, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <div className="inner">
                                            <div className="icon_img">
                                                <ImageControl folder="industries" src={industry?.icon} />
                                            </div>
                                            <div className="cntnt">
                                                <h5>{industry?.title}</h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                    <div className="after_txt text-center">
                        <p>{content?.section_industries_text}</p>
                    </div>
                </div>
                <div className="ribbon_lst">
                    <div className="riben_badge">
                        <span>{content?.section_industries_list_1}</span>
                    </div>
                    <div className="riben_badge">
                        <span>{content?.section_industries_list_2}</span>
                    </div>
                    <div className="riben_badge">
                        <span>{content?.section_industries_list_3}</span>
                    </div>
                    <div className="riben_badge">
                        <span>{content?.section_industries_list_4}</span>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LeadingIndustries;
