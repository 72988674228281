import React, { useState, useEffect,useRef  } from "react";
import { useDispatch } from "react-redux";
import http from "../../helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const OwnerPropertiesListPopup = ({ propertyId, closePopup }) => {
  const [propertyData, setPropertyData] = useState(null);
  const popupRef = useRef(null);
  


  useEffect(() => {
    fetchPropertyData();
  }, []);

  const fetchPropertyData = async () => {
    
    http.post("getownerproperties", { email: propertyId })
      .then(({ data }) => {
        console.log(data);
        const property = data[0];
        const list = property.properties_list;
        console.log(list);
        setPropertyData(list);
        
        
        
      })
      .catch((error) => {
        console.error(error);
      });
      //const response = await http.post("/getownerproperties", { email: propertyId });
       
   
  };
  const popupContainerStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
  };
  const handleClosePopup = () => {
    closePopup();
  };
  return (
    <div style={popupContainerStyle} className="popup-container" ref={popupRef}>
      <div className="popup-content">
        {propertyData ? (
          <>
            <h3>Property Information</h3>
            {propertyData?.map((property, i) => {
                
                console.log(property.title, "data");
                return (<><div key={i+12789100000000876543} className="property-details"><p className="property-name"><Link to={`/property-details/${property.id}`}>Property Name: {property.title}</Link></p></div></>);
            })}

            
          </>
        ) : (
          <p>Loading property data...</p>
        )}
         <button className="close-button" onClick={handleClosePopup}>
          Close
        </button>
      </div>
      <div className="popup-overlay" style={overlayStyle} onClick={handleClosePopup}></div>
    </div>
  );
};

export default OwnerPropertiesListPopup;
