import React, { useEffect, useState } from "react";
import FeedbackForm from "./form";
import FeedbackInfo from "./info";

import {
  fetchFeedback,
  postFeedback,
} from "../../../states/actions/fetchFeedback";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import MetaGenerator from "../../common/meta-generator";
export default function Feedback() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchFeedback.content);
  const isLoading = useSelector((state) => state.fetchFeedback.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const content = data?.content?.content;
  const intro = data?.content?.intro;

  useEffect(() => {
    dispatch(fetchFeedback());
  }, []);
  const handleSaveFeedback = (frmData) => {
    dispatch(postFeedback(frmData));
  };
  // useDocumentTitle(data?.page_title);
  return (
    <>
      <ToastContainer />
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <main>
          <MetaGenerator page_title={data?.page_title} meta_desc={data?.meta} />
          <section className="contact_pg">
            <div className="contain">
              <div className="sec_heading text-center">
                <h1>{content?.main_heading}</h1>
              </div>
              <div className="cmn_blk">
                <div className="contact_flex flex">
                  <FeedbackInfo content={content} intro={intro} />
                  <FeedbackForm
                    content={content}
                    handleSaveFeedback={handleSaveFeedback}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
