import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

function Industrial({ content }) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
      >
        <div className="flex">
          <div className="colL">
            <div className="image">
              <ImageControl folder="images" src={content?.image2} />
            </div>
            <div className="play_lbl flex">
              <div className="play_ic">
                <ImageControl src={content?.image16} folder="images" />
              </div>
              <div className="cntnt">
                <h6>
                  <Text string={content?.section2_left_upper_card_heading} />
                </h6>
                <p>
                  <Text string={content?.section2_left_upper_card_text} />
                </p>
              </div>
            </div>
            <div className="be_more">
              <div className="inner">
                <h6>
                  <Text
                    string={content?.section2_left_bottom_mid_card_heading}
                  />
                </h6>
                <p>
                  <Text string={content?.section2_left_bottom_mid_card_text} />
                </p>
              </div>
              <div className="home_ico">
                <ImageControl src={content?.image17} folder="images" />
              </div>
            </div>
          </div>
          <div className="colR">
            <h2>
              {content?.section2_industrial_section_heading}
            </h2>
            <p>{content?.section2_industrial_section_text}</p>
            <div className="btn_blk">
              <Link
                to={content?.section2_industrial_section_button_link ? content?.section2_industrial_section_button_link : "/"}
                className="site_btn"
              >
                {content?.section2_industrial_section_button_text}
                <img src="/images/right-angle.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Industrial;
