import React, { useState } from "react";
import Company from "./Company";
import Personal from "./Personal";
import Authentication from "./Authentication";
import { useSelector } from "react-redux";

const Form = ({ data, content, industries, primary_interests }) => {
  const step = useSelector((state) => state.fetchSignup.step);
  const [page, setPage] = useState(0);

  const componentList = [
    <Personal content={content} />,
    <Company content={content} industries={industries} primary_interests={primary_interests} />,
    <Authentication data={data} />
  ];
  return <>{componentList[step]}</>;
};

export default Form;
