import React from "react";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
function Block({ content, key_index }) {
  return (
    <>
      <section className="mini_sec">
        <div className="contain">
            <div className="outer_bg">
              <div className="flex">
                <div className="colL">
                  <h3>
                    <Text string={content?.section3_heading} />
                  </h3>
                  <p>
                    <Text string={content?.section3_text} />
                  </p>
                  
                </div>
                <div className="colR">
                  <div className="image">
                    <ImageControl folder="images" src={content?.image7} />
                  </div>
                </div>
              </div>

              <div className="flex mini_icon_new">
                <div className="col_c" key={key_index+123100000}>
                  <div className="inner_in">
                    <div className="sm_icon">
                      <ImageControl folder="images" src={content?.image8} />
                    </div>
                    <div className="cn_tnt">
                      <h5>
                        <Text string={content?.section3_card_heading_8} />
                      </h5>
                      <p>
                        <Text string={content?.section3_card_text_8} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_c" key={key_index+11231000000}>
                  <div className="inner_in">
                    <div className="sm_icon">
                      <ImageControl folder="images" src={content?.image9} />
                    </div>
                    <div className="cn_tnt">
                      <h5>
                        <Text string={content?.section3_card_heading_9} />
                      </h5>
                      <p>
                        <Text string={content?.section3_card_text_9} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_c" key={key_index+112310000000}>
                  <div className="inner_in">
                    <div className="sm_icon">
                      <ImageControl folder="images" src={content?.image10} />
                    </div>
                    <div className="cn_tnt">
                      <h5>
                        <Text string={content?.section3_card_heading_10} />
                      </h5>
                      <p>
                        <Text string={content?.section3_card_text_10} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
}

export default Block;
