import styled from 'styled-components';

export const DrawControlWrapper = styled.div`
  position: absolute !important;
  top: 20px;
  right: 20px;
  font-size: 16.5px;
  font-weight: 600px;
  width: 140px;

  .circle-radius-input {
    background-color: #f7f9f2;
    padding: '1px 8px';
    width: inherit;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    margin-top: 5px;

    input {
      width: 100%;
      border: none;
      outline: none;
      background: none;
      border-radius: 8px 0 0 8px;
      text-align: center;
    }

    span {
      border: none;
      border-left: 1px solid #ccc;
      width: 50px;
      text-align: center;
    }
  }
`;

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#969b89' : '#969b89',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1px 8px',
    margin: '5px 0',
    backgroundColor: '#f7f9f2'
  }),

  placeholder: (provided) => ({
    ...provided,
    color: '#969b89',
    fontSize: '16.5px',
    fontWeight: 600
  }),

  control: (provided) => ({
    ...provided,
    borderRadius: '10px 10px 0px 0px',
    border: '1px solid #ccc',
    boxShadow: 'none',
    backgroundColor: '#fcfcfc'
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: ' 0px 0px 10px 10px',
    border: '1px solid #ccc',
    boxShadow: 'none',
    backgroundColor: '#f7f9f2',
    marginTop: '-1px'
  })
};
