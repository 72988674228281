import React, { useEffect } from "react";
import Card from "./Card";
import Intro from "./Intro";

import { fetchSignup } from "../../../states/actions/fetchSignup";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import { ToastContainer } from "react-toastify";
import MetaGenerator from "../../common/meta-generator";

const Signup = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSignup.content);
  const isLoading = useSelector((state) => state.fetchSignup.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, industries, primary_interests, meta } = data;

  useEffect(() => {
    // localStorage.removeItem("firstFormStep");
    // localStorage.removeItem("secondFormStep");
    // localStorage.removeItem("email");
    dispatch(fetchSignup());
  }, []);

  // useDocumentTitle(data.page_title);

  const log = {
    card: {
      image: "/images/property.png",
      popLbl: "POPULAR",
      infoLbl: "Also owns",
      infoLnkLbl: "3 other properties",
      infoLnk: "/",
      phone: "+1234567890",
      email: "john@doe.com",
      position: "Owner",
      name: "John Doe",
      btnLbl: "Contact Now",
      btnLnk: "/"
    },
    intro: {
      title1: "Welcome to the sales revolution!",
      detail1:
        "Rhinos plow through every opportunity with grit and determination. Let's make some sales!",
      title2: "Company info",
      detail2: "Please enter your company information below.",
      title3: "Authenticate your account",
      detail3:
        "We just sent you a code to validate your email address, check your inbox."
    },
    right_detail: {
      details:
        "<h3>Why Rhino Recon?</h3><p>Find more opportunities, connect with owners instantly, and analyze properties with ease.</p><ul><li>Our interactive map will help you find target properties.</li><li>Get the right data, including sales, tax, and debt details.</li><li>Find property owners and talk to the right person in seconds.</li></ul>"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <ToastContainer />
          <section className="logon">
            <div className="contain">
              <div className="flex">
                <Intro data={log.intro} content={content} industries={industries} primary_interests={primary_interests} />
                <div className="colR">
                  <Text string={content.right_sec_text} />
                  <div className="br"></div>
                  <Card data={log.card} />
                </div>
              </div>
            </div>
            <div className="lg_image">
              <ImageControl src={content.image1} folder="images" />
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Signup;
