import React from "react";
import Form from "./Form";

const Intro = ({ content }) => {
  return (
    <>
      <div className="colL">
        <div className="inner">
          <h4>{content.left_sec_heading}</h4>
          <p>{content.left_sec_text}</p>
          <Form content={content} />
        </div>
      </div>
    </>
  );
};

export default Intro;
