import React from "react";
const SideBar = (props) => {
  const { activePage } = props;

  return (
    <>
      <ul>
        <li>
          <button
            type="button"
            className={activePage == "Profile" ? "active" : ""}
            onClick={() => props.pageToggle("Profile")}
          >
            Profile
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "MyAccount" ? "active" : ""}
            onClick={() => props.pageToggle("MyAccount")}
          >
            My Account
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "Notifications" ? "active" : ""}
            onClick={() => props.pageToggle("Notifications")}
          >
            Notifications
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "MembershipManagement" ? "active" : ""}
            onClick={() => props.pageToggle("MembershipManagement")}
          >
            Membership Management
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "Exports" ? "active" : ""}
            onClick={() => props.pageToggle("Exports")}
          >
            Exports
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "Billing" ? "active" : ""}
            onClick={() => props.pageToggle("Billing")}
          >
            Billing
          </button>
        </li>
        <li>
          <button
            type="button"
            className={activePage == "Users" ? "active" : ""}
            onClick={() => props.pageToggle("Users")}
          >
            Users
          </button>
        </li>
      </ul>
    </>
  );
};

export default SideBar;
