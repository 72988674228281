import {
  FETCH_SITE_SETTINGS,
  FETCH_SITE_SETTINGS_SUCCESS,
  FETCH_SITE_SETTINGS_FAILED,
  UPDATE_USER_CREDITS
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  site_settings: null,
  memData: null,
  dashboard_articles: null,
  plan: null,
  error: false,
  total_available_credits:0
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SITE_SETTINGS:
      return {
        ...state,
        isLoading: true,
        site_settings: null,
        memData: null
      };
    case FETCH_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        site_settings: payload.site_settings,
        memData: payload.member,
        total_available_credits:payload?.member?.total_available_credits > 0 ? payload?.member?.total_available_credits : 0
      };
    case FETCH_SITE_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case UPDATE_USER_CREDITS:
      return {
        ...state,
        total_available_credits: payload
      };
    default:
      return state;
  }
}
