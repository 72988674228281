import {
  FETCH_EVENT_DETAIL_CONTENT,
  FETCH_EVENT_DETAIL_CONTENT_SUCCESS,
  FETCH_EVENT_DETAIL_CONTENT_FAILED,
  REGISTER_EVENT,
  REGISTER_EVENT_SUCCESS,
  REGISTER_EVENT_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  isRegistered: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_EVENT_DETAIL_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
        events: []
      };
    case FETCH_EVENT_DETAIL_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        events: payload.events,
        isRegistered: payload.isRegistered
      };
    case FETCH_EVENT_DETAIL_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        events: [],
        error: payload
      };
    case REGISTER_EVENT:
      return {
        ...state,
        isFormProcessing: true
      };
    case REGISTER_EVENT_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        isRegistered: true
      };
    case REGISTER_EVENT_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
