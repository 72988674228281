import React from "react";
import FeaturedPost from "./Featured-post";
import Posts from "./Posts";

const BlogPage = ({ content, featured_blog, all_articles }) => {
  return (
    <>
      <section className="blog">
        <div className="contain">
          <div className="feature_blog">
            <h2>{content.featured_heading}</h2>
            <FeaturedPost featured_blog={featured_blog} />
          </div>
          <div className="all_post">
            <h4>{content.articles_heading}</h4>
            <Posts all_articles={all_articles} />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;
