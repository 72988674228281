import React from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";

export default function FeedbackInfo({ content, intro }) {
    return <>
        <div className="colL">
            <Text string={content?.contact_text} />

        </div>
    </>;
}
