import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";

import {
  FETCH_HELP_CONTENT,
  FETCH_HELP_CONTENT_SUCCESS,
  FETCH_HELP_CONTENT_FAILED,
  SEARCH_HELP,
  SEARCH_HELP_SUCCESS,
  SEARCH_HELP_FAILED
} from "./actionTypes";

export const fetchHelp = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_HELP_CONTENT,
    payload: null
  });
  http
    .post("help-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_HELP_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_HELP_CONTENT_FAILED,
        payload: error
      });
    });
};

export const searchHelp = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: SEARCH_HELP,
    payload: null
  });
  http
    .post("search-help-data", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: SEARCH_HELP_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_HELP_FAILED,
        payload: error
      });
    });
};
