import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const OwnerFilter = (props) => {
  const { ToggleHideSearch, hideSearch, ownerEmail, ownerData } = props;
  const { detail, propertiesCount } = ownerData;

  const [contact, setContact] = useState(false);
  const ToggleContact = () => {
    setContact(!contact);
  };
  const currentOwnerEmail = ownerEmail;
  const location = useLocation();
  const location_path = location?.pathname;
  const current_page = location_path?.split("/")[1];
  return (
    <>
      <div className={hideSearch ? "result_filter less_top" : "result_filter"}>
        <div className="contain-fluid">
          <div className="filter_result_flex detail_filter_result_flex owner_filter_new">
            <div className="_col">
              <div className="filter_blk">
                <div className="text_search text_new_search">
                  <div className="main_filter_title">
                    {detail && (
                      <h4>
                        {detail.first_name} {detail.last_name}
                      </h4>
                    )}
                    {propertiesCount && (
                      <p>
                        & {propertiesCount}{" "}
                        {propertiesCount > 1 ? " properties" : "property"}{" "}
                        available
                      </p>
                    )}
                  </div>
                </div>
                <Link
                  to={`/owner-overview/${currentOwnerEmail}`}
                  className={
                    current_page == "owner-overview"
                      ? "site_btn"
                      : "site_btn blank yellow_blank"
                  }
                >
                  Overview
                </Link>
                <Link
                  to={`/owner-property/${currentOwnerEmail}?page=1`}
                  className={
                    current_page == "owner-property"
                      ? "site_btn"
                      : "site_btn blank yellow_blank"
                  }
                >
                  Properties
                </Link>
                <button
                  className="site_btn hide_detail_search_btn_new_new"
                  onClick={ToggleHideSearch}
                >
                  <span>View Search</span>
                  <img src="/images/eye_circle_hide.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="_col">
              <div className="filter_blk drop_result_btn">
                <button
                  class="filter_btn site_btn view_searches_btn"
                  onClick={ToggleContact}
                >
                  <img src="/images/contact_book.svg" alt="" />
                  <span>Contact</span>
                </button>
                <div
                  className={
                    contact
                      ? "drop_cnt medium_medium owner_filter_cnt_drop active"
                      : "drop_cnt owner_filter_cnt_drop medium_medium"
                  }
                >
                  <div className="contact_drop_inner">
                    <div className="flex_info">
                      <div className="dp">
                        <img src="/images/profile.svg" alt="" />
                      </div>
                      <div className="cntnt">
                        {detail && (
                          <h5>
                            {detail.first_name} {detail.last_name}
                          </h5>
                        )}
                        {propertiesCount && (
                          <p>Owns {propertiesCount} propertie(s)</p>
                        )}
                      </div>
                    </div>
                    <div className="flex_flex head_flex">
                      <div className="col">
                        <p>Phone numbers</p>
                      </div>
                      <div className="col">
                        <p>Emails and addresses</p>
                      </div>
                    </div>
                    <div className="flex_flex">
                      <div className="col">
                        <p className="hide_big_sc">Phone numbers</p>
                        <ul>
                          <li>
                            <div className="left_cnt">
                              <div className="social_icon">
                                <img src="/images/dark_call.svg" alt="" />
                              </div>
                              <div className="cn_tnt">
                                {detail && (
                                  <h5>
                                    {detail?.phone ? detail?.phone : "N/A"}
                                  </h5>
                                )}
                                <p>R L Marketing</p>
                              </div>
                            </div>
                            {detail?.phone && (
                              <div className="right_cnt">
                                <a
                                  href={`tel:${detail?.phone}`}
                                  className="mini_image"
                                >
                                  <img src="/images/mini_call.svg" alt="" />
                                </a>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col">
                        <p className="hide_big_sc">Emails and addresses</p>
                        <ul>
                          <li>
                            <div className="left_cnt">
                              <div className="social_icon">
                                <img src="/images/dark_email.svg" alt="" />
                              </div>
                              <div className="cn_tnt">
                                {detail && <h5>{detail.email}</h5>}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="left_cnt">
                              <div className="social_icon">
                                <img src="/images/dark_address.svg" alt="" />
                              </div>
                              <div className="cn_tnt">
                                <h5>N/A</h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerFilter;
