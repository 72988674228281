import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_PRICING_CONTENT,
  FETCH_PRICING_CONTENT_SUCCESS,
  FETCH_PRICING_CONTENT_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE,
  CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
  UPDATE_SUBSCRIPTION_MESSAGE,
  UPDATE_SUBSCRIPTION_MESSAGE_FAILED,
  UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS
} from "./actionTypes";

export const fetchPricing = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_PRICING_CONTENT,
    payload: null
  });
  http
    .post("pricing-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_PRICING_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PRICING_CONTENT_FAILED,
        payload: error
      });
    });
};
export const cancelSubscription = (formData, location) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    formData = { ...formData, token: localStorage.getItem("authToken") };
    dispatch({
      type: CANCEL_SUBSCRIPTION_MESSAGE,
      payload: null
    });
    http
      .post("cancel-member-subscription", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        console.log(data)
        if (data.status) {
          toast.success(data?.msg, TOAST_SETTINGS);
          dispatch({
            type: CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
            payload: data
          });
          setTimeout(() => {
            window.location.reload()
          }, 4000);
        } else {
          if (data?.msg) {
            toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
            dispatch({
              type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
              payload: null
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
          payload: error
        });
      });
  }
  else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
export const updateSubscription = (formData, location) => (dispatch) => {
  console.log(formData)
  if (localStorage.getItem("authToken")) {
    formData = { ...formData, token: localStorage.getItem("authToken") };
    dispatch({
      type: UPDATE_SUBSCRIPTION_MESSAGE,
      payload: null
    });
    http
      .post("update-member-subscription", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        console.log(data)
        if (data.status) {
          toast.success(data?.msg, TOAST_SETTINGS);
          dispatch({
            type: UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS,
            payload: data
          });
          setTimeout(() => {
            window.location.reload()
          }, 4000);
        } else {
          if (data?.msg) {
            toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
            dispatch({
              type: UPDATE_SUBSCRIPTION_MESSAGE_FAILED,
              payload: null
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SUBSCRIPTION_MESSAGE_FAILED,
          payload: error
        });
      });
  }
  else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};