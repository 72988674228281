import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import Nav from "./Nav";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NavCell from "./nav-cell";
function Header({ access, member }) {
  const location = useLocation();

  const [toggle, setToggle] = useState(false);
  const ToggleActive = () => {
    setToggle(!toggle);
  };

  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const industries = site_settings?.industries;
  // useEffect(() => {
  //   if (authToken) {
  //     if (member === undefined || member === null || member === '') {
  //       localStorage.removeItem("authToken");
  //       window.location.reload();
  //     }
  //   }
  // }, []);

  return (
    <>
      {access == "auth" ? (
        <header className="ease">
          <div className="contain-fluid">
            <Logo site_settings={site_settings} />
          </div>
        </header>
      ) : (
        <header className="ease">
          <div className="contain-fluid">
            <Logo site_settings={site_settings} />
            <div
              className={!toggle ? "toggle" : "toggle active"}
              onClick={() => ToggleActive(!toggle)}
            >
              <span></span>
            </div>
            {access == "public" &&
            !location?.pathname?.includes("/enterprise-checkout/") ? (
              <Navigation
                active={toggle}
                setToggle={setToggle}
                member={member}
                industries_arr={industries}
              />
            ) : (
              !location?.pathname?.includes("/enterprise-checkout/") && (
                <>
                  <Nav
                    active={toggle}
                    setToggle={setToggle}
                    member={member}
                    industries_arr={industries}
                  />
                  <NavCell
                    active={toggle}
                    setToggle={setToggle}
                    member={member}
                    industries_arr={industries}
                  />
                </>
              )
            )}
            {/* {location?.pathname === } */}
          </div>
        </header>
      )}
    </>
  );
}
export default Header;
