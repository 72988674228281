import React, { useState, useEffect, useCallback } from "react";
import FiltersSearch from "./Filters-search";
import DetailFilters from "./Detail-filter";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MobileAdvance from "./Mobile-advance";
import { useParams } from "react-router-dom";
import http from "../../../helpers/http";
import * as helpers from "../../../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Text from "../../../components/common/Text";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "../../common/LoadingScreen";
import Mapcomponent from "./Map";

import {
  FETCH_PROPERTY_DETAIL_CONTENT,
  FETCH_PROPERTY_DETAIL_FAILED,
  FETCH_WISHLIST_CONTENT_FAILED,
  FETCH_WISHLIST_CONTENT_SUCCESS
} from "../../../states/actions/actionTypes";
import PrtopertyDetailsNotes from "./property-details-notes";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";

const fetchPropertyDetails = async (
  propertyId,
  dispatch,
  setpropertyData,
  setLoading,
  setUserAvailableCredits,
  setPropertyNotes
) => {
  setLoading(true);
  const formData = helpers.doObjToFormData({ propertyId: propertyId });

  try {
    const { data } = await http.post("/getsearchpropertydetail", propertyId);
    console.log('data', data);
    if (data?.status === 2) {
      window.location.href = "/"
    }
    else {
      localStorage.removeItem("propertyData");
      const dataprop = data.properties[0];
      renderMap(dataprop.parcel_id);
      setpropertyData(dataprop);
      setUserAvailableCredits(data?.total_available_credits);
      setPropertyNotes(data?.notes_entries);
      console.log(dataprop);
      dispatch(updateMEmberCredits(data?.total_available_credits))
      localStorage.setItem("propertyData", JSON.stringify(dataprop));

      dispatch({
        type: FETCH_PROPERTY_DETAIL_CONTENT,
        payload: data
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PROPERTY_DETAIL_FAILED,
      payload: error
    });
  } finally {
    setLoading(false);
  }
};

const handleLikeClick = (e, propertyData, dispatch) => {
  if (!propertyData) {
    return; // or show an error message
  }

  let elem = e.target;
  http
    .post("/adduserwishlist", propertyData)
    .then((response) => {
      console.log(response.data.msg);
      if (response?.data?.status) {
        toast.success(response.data.msg, TOAST_SETTINGS);
      } else {
        toast.error(response.data.msg, TOAST_SETTINGS);
      }
      elem.classList.toggle("active");
      elem.parentNode.classList.toggle("active");
      dispatch({
        type: FETCH_WISHLIST_CONTENT_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      toast.error(<Text id="wishlist.error" />, TOAST_SETTINGS);
    });
};

// Render Map base of Parcel Id
const renderMap = (parcel_id) => {
  console.log(parcel_id);
};

const PropertyDetails = () => {
  const [propertyData, setpropertyData] = useState(null);
  const [userAvailableCredits, setUserAvailableCredits] = useState(null);
  const { propertyId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [hideSearch, setHideSearch] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [sortValue, setSortValue] = useState(null);
  const [propertyNotes, setPropertyNotes] = useState(null);

  const handleSubmitLabel = useCallback((labelName) => {
    const myFormData = {
      labelProperties: labelName,
      property_id: propertyId
    };

    http
      .post("save-label-properties", myFormData)
      .then(({ data }) => {
        console.log(data);
        toast.success(data.msg);
        setLabelName(labelName); // Set the label name received from the API
      })
      .catch((error) => {
        console.error(error);
      });
  }, [propertyId]);

  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
    console.log(hideSearch);
  };

  useEffect(() => {
    fetchPropertyDetails(propertyId, dispatch, setpropertyData, setLoading, setUserAvailableCredits, setPropertyNotes);
  }, [propertyId, dispatch]);

  console.log("userAvailableCredits", userAvailableCredits);

  const focusedParceBorderlStyle = {
    id: "focused",
    type: "line",
    paint: {
      "line-color": "yellow",
      "line-width": 2
    }
  };

  return (
    <React.StrictMode>
      <>
        <ToastContainer />
        <MobileAdvance />
        <FiltersSearch ToggleHideSearch={ToggleHideSearch} hideSearch={hideSearch} propertyDetailsSortValue={sortValue} property_details={true} />
        <DetailFilters
          ToggleHideSearch={ToggleHideSearch}
          hideSearch={hideSearch}
          handleSubmitLabel={handleSubmitLabel}
          propertyData={propertyData}
          sortValue={sortValue}
          setSortValue={setSortValue}
          userAvailableCredits={userAvailableCredits}
        />
        <section
          className={
            hideSearch
              ? "search_result_page detail_property_page"
              : "search_result_page detail_property_page filter_show_after"
          }
        >
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <div className="flex listing_relative">
              <div className="colL">
                <div
                  style={{
                    position: "relative",
                    height: 300,
                    overflow: "hidden"
                  }}
                >
                  <Mapcomponent
                    page="details"
                    filterParcelIds={[propertyData?.apnformatted]}
                    defaultBasemap="mapbox://styles/mapbox/satellite-v9"
                    hideParcelsLyr={true}
                    isStatic={true}
                    mapHeight={300}
                    parcelStyle={focusedParceBorderlStyle}
                    mapZoomPadding={0.002}
                  />
                </div>
                <div className="inner">
                  <div className="like_btn">
                    <button
                      className={propertyData.is_wish == 1 ? 'active' : ""}
                      onClick={
                        (e) => handleLikeClick(e, propertyData?.id, dispatch)
                      }
                    >
                      <img
                        src="/images/heart_ico.svg"
                        alt=""
                      />
                    </button>
                  </div>
                  {labelName ? (
                    <span>{labelName}</span>
                  ) : (
                    <span
                      style={{ backgroundColor: propertyData?.label_color }}
                    >
                      {propertyData?.label_name}
                    </span>
                  )}

                  <h4>{
                    propertyData?.address + ", " + propertyData?.city + ", " + propertyData?.state + ", " + propertyData?.zip
                  }</h4>
                  <p className="sm_pera">APN: {propertyData?.apnformatted}</p>
                  {
                    propertyData?.last_viewed_date ?
                      <p className="sm_pera">Last Viewed: {propertyData?.last_viewed_date}</p>
                      :
                      ""
                  }
                  <div className="tabs_filter">
                    <Tabs>
                      <TabList>
                        <Tab>
                          <button className="site_btn blank">
                            Building & Lot
                          </button>
                        </Tab>
                        <Tab>
                          <button className="site_btn blank">Owner</button>
                        </Tab>
                        <Tab>
                          <button className="site_btn blank">Sales</button>
                        </Tab>
                        <Tab>
                          <button className="site_btn blank">Taxes</button>
                        </Tab>
                        <Tab>
                          <button className="site_btn blank">Notes</button>
                        </Tab>
                        {/* <Tab><button className="site_btn blank">Occupants</button></Tab>
                                <Tab><button className="site_btn blank">Debts</button></Tab>
                                <Tab><button className="site_btn blank">Notes</button></Tab> */}
                      </TabList>
                      <TabPanel>
                        <div className="lot_blk_flex">
                          <div className="col">
                            <div className="lot_info_blk">
                              <h4>Building</h4>
                              <ul>
                                <li>
                                  <span>Year built</span>
                                  <span>{propertyData?.yearbuilt}</span>
                                </li>
                                {/* <li><span>Year renovated</span><span></span></li> */}
                                <li>
                                  <span>Stories</span>
                                  <span>{propertyData?.storiescount}</span>
                                </li>
                                <li>
                                  <span>Building Area</span>
                                  <span>{Number(propertyData?.square)?.toLocaleString()} sqft</span>
                                </li>
                                <li>
                                  <span>Parking Area</span>
                                  <span>{propertyData?.parkinggaragearea}</span>
                                </li>
                                <li>
                                  <span>Commercial Unit</span>
                                  <span>--</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col">
                            <div className="lot_info_blk">
                              <h4>Lot</h4>
                              <ul>
                                <li>
                                  <span>Property Type</span>
                                  <span>{propertyData?.property_type}</span>
                                </li>
                                <li>
                                  <span>Lot area SF</span>
                                  <span>{Number(propertyData?.arealotsf)?.toLocaleString()} sf </span>
                                </li>
                                <li>
                                  <span>Lot area acres</span>
                                  <span>
                                    {propertyData?.arealotacres} acres
                                  </span>
                                </li>
                                <li>
                                  <span>Zoning</span>
                                  <span>{propertyData?.zonedcodelocal}</span>
                                </li>
                                <li>
                                  <span>Depth</span>
                                  <span>{propertyData?.arealotdepth}</span>
                                </li>
                                <li>
                                  <span>Census Track</span>
                                  <span>{propertyData?.Census_tract}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="lot_info_blk">
                          <h4>Location</h4>
                          <ul>
                            <li>
                              <span>Metropolitan Statistical Area</span>
                              <span>
                                {propertyData?.metropolitan_statistical}
                              </span>
                            </li>
                            <li>
                              <span>County</span>
                              <span>{propertyData?.situscounty}</span>
                            </li>
                            <li>
                              <span>Municipality</span>
                              <span>{propertyData?.city}</span>
                            </li>
                            <li>
                              <span>Minor Civil Division</span>
                              <span>
                                {propertyData?.minorcivildivisionname}
                              </span>
                            </li>
                            <li>
                              <span>Neighborhood</span>
                              <span>{propertyData?.neighborhoodcode}</span>
                            </li>
                            <li>
                              <span>Legal</span>
                              <span>{propertyData?.legaldescription}</span>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="owner_blk_detail">
                          <div className="owner_card">
                            <div className="card_head">
                              <p>Property Owner</p>
                              <div className="locate_right">
                                <span>Location:</span>
                                <span>{propertyData?.city}</span>
                              </div>
                            </div>
                            <div className="owner_info">
                              <div className="owner_icons">
                                <span>{propertyData?.owner}</span>
                              </div>
                              <div className="cnt">
                                <h6>{propertyData?.owner}</h6>
                                <p>{propertyData?.address}</p>
                              </div>
                            </div>
                            <div className="tiles_flex">
                              <div className="col">
                                <div className="inner relative">
                                  <a target="_blank" href={"/owner-property/" + propertyData?.owner_email}>
                                    <p>Properties in Portfolio</p>
                                    <h6>
                                      {propertyData?.count_properties?.toLocaleString()}
                                    </h6>
                                  </a>
                                </div>
                              </div>
                              <div className="col">
                                <div className="inner">
                                  <p>Last Acquisition Date</p>
                                  <h6>{propertyData?.assessorlastsaledate}</h6>
                                </div>
                              </div>
                              <div className="col">
                                <div className="inner">
                                  <p>Portfolio Assessed Value</p>
                                  <h6>
                                    $
                                    {Number(propertyData?.portfolio_assessed_price)?.toLocaleString()}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contacts">
                            <h3>Contacts</h3>
                            <div className="contact_info">
                              <div className="dp">
                                <img src="/images/profile.svg" alt="" />
                              </div>
                              <div className="cnt">
                                <h6>{propertyData?.owner}</h6>
                                <p>{propertyData?.address}</p>
                              </div>
                            </div>
                            <div className="devide_line"></div>
                            <div className="contact_information">
                              <h4>Contact information</h4>
                              <div className="contact_info">
                                <div className="dp">
                                  <img src="/images/profile.svg" alt="" />
                                </div>
                                <div className="cnt">
                                  <h5>
                                    {propertyData.email
                                      ? <a href={"mailto:" + propertyData.email}>{propertyData.email}</a>
                                      : "N/A"}
                                  </h5>
                                </div>
                              </div>
                              <div className="devide_line"></div>
                              <div className="contact_info">
                                <div className="dp">
                                  <img src="/images/profile.svg" alt="" />
                                </div>
                                <div className="cnt">
                                  <h5>
                                    {propertyData.call
                                      ? <a href={"tel:" + propertyData.call}>{propertyData.call}</a>
                                      : "N/A"}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="lot_info_blk">
                          <h4>Sales</h4>
                          <ul>
                            <li>
                              <span>Sales price</span>
                              <span>
                                {Number(propertyData?.assessorlastsaleamount)?.toLocaleString()}
                              </span>
                            </li>
                            <li>
                              <span>Sales Date</span>
                              <span>{propertyData?.assessorlastsaledate}</span>
                            </li>
                            <li>
                              <span>Mortgage Amount</span>
                              <span>{Number(propertyData?.mortgage1amount)?.toLocaleString()}</span>
                            </li>
                            <li>
                              <span>Mortgage origination or date</span>
                              <span>
                                {propertyData?.mortgage1recordingdate}
                              </span>
                            </li>
                            <li>
                              <span>Foreclosure</span>
                              <span>
                                {propertyData?.foreclosureauctionsale}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="lot_info_blk">
                          <h4>Taxes</h4>
                          <ul>
                            <li>
                              <span>Tax amount</span>
                              <span>{propertyData?.taxbilledamount}</span>
                            </li>
                            <li>
                              <span>Tax year</span>
                              <span>{propertyData?.taxfiscalyear}</span>
                            </li>
                            <li>
                              <span>Tax Assessed land value</span>
                              <span>{Number(propertyData?.taxassessedvalueland)?.toLocaleString()}</span>
                            </li>
                            <li>
                              <span>Tax Assessed Value total</span>
                              <span>{Number(propertyData?.taxassessedvaluetotal)?.toLocaleString()}</span>
                            </li>
                            <li>
                              <span>Tax Bill Amount</span>
                              <span>{Number(propertyData?.taxbilledamount)?.toLocaleString()}</span>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="lot_info_blk">
                          <h4>Notes</h4>
                          <p><strong>Note: </strong> This is a private section for you to write your personal notes about this property</p>
                          <PrtopertyDetailsNotes property_id={propertyData?.id} user_notes={propertyData?.user_notes} propertyNotes={propertyNotes} setPropertyNotes={setPropertyNotes} />
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="colR">
                <div className="outer_map">
                  <div className="map">
                    <Mapcomponent
                      filterParcelIds={[propertyData?.apnformatted]}
                      page="details"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </>
    </React.StrictMode>
  );
};

export default PropertyDetails;
