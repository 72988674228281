import React, { useState,useEffect  } from "react";
import Text from "../../common/Text";
import { useDispatch } from 'react-redux';
import { search } from "../../../states/actions/fetchnewsletteremail";
import { ToastContainer } from 'react-toastify';

const Cta = ({ content}) => {
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState({type: '', text: ''});
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      email: query
    };
    dispatch(search(formData, setMessage));
  };
  useEffect(() => {
    if (message.text) {
      // Show the message element
      const toastContainer = document.getElementById('my-toast-container');
      toastContainer.classList.add(message.type);

      // Hide the message element after 2 to 3 seconds
      const timerId = setTimeout(() => {
        toastContainer.classList.remove(message.type);
        setMessage({type: '', text: ''});
      }, 2000);
      
      // Clear the timer when the component unmounts
      return () => clearTimeout(timerId);
    }
  }, [message]);

  return (
    <>
      <section className="cta_sec">
        <div className="contain">
          <div className="cntnt">
            <h5>
              <Text  string={content?.section_4_heading_1} />
            </h5>
            <h3>
              <Text string={content?.section_4_heading_2} />
            </h3>
            <p>
              <Text string={content?.section_4_tagline} />
            </p>
            <p id="my-toast-container" className={message.type}>
              {message.text && <Text string={message.text} />}
            </p>
            <form onSubmit={handleSubmit}>
              <div className="input_relative">
                <input
                  type="email"
                  className="input"
                  placeholder={content?.section_4_search_heading}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button className="site_btn" type="submit">
                  <Text string={content?.section_4_search_button_text} />
                </button>
              </div>
            </form>
            <Text string={content?.section_4_search_tagline} />
          </div>
        </div>
        
      </section>
      <style>
        {`
          

           .success div{
            font-weight:bold;
            color: #4caf50;
          }

           .error div{
            font-weight:bold;
            color: #f44336 !important;
          }
        `}
      </style>
    </>
  );
}

export default Cta;
