import React, { useEffect } from "react";
import Card from "./Card";
import Intro from "./Intro";

import { fetchAccountInvitation } from "../../../states/actions/fetchAccountInvitation";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import { ToastContainer } from "react-toastify";

const AccountInvitation = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchAccountInvitation.content);
  const isLoading = useSelector(
    (state) => state.fetchAccountInvitation.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content } = data;

  useEffect(() => {
    dispatch(fetchAccountInvitation());
  }, []);

  useDocumentTitle(data.page_title);

  const log = {
    card: {
      image: "/images/property.png",
      popLbl: "POPULAR",
      infoLbl: "Also owns",
      infoLnkLbl: "3 other properties",
      infoLnk: "/",
      phone: "+1234567890",
      email: "john@doe.com",
      position: "Owner",
      name: "John Doe",
      btnLbl: "Contact Now",
      btnLnk: "/"
    }
  };
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section className="logon">
            <div className="contain">
              <div className="flex">
                <Intro content={content} />
                <div className="colR">
                  <Card data={log.card} />
                </div>
              </div>
            </div>
            <div className="lg_image">
              <ImageControl src={content.image1} folder="images" />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AccountInvitation;
