import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";

import { TOAST_SETTINGS } from "../../utils/siteSettings";

import Text from "../../components/common/Text";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_PRICING_CONTENT,
  FETCH_PRICING_CONTENT_SUCCESS,
  FETCH_PRICING_CONTENT_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE,
  CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
  UPDATE_SUBSCRIPTION_MESSAGE,
  UPDATE_SUBSCRIPTION_MESSAGE_FAILED,
  UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchPricing,
  cancelSubscription,
  updateSubscription,
} from "./fetchPricing";

const popupStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "999",
};

const contentStyle = {
  position: "relative",
  background: "#fff",
  borderRadius: "5px",
  width: "80%",
  maxWidth: "500px",
  padding: "20px",
  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
};

const radioStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const labelStyle = {
  marginLeft: "10px",
};
const CustomPopup = (props) => {
  const [reason, setReason] = useState("");
  const [showOtherReason, setShowOtherReason] = useState(false);
  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const formData = {};
  const subscription = site_settings?.member?.mem_subscription;

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const planid = subscription?.id;

    if (reason) {
      const myFormData = {
        // add your form data fields and values here
        planid: planid,
        reason: reason,
        // ...
      };
      http
        .post(
          "cancel-member-subscription",
          doObjToFormData({
            token: localStorage.getItem("authToken"),
            ref: myFormData,
          })
        )
        .then(({ data }) => {
          console.log(data);
          if (data.status) {
            toast.success(data?.msg, TOAST_SETTINGS);
            dispatch({
              type: CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
              payload: data,
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            if (data?.msg) {
              toast.error(
                <Text string={data.msg} parse={true} />,
                TOAST_SETTINGS
              );
              dispatch({
                type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
                payload: null,
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
            payload: error,
          });
        });
    } else {
      toast.error("Please Enter Reason to continue.");
    }
  };

  // const handleCancel = () => {
  //   // Handle form cancellation here
  //   setShow(false);
  //   onCancel(reason);
  // };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "other") {
      setShowOtherReason(true);
      setReason("");
    } else {
      setShowOtherReason(false);
      setReason(e.target.value);
    }
  };

  return (
    <div style={popupStyle}>
      <div style={contentStyle}>
        <h2>Cancel Subscription</h2>
        <form onSubmit={handleSubmit}>
          <div style={radioStyle}>
            <input
              type="radio"
              id="reason1"
              name="reason"
              value="reason1"
              onChange={handleRadioChange}
            />
            <label htmlFor="reason1" style={labelStyle}>
              Reason 1
            </label>
          </div>
          <div style={radioStyle}>
            <input
              type="radio"
              id="reason2"
              name="reason"
              value="reason2"
              onChange={handleRadioChange}
            />
            <label htmlFor="reason2" style={labelStyle}>
              Reason 2
            </label>
          </div>
          <div style={radioStyle}>
            <input
              type="radio"
              id="reason3"
              name="reason"
              value="reason3"
              onChange={handleRadioChange}
            />
            <label htmlFor="reason3 " style={labelStyle}>
              Reason 3
            </label>
          </div>
          <div style={radioStyle}>
            <input
              type="radio"
              id="other"
              name="reason"
              value="other"
              onChange={handleRadioChange}
            />
            <label htmlFor="other" style={labelStyle}>
              Other
            </label>
          </div>
          {showOtherReason && (
            <div>
              <label>
                Other Reason:
                <textarea
                  value={reason}
                  onChange={handleReasonChange}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100px",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginBottom: "20px",
                  }}
                />
              </label>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button type="submit" className="site_btn blank">
              Submit
            </button>
            <button
              type="button"
              onClick={props.onCancel}
              className="site_btn blank"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomPopup;
