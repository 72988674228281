import React from "react";
import ImageControl from "../../common/ImageControl";

const Feature = ({ content }) => {
  return (
    <>
      <section className="feature_sec">
        <div className="contain">
          <div className="flex">
            <div className="col" key={1000000000}>
              <div className="icon_sm">
                <ImageControl src={content.image2} folder="images" />
              </div>
              <p>{content.section2_cards_text_1}</p>
            </div>
            <div className="col" key={21000000000}>
              <div className="icon_sm">
                <ImageControl src={content.image3} folder="images" />
              </div>
              <p>{content.section2_cards_text_2}</p>
            </div>
            <div className="col" key={31000000000}>
              <div className="icon_sm">
                <ImageControl src={content.image4} folder="images" />
              </div>
              <p>{content.section2_cards_text_3}</p>
            </div>
            <div className="col" key={41000000000}>
              <div className="icon_sm">
                <ImageControl src={content.image5} folder="images" />
              </div>
              <p>{content.section2_cards_text_4}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
