import React from "react";
import { Link } from "react-router-dom";

const ViewdOwners = ({data}) => { 
   
  return (
    <>
      <section className="viewd_owners_list">
        <div className="contain">
            <h4 className="light_heading f_s_24">{data?.heading}</h4>
            <div className="flex">
                {data.owner_listing?.map((val,i) => {
                    return(
                    <div className="_col" key={i}>
                        <div className="inner">
                            <p>{val.designation}</p>
                            <div className="inner_flex">
                                <div className="cntnt">
                                    <div className="sm_icon">
                                        <span>{val?.dp}</span>
                                    </div>
                                    <div className="_cntnt">
                                        <h6>{val.name}</h6>
                                        <p>{val.address}</p>
                                    </div>
                                </div>
                                <div className="btn_blk">
                                    <Link to={`/owner-property/${val.link}`} className="site_btn yellow_btn"><img src="/images/info_circle.svg" alt="" /><span>Get more info</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
                })}
                
            </div>
        </div>
      </section>
    </>
  );
};

export default ViewdOwners;
