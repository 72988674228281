import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const Posts = ({ all_articles }) => {
  return (
    <>
      <div className="flex post_flex">
        {all_articles?.map((blog, i) => {
          return (
            <div className="col" key={i+7654100000000}>
              <div className="post_blk">
                <Link
                  to={`/blog-detail/${blog.encoded_id}/${blog.slug}`}
                  className="image"
                >
                  <ImageControl src={blog.image} folder="blog" />
                </Link>
                <div className="cntnt">
                  <div className="cat_lbl">{blog.cat_name}</div>
                  <h4>
                    <Link to={`/blog-detail/${blog.encoded_id}/${blog.slug}`}>
                      <Text string={blog.title} length={50} />
                    </Link>
                  </h4>
                  <Text string={blog.detail} length={200} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Posts;
