import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";

export default function FeedbackForm({ content, handleSaveFeedback }) {
  const isFormProcessing = useSelector(
    (state) => state.fetchFeedback.isFormProcessing
  );
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    handleSaveFeedback(data);
    setTimeout(() => {
      reset();
    }, 2000);
  };
  return (
    <>
      <div className="colR">
        <div className="inner">
          <h3>{content?.form_heading}</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form_blk">
              <input
                id="frm-name"
                type="text"
                name="name"
                autoComplete="name"
                placeholder="Full Name"
                className="input"
                {...register("name", {
                  required: "Name is required.",
                  minLength: {
                    value: 2,
                    message: "Name should contains atleast 2 letters.",
                  },
                })}
              />
              <span className="validation-error">{errors.name?.message}</span>
            </div>
            <div className="form_blk">
              <input
                id="frm-phone"
                type="text"
                name="phone"
                autoComplete="tel"
                placeholder="Phone Number"
                className="input"
                {...register("phone", {
                  required: "Phone is required.",
                })}
              />
              <span className="validation-error">{errors.phone?.message}</span>
            </div>
            <div className="form_blk">
              <input
                id="frm-email"
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Email Address"
                className="input"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              <span className="validation-error">{errors.email?.message}</span>
            </div>
            <div className="form_blk">
              <textarea
                id="frm-message"
                name="message"
                className="input"
                placeholder="Enter Your Message Here"
                {...register("message", {
                  required: "Message is required.",
                })}
              ></textarea>
              <span className="validation-error">
                {errors.message?.message}
              </span>
            </div>
            <div className="btn_blk">
              <SubmitButton
                classes="site_btn"
                button_text={"Send"}
                isFormProcessing={isFormProcessing}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
