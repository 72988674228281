import React from "react";
import Text from "../../common/Text";

const Banner = ({ content }) => {
  return (
    <>
      <section className="data_banner blank_banner">
        <div className="contain">
          <div className="text-center">
            <h3>
              <Text string={content.banner_heading} />
            </h3>
            <p>
              <Text string={content.banner_text} />
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
