import React from "react";

const Banner = ({ content }) => {
  return (
    <>
      <section className="data_banner blank_banner searches_banner_saved">
        <div className="dim_img dim_sm_img">
          <img src="/images/half_rahino.svg" alt="" />
        </div>
        <div className="contain">
          <h3>{content.banner_heading}</h3>
        </div>
      </section>
    </>
  );
};

export default Banner;
