import React from "react";
import Form from "./Form";
import { useSelector } from "react-redux";

const Intro = ({ content }) => {
  const authentication = useSelector(
    (state) => state.fetchSignin.authentication
  );
  return (
    <>
      <div className="colL">
        <div className="inner">
          {!authentication && (
            <React.Fragment>
              <h4>{content.left_sec_heading}</h4>
              <p>{content.left_sec_text}</p>
            </React.Fragment>
          )}
          <Form content={content} />
        </div>
      </div>
    </>
  );
};

export default Intro;
