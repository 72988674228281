import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import SubmitButton from "../../common/SubmitButton";
import { ToastContainer } from "react-toastify";
const Form = ({ content, primary_interests, industries, handleEnterpriseSubmit, isFormProcessing, member }) => {
  const team_size = [
    {
      value: 1,
      label: "1-10"
    },
    {
      value: 2,
      label: "10-15"
    },
    {
      value: 3,
      label: "15-25"
    },
    {
      value: 4,
      label: "25-50"
    },
    {
      value: 5,
      label: "50+"
    }
  ];



  const onSubmit = (formData) => {
    console.log(formData)
    handleEnterpriseSubmit(formData)
  };
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit, setValue, control
  } = useForm();
  const watchAllFields = watch();
  useEffect(() => {
    if (member?.id > 0) {
      setValue("name", member?.mem_fname + " " + member?.mem_lname)
      setValue("email", member?.mem_email)
    }
  }, [member]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer />
        <div className="blk_form">
          <h6>Name</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              {...register("name", {
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: 'Invalid value',
                },
                required: "Required"
              })}
              placeholder="Full name"
              defaultValue={watchAllFields?.name}
              
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <p className='validation_error'> {message}</p>}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Email</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              {...register("email",
                {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email"
                  },
                  required: "Required"
                })}
              placeholder="hi@example.com"
              defaultValue={watchAllFields?.email}
              
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <p className='validation_error'> {message}</p>}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Phone Number</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              {...register("phone", {
                pattern: {
                  value:
                    /^[0-9\b]+$/,
                  message: "Please enter a valid email"
                },
                required: "Required"
              })}
              placeholder="+1234567890"
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => <p className='validation_error'> {message}</p>}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Company</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              {...register("company", { required: "Required" })}
              placeholder="Company name"
            />
            <ErrorMessage
              errors={errors}
              name="company_name"
              render={({ message }) => <p className='validation_error'> {message}</p>}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Job title</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              {...register("job_title", { required: "Required" })}
              placeholder="Job title"
            />
            <ErrorMessage
              errors={errors}
              name="job_title"
              render={({ message }) => <p className='validation_error'> {message}</p>}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Your industry</h6>
          <div class="form_blk">
            <Controller
              {...register("industry", { required: "Required" })}
              name="industry"
              id="industry"
              control={control}
              // defaultValue={defaultFormValues?.industry}
              render={({ field: { onChange, value, ref, name } }) => (
                <>
                  <Select
                    options={industries}
                    onChange={val => {
                      onChange(val.value);
                      setValue("state_label", val.label);
                    }}
                    label="Select State"
                    defaultValue={industries.filter(function (option) {
                      return option.value === value;
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="industry"
                    render={({ message }) => <p className='validation_error'> {message}</p>}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="blk_form">
          <h6>Your Interest</h6>
          <div class="form_blk">
            <Controller
              {...register("your_focus", { required: "Required" })}
              name="your_focus"
              id="your_focus"
              control={control}
              // defaultValue={defaultFormValues?.primary_interest}
              render={({ field: { onChange, value, ref, name } }) => (
                <>
                  <Select
                    options={primary_interests}
                    onChange={val => {
                      onChange(val.value);
                      setValue("state_label", val.label);
                    }}
                    label="Select State"
                    defaultValue={primary_interests.filter(function (option) {
                      return option.value === value;
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="your_focus"
                    render={({ message }) => <p className='validation_error'> {message}</p>}
                  />
                </>
              )}
            />

          </div>
        </div>
        <div className="blk_form">
          <h6>Your Company size</h6>
          <div class="form_blk">
            <Controller
              {...register("company_size", { required: "Required" })}
              name="company_size"
              id="company_size"
              control={control}
              // defaultValue={defaultFormValues?.company_size}
              render={({ field: { onChange, value, ref, name } }) => (
                <>
                  <Select
                    options={team_size}
                    onChange={val => {
                      onChange(val.value);
                      setValue("state_label", val.label);
                    }}
                    label="Select Company Size"
                    defaultValue={team_size.filter(function (option) {
                      return option.value === value;
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="company_size"
                    render={({ message }) => <p className='validation_error'> {message}</p>}
                  />
                </>
              )}
            />

          </div>
        </div>
        <div className="blk_form">
          <h6>Please briefly describe your inquiry</h6>
          <div class="form_blk">

            <textarea name="inquiry" id="inquiry" className="input" {...register("inquiry")}></textarea>

          </div>
        </div>
        <div className="btn_blk">

          <SubmitButton
            classes="site_btn"
            button_text={content.left_sec_button_text}
            isFormProcessing={isFormProcessing}
          />
        </div>
      </form>
    </>
  );
};

export default Form;
