import React from "react";

const ShowPasswordIcon = ({ showPassword, handleShowPassword }) => {
  return (
    <i
      className={`icon-eye${showPassword ? `-slash` : ""}`}
      onClick={handleShowPassword}
    />
  );
};

export default ShowPasswordIcon;
