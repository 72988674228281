import React from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function MetaGenerator({ page_title, meta_desc }) {
    const location = useLocation()
    const canonicalUrl = `${window.location.origin}${location.pathname}${location.search}`;

    return <>
        <Helmet>
            <title>{page_title}</title>
            <meta name="title" content={meta_desc?.meta_title} />
            <meta name="description" content={meta_desc?.meta_description} />
            <meta name="keywords" content={meta_desc?.meta_keywords} />

            <meta property="og:type" content="Website" />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:title" content={meta_desc?.meta_title} />
            <meta property="og:description" content={meta_desc?.meta_description} />
            <meta property="og:image" content={(meta_desc?.meta_thumb)} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={canonicalUrl} />
            <meta property="twitter:title" content={meta_desc?.meta_title} />
            <meta
                property="twitter:description"
                content={meta_desc?.meta_description}
            />
            <meta
                property="twitter:image"
                content={(meta_desc?.meta_thumb)}
            />
        </Helmet>
    </>;
}
