import React from "react";
import Text from "./Text";
import FormProcessingSpinner from "./FormProcessingSpinner";

const SubmitButton = ({
  button_text = "Submit",
  classes = "site_btn",
  type = "submit",
  isFormProcessing = false,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      className={classes && classes}
      type={type}
      disabled={isFormProcessing || disabled}
      {...rest}
    >
      <Text string={button_text} />
      <FormProcessingSpinner isFormProcessing={isFormProcessing} />
    </button>
  );
};
export default SubmitButton;
