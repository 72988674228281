import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { price_format } from "../../../helpers/helpers";
import Text from "../../common/Text";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPopup from "../../../states/actions/CancelPopup";

const Packages = ({
  handleCancelSubscription,
  isFormProcessing,
  handleUpdateSubscription,
}) => {
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const [currentTab, setCurrentTab] = useState(1);
  const [show, setShow] = useState(false);
  const data = useSelector((state) => state.fetchPricing.content);
  const { content, monthly_plans, annual_plans } = data;
  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const subscription = site_settings?.member?.mem_subscription;
  const user_subscription_exist =
    site_settings?.member?.user_subscription_exist;
  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  const handleToggle = () => {
    setShow(!show);
  };
  // console.log(data)
  return (
    <>
      <section className="pricing">
        <div className="contain">
          <div className="price_heading text-center">
            <h5>
              {content.banner_heading} <sub>{content.banner_tagline}</sub>
            </h5>
            <p>{content.banner_text}</p>
          </div>
          <ul className="nav nav-tabs">
            <li key={10000}>
              <button
                id={1}
                disabled={currentTab === 1}
                onClick={() => handleTabClick(1)}
                className={currentTab === 1 ? "active" : ""}
              >
                Monthly Plans
              </button>
            </li>
            <li key={20000}>
              <button
                id={2}
                disabled={currentTab === 2}
                onClick={() => handleTabClick(2)}
                className={currentTab === 2 ? "active" : ""}
              >
                Annual Plans
                <span>Save 20%</span>
              </button>
            </li>
          </ul>
          {isFormProcessing === true ? (
            <div className="relative">
              <div id="loadingDisabled">
                <div id="pageloader" className="">
                  <span className="loader"></span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {currentTab === 1 ? (
            <div className="tab-content">
              <div className="flex price_flex new_price_flex">
                {monthly_plans &&
                  monthly_plans?.map((plan, i) => (
                    <div className="col" key={i + 100000}>
                      <div
                        className={
                          plan?.best_status === 1 ? "inner active" : "inner"
                        }
                      >
                        <div
                          className={
                            parseInt(plan?.is_enterprise) === 1
                              ? "head_pricing enterprise_head_pricing"
                              : "head_pricing"
                          }
                        >
                          <h5>{plan.plan_name}</h5>
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <h4 className="enterprise_heading">
                              {plan?.price_tagline}
                            </h4>
                          ) : (
                            ""
                          )}
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <h4 className="enterprise_heading">
                              {/* {plan.tagline} */}
                            </h4>
                          ) : (
                            <>
                              <h2 className="big_price">
                                {price_format(plan.price)}
                                <sub>
                                  / {plan.type === "monthly" ? "mo" : "mo"}
                                </sub>
                              </h2>
                              {/* <p>{plan.tagline}</p> */}
                            </>
                          )}
                        </div>
                        <div className="price_body">
                          {/* <Text string={plan.description} /> */}
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <ul>
                              <li>
                                Unlimited <span>Clicks</span>
                              </li>
                              <li>
                                Unlimited <span>Downloads</span>
                              </li>
                              <li>
                                Unlimited <span>Users</span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li className="or_lbl">
                                {parseInt(plan?.clicks_count)}{" "}
                                <span>Clicks</span>
                                <em>Or</em>
                              </li>
                              <li>
                                {parseInt(plan?.downloads_count)}{" "}
                                <span>Downloads</span>
                              </li>
                              {/* <li>{parseInt(plan?.user_count)} <span>User</span></li>*/}
                              <li>
                                {parseInt(plan?.user_count)}{" "}
                                <span>
                                  {parseInt(plan?.user_count) > 1
                                    ? "Users"
                                    : "User"}
                                </span>
                              </li>
                            </ul>
                          )}
                        </div>
                        <div
                          className={
                            parseInt(plan?.is_enterprise) === 1
                              ? "price_footer price_footer_enterprise"
                              : "price_footer"
                          }
                        >
                          {plan?.annual_price !== null &&
                            plan?.annual_price !== undefined &&
                            plan?.annual_price > 0 ? (
                            <h4>
                              {price_format(plan?.annual_price)}
                              <sub>/ {plan.annual_price_type}</sub>
                            </h4>
                          ) : (
                            ""
                          )}
                          {plan?.annual_price_sub_title !== null &&
                            plan?.annual_price_sub_title !== undefined &&
                            plan?.annual_price_sub_title !== "" ? (
                            <h6>
                              {/* {plan?.annual_price_sub_title} */}
                            </h6>
                          ) : (
                            ""
                          )}

                          {parseInt(subscription?.plan_id) === plan?.id ? (
                            <div className="site_btn blank active">
                              Subscribed
                            </div>
                          ) : parseInt(plan?.is_enterprise) === 1 ? (
                            <>
                              <h6></h6>
                              <Link
                                to={
                                  "/enterprise-checkout/" +
                                  plan?.encoded_id +
                                  "/" +
                                  plan?.plan_slug
                                }
                                className="site_btn blank"
                              >
                                {plan?.button_text}
                              </Link>
                            </>
                          ) : user_subscription_exist !== undefined &&
                            user_subscription_exist !== null &&
                            user_subscription_exist !== "" &&
                            user_subscription_exist?.subscription_status ===
                            0 ? (
                            <Link
                              to={`/checkout/${plan.encoded_id}`}
                              className="site_btn blank"
                            //onClick={() => { if (window.confirm('Are you sure you wish to update subscription?')) handleUpdateSubscription(plan?.encoded_id) }}
                            >
                              Update Package
                            </Link>
                          ) : user_subscription_exist !== undefined &&
                            user_subscription_exist !== null &&
                            user_subscription_exist?.id > 0 ? (
                            <Link
                              to="#!"
                              className="site_btn blank"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to update subscription?"
                                  )
                                )
                                  handleUpdateSubscription(plan?.encoded_id);
                              }}
                            >
                              Update Package
                            </Link>
                          ) : authToken && authToken != null ? (
                            <Link
                              to={`/checkout/${plan.encoded_id}`}
                              className="site_btn blank"
                            >
                              {plan?.button_text}
                            </Link>
                          ) : (
                            <Link to={`/signup`} className="site_btn blank">
                              {plan?.button_text}
                            </Link>
                          )}
                          {parseInt(subscription?.plan_id) === plan?.id &&
                            subscription?.expiry_status == 1 &&
                            subscription?.subscription_status === 0 ? (
                            <span className="badge badge-danger">
                              This plan will be cancelled at{" "}
                              <strong>{subscription?.expiry_date}</strong>
                            </span>
                          ) : (
                            ""
                          )}
                          {/* <div className="text-center mini_lbl">
                                {plan.trial_days > 0 &&
                                  `Free ${plan.trial_days} Days Trial`}
                              </div> */}
                          {/*
                              subscription !== undefined && subscription !== null && subscription !== '' && subscription?.id > 0 && parseInt(subscription?.plan_id) === plan?.id && subscription?.subscription_status === 1 ?
                                  <Link to="#!" className="site_btn sm_btn" onClick={() => { if (window.confirm('Are you sure you wish to cancel subscription?')) handleCancelSubscription(subscription?.id) }} disabled={isFormProcessing ? true : false}>
                                   </Link> <Link to="#!" className="site_btn sm_btn" onClick={handleToggle}> 
                                  Cancel Subscription
                                  <FormProcessingSpinner isFormProcessing={isFormProcessing} />
                                </Link>
                                
                                : ''
                              */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : currentTab === 2 ? (
            <div className="tab-content">
              <div className="flex price_flex new_price_flex">
                {annual_plans &&
                  annual_plans?.map((plan, i) => (
                    <div className="col" key={i + 200000}>
                      <div
                        className={
                          plan?.best_status === 1 ? "inner active" : "inner"
                        }
                      >
                        <div
                          className={
                            parseInt(plan?.is_enterprise) === 1
                              ? "head_pricing enterprise_head_pricing yes"
                              : "head_pricing"
                          }
                        >
                          <h5>{plan.plan_name}</h5>
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <h4 className="enterprise_heading">
                              {plan?.price_tagline}
                            </h4>
                          ) : (
                            ""
                          )}
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <h4 className="enterprise_heading">
                              {/* {plan.tagline} */}
                            </h4>
                          ) : parseInt(plan?.is_enterprise) !== 1 ? (
                            <>
                              <h2 className="big_price">
                                {price_format(plan.annual_price)}
                                <sub>
                                  / {plan.type === "monthly" ? "mo" : "mo"}
                                </sub>
                              </h2>
                              <p>{plan.tagline}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="price_body">
                          {/* <Text string={plan.description} /> */}
                          {parseInt(plan?.is_enterprise) === 1 ? (
                            <ul>
                              <li>
                                Unlimited <span>Clicks</span>
                              </li>
                              <li>
                                Unlimited <span>Downloads</span>
                              </li>
                              <li>
                                Unlimited <span>Users</span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li className="or_lbl">
                                {parseInt(plan?.clicks_count)}{" "}
                                <span>Clicks</span>
                                <em>Or</em>
                              </li>
                              <li>
                                {parseInt(plan?.downloads_count)}{" "}
                                <span>Downloads</span>
                              </li>
                              {/* <li>{parseInt(plan?.user_count)} <span>User</span></li>*/}
                              <li>
                                {parseInt(plan?.user_count)}{" "}
                                <span>
                                  {parseInt(plan?.user_count) > 1
                                    ? "Users"
                                    : "User"}
                                </span>
                              </li>
                            </ul>
                          )}
                        </div>
                        <div
                          className={
                            parseInt(plan?.is_enterprise) === 1
                              ? "price_footer price_footer_enterprise yes"
                              : "price_footer"
                          }
                        >
                          {parseInt(plan?.is_enterprise) !== 1 &&
                            plan?.price !== null &&
                            plan?.price !== undefined &&
                            plan?.price > 0 ? (
                            <h4>
                              {/* {price_format(plan?.price)}
                              <sub>
                                / {plan.type === "monthly" ? "mo" : "yr"}
                              </sub> */}
                            </h4>
                          ) : (
                            ""
                          )}
                          {plan?.annual_price_sub_title !== null &&
                            plan?.annual_price_sub_title !== undefined &&
                            plan?.annual_price_sub_title !== "" &&
                            parseInt(plan?.is_enterprise) !== 1 ? (
                            <h6>
                              {/* {plan?.annual_price_sub_title} */}
                            </h6>
                          ) : (
                            ""
                          )}

                          {parseInt(subscription?.plan_id) === plan?.id ? (
                            <div className="site_btn blank active">
                              Subscribed
                            </div>
                          ) : plan?.enterprise_checkout ? (
                            <>
                              <h6></h6>
                              <Link
                                to={
                                  "/enterprise-checkout/" +
                                  plan?.encoded_id +
                                  "/" +
                                  plan?.plan_slug
                                }
                                className="site_btn blank"
                              >
                                {plan?.button_text}
                              </Link>
                            </>
                          ) : user_subscription_exist !== undefined &&
                            user_subscription_exist !== null &&
                            user_subscription_exist !== "" &&
                            subscription?.subscription_status === 0 ? (
                            <Link
                              to={`/checkout/${plan.encoded_id}`}
                              className="site_btn blank"
                            // onClick={() => { if (window.confirm('Are you sure you wish to update subscription?1')) handleUpdateSubscription(plan?.encoded_id) }}
                            >
                              Update Package
                            </Link>
                          ) : user_subscription_exist !== undefined &&
                            user_subscription_exist !== null &&
                            user_subscription_exist?.id > 0 ? (
                            <Link
                              to="#!"
                              className="site_btn blank"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to update subscription?"
                                  )
                                )
                                  handleUpdateSubscription(plan?.encoded_id);
                              }}
                            >
                              Update Package
                            </Link>
                          ) : authToken && authToken != null ? (
                            <Link
                              to={`/checkout/${plan.encoded_id}`}
                              className="site_btn blank"
                            >
                              {plan?.button_text}
                            </Link>
                          ) : (
                            <Link to={`/signup`} className="site_btn blank">
                              {plan?.button_text}
                            </Link>
                          )}
                          {parseInt(subscription?.plan_id) === plan?.id &&
                            subscription?.expiry_status == 1 &&
                            subscription?.subscription_status === 0 ? (
                            <span className="badge badge-danger">
                              This plan will be cancelled at{" "}
                              <strong>{subscription?.expiry_date}</strong>
                            </span>
                          ) : (
                            ""
                          )}
                          {/* <div className="text-center mini_lbl">
                                {plan.trial_days > 0 &&
                                  `Free ${plan.trial_days} Days Trial`}
                              </div> */}
                          {/*
                                subscription !== undefined && subscription !== null && subscription !== '' && subscription?.id > 0 && parseInt(subscription?.plan_id) === plan?.id && subscription?.subscription_status === 1 ?
                                  <Link to="#!" className="site_btn sm_btn" onClick={handleToggle}> 
                                    Cancel Subscription
                                    <FormProcessingSpinner isFormProcessing={isFormProcessing} />
                                  </Link>
                                  : ''
                                */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {show ? (
          <>
            <div>
              <CustomPopup onCancel={handleToggle} />
            </div>
          </>
        ) : null}
      </section>
    </>
  );
};

export default Packages;
