import {
  FETCH_PROFILE_SETTINGS,
  FETCH_PROFILE_SETTINGS_SUCCESS,
  FETCH_PROFILE_SETTINGS_FAILED,
  SAVE_PROFILE_SETTINGS,
  SAVE_PROFILE_SETTINGS_SUCCESS,
  SAVE_PROFILE_SETTINGS_FAILED,
  TOGGLE_CHANGE_PASSWORD_POPUP,
  TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILED,
  SHOW_USER_POPUP,
  CANCEL_SUBSCRIPTION_MESSAGE,
  CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isFormProcessing: false,
  isPassChangeProcessing: false,
  isChangePasswordPopupShowing: false,
  isDeleteAccountPopupShowing: false,
  showUserPopup: false,
  content: {},
  mem: {},
  notificationSettings: {},
  users: {},
  billingData: {},
  exportsData: {},
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PROFILE_SETTINGS:
      return {
        ...state,
        isLoading: true,
        content: {},
        mem: {}
      };
    case FETCH_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        mem: payload.mem,
        notificationSettings: payload.notification_settings,
        users: payload.users,
        exportsData: payload.exportsData,
        billingData: payload.billingData
      };
    case FETCH_PROFILE_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        mem: {},
        error: payload
      };
    case SAVE_PROFILE_SETTINGS:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case SAVE_PROFILE_SETTINGS_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case TOGGLE_CHANGE_PASSWORD_POPUP:
      return {
        ...state,
        isChangePasswordPopupShowing: !state.isChangePasswordPopupShowing
      };
    case TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP:
      return {
        ...state,
        isDeleteAccountPopupShowing: !state.isDeleteAccountPopupShowing
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        isFormProcessing: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        isFormProcessing: true
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        isFormProcessing: true
      };
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case UPDATE_NOTIFICATION_SETTINGS_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case SHOW_USER_POPUP:
      return {
        ...state,
        showUserPopup: !state.showUserPopup
      };
    case SAVE_USER:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        users: payload.users
      };
    case SAVE_USER_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case DELETE_USER:
      return {
        ...state,
        isFormProcessing: true
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        users: payload.users
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case CANCEL_SUBSCRIPTION_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS:
      return {
        ...state,
        authToken: payload.authToken,
        isFormProcessing: true
      };
    case CANCEL_SUBSCRIPTION_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
