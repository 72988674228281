import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ToastContainer, toast } from "react-toastify";
import * as helpers from "../../../helpers/helpers";
import http from "../../../helpers/http";
import LoadingScreen from "../../common/LoadingScreen";
import MultiRangeSlider from "../multirangeslider/MultiRangeSlider";
import SearchResultsComponent from "./Result-search";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { FETCH_PROPERTIES_CONTENT_FAILED } from "../../../states/actions/actionTypes";
import { toggleResultView } from "../../../states/actions/resultView";
import usePreviousURLParams from "./prevurl";

const FiltersSearch = (props) => {
  const searchInputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [search_p, setSearchP] = useState("");
  const [searchQueryValues, setSearchQueryValues] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/");
  useEffect(() => {
    if (props?.search_keyword) {
      setSearchP(props?.search_keyword);
    }
  }, [props?.search_keyword]);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    // let previousURLParams = '';
    // const referrerURL = document.referrer;
    // if (referrerURL) {
    //   const url = new URL(referrerURL);
    //   previousURLParams = url?.pathname;
    // }

    // if (previousURLParams == '/saved-searches') {
    //   if (queryParams.get("geometry")) {
    //     props.setDrawnFeatures(queryParams.get("geometry"))
    //   }

    // }
    if (
      segments[1] !== "owner-property" &&
      segments[1] !== "owner-overview" &&
      segments[1] !== "property-details"
    ) {
      const allParams = {};
      for (const [key, value] of queryParams.entries()) {
        allParams[key] = value;
      }
      if (Object.keys(allParams).length > 0) {
        if (
          allParams?.sale_date_within !== undefined &&
          allParams?.sale_date_within !== null &&
          allParams?.sale_date_within !== ""
        ) {
          setSelectedOptionsWithin((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            sale_date_within: allParams?.sale_date_within,
          }));
        }
        if (
          allParams?.sale_date !== undefined &&
          allParams?.sale_date !== null &&
          allParams?.sale_date !== ""
        ) {
          setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            sale_date: allParams?.sale_date,
          }));
        }
        setSearchQueryValues(allParams);
        if (allParams?.search_query) {
          setSearchP(allParams?.search_query);
        }
        setIsLoadingform(true);
        searchApiReq(allParams);
      }
    }
  }, []);
  const property_details = props?.property_details
    ? props?.property_details
    : false;

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  const [sortValue, setSortValue] = useState("year");
  // useEffect(() => {
  //   if (property_details) {
  //     if (props?.propertyDetailsSortValue) {
  //       setSortValue(props?.propertyDetailsSortValue)
  //       filterSearchSubmitData("search_field", props?.propertyDetailsSortValue)
  //     }
  //   }
  // }, [property_details, props?.propertyDetailsSortValue]);

  const [includeLabels, setIncludeLabels] = useState([]);
  const [excludeLabels, setExcludeLabels] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResultsData, setSearchResultsData] = useState(null);
  const [, setTotalPropertiesRecords] = useState(null);
  const [propertyType, setPropertyType] = useState(false);
  const [propertySize, setPropertySize] = useState(false);
  const [recommended, setRecommended] = useState(false);
  const [myProperties, setMyProperties] = useState(false);
  const [moreFilters, setMoreFilters] = useState(false);
  const [selectedOption, setSelectedOptions] = useState({});
  const [selectedOptionWithin, setSelectedOptionsWithin] = useState({});
  const [multSelectOptions, setMultiSelectOptions] = useState({});
  const [isLoadingform, setIsLoadingform] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const popupRef1 = useRef();
  const searchButtonRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [propertyCatsCounts, setPropertyCatsCounts] = useState({
    commercial: 0,
    agricultural: 0,
    industrial: 0,
    residential: 0,
    public: 0,
    other: 0,
    vacant: 0,
  });
  const handleLabelIncludeChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setIncludeLabels((prevIds) => [...prevIds, checkboxValue]);
    } else {
      setIncludeLabels((prevIds) =>
        prevIds.filter((id) => id !== checkboxValue)
      );
    }
  };
  const handleLabelExcludeChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setExcludeLabels((prevIds) => [...prevIds, checkboxValue]);
    } else {
      setExcludeLabels((prevIds) =>
        prevIds.filter((id) => id !== checkboxValue)
      );
    }
  };
  const [ownerContactIncludes, setOwnerContactIncludes] = useState({
    phone_include: false,
    email_include: false,
    mailing_include: false,
  });
  // const [resultView, setResultView] = useState("list");

  // useEffect(() => {
  // }, [ownerContactIncludes]);

  const resultView = useSelector((state) => state.resultView.resultView);
  const [userLabels, setUserLabels] = useState([]);
  const fetchUserLabels = () => {
    http
      .post("get-user-label-properties", "")
      .then(({ data }) => {
        setUserLabels(data.labels);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    fetchUserLabels();
  }, []);
  const navigate = useNavigate();

  const TogglePropertyType = () => {
    setPropertyType(!propertyType);
    if (propertyType) {
      // If the property size tab is already open, close it
      setPropertyType(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(true);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const TogglePropertySize = () => {
    if (propertySize) {
      // If the property size tab is already open, close it
      setPropertySize(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(true);
    }
  };
  const ToggleRecommended = () => {
    if (recommended) {
      // If the property size tab is already open, close it
      setRecommended(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(true);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const ToggleMyProperties = () => {
    if (myProperties) {
      // If the property size tab is already open, close it
      setMyProperties(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(true);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const ToggleMoreFilters = () => {
    setMoreFilters(!moreFilters);

    if (moreFilters) {
      // If the property size tab is already open, close it
      setMoreFilters(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(true);
      setPropertySize(false);
    }
  };

  const {
    ToggleHideSearch,
    hideSearch,
    Maptoggle,
    showMap,
    ownertoggle,
    setShowMap,
    search_keyword,
    searchRef,
  } = props;
  // useEffect(() => {
  //   if (search_keyword) {
  //     if (searchRef.current) {
  //       searchRef.current.click();
  //     }
  //   }
  // }, [search_keyword]);

  function handleClearForm(event, formId) {
    event.preventDefault();
    // const form = document.getElementById(formId);

    // if (!form.checkValidity()) {
    //   return;
    // }
    // const radioButtons = form.querySelectorAll("input[type='radio']");
    // radioButtons.forEach((radioButton) => {
    //   radioButton.checked = false;
    // });
    // const inputstexts = form.querySelectorAll("input[type='text']");
    // inputstexts.forEach((inputstexts) => {
    //   inputstexts.value = "";
    // });

    // form.reset();
    // setFormData({});
    window.location.href = "/search-property";
  }

  const handleInputChange = (event, formName) => {
    const { name, value, type, checked } = event.target;
    //setSelectedOption(value);
    const label = event.target.closest("label");
    if (!label) {
      return;
    }
    const id = label.innerText.trim().replace(/\s/g, "_");

    let inputValue;
    if (type === "radio") {
      inputValue = checked ? value : "";
    } else if (type === "checkbox") {
      const previousValues = formData[name] || [];
      if (checked) {
        inputValue = [...previousValues, value];
      } else {
        inputValue = previousValues.filter((val) => val !== value);
      }
    } else {
      inputValue = value;
    }

    setFormData((prevState) => ({ ...prevState, [name]: inputValue }));
    setSelectedIds((prevState) => [...prevState, id]);

    const selectedValues = Object.entries(formData)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${value}`);
    const data = selectedValues.join("&");

    // update the filter data for the current form
    const filterData = { [id]: selectedValues };
    setFilters((prevState) => ({ ...prevState, [formName]: filterData }));
    // setFormValues(prevValues => ({ ...prevValues, [name]: inputValue }));
  };

  const handleRadioChange = (event, groupName, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [groupName]: option,
    }));
  };
  const handleRadioWithinChange = (event, groupName, option) => {
    setSelectedOptionsWithin((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [groupName]: option,
    }));
  };
  const handleMultiSelectChange = (name, min, max) => {
    let arr = [min, max];
    setMultiSelectOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: arr,
    }));
  };

  const handleSetOwnerContactIncludes = (event) => {
    event.preventDefault();
    // setOwnerContactIncludes((prevState) => {
    //   if (prevState.includes(option))
    //     return prevState.filter(function (item) {
    //       return item !== option;
    //     });
    //   return [...prevState, option];
    // });
    setOwnerContactIncludes({
      ...ownerContactIncludes,
      [event.target.value]: event.target.checked,
    });
  };
  const handleSetOwnerContactIncludesLabel = (event, name, value) => {
    event.preventDefault();
    // setOwnerContactIncludes((prevState) => {
    //   if (prevState.includes(option))
    //     return prevState.filter(function (item) {
    //       return item !== option;
    //     });
    //   return [...prevState, option];
    // });
    setOwnerContactIncludes({
      ...ownerContactIncludes,
      [name]: value,
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event, "search_field");
    }
  };

  const hanldeSearchFieldSubmit = (event) => {
    event.preventDefault();
    setPage(1);
    handleSubmit(event, "search_field", null, 1, true);
  };
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    "Address, city, state, postal code"
  );

  const hanldeSearchSelectFieldSubmit = (event) => {
    event.preventDefault();
    const searchInput = document.getElementsByName("serch")[0];
    if (event.target.value === "city_state") {
      setSearchPlaceHolder("City, State");
    }
    if (event.target.value === "zipcode") {
      setSearchPlaceHolder("Zipcode");
    }
    if (event.target.value === "owner_name") {
      setSearchPlaceHolder("Property Owner");
    }
    if (event.target.value === "address") {
      setSearchPlaceHolder("Address, city, state, postal code");
    }
    // if (searchInputRef.current) {
    //   searchInputRef.current.value = "";
    // }
    // setSearchP("");
  };

  const getQueryFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("query");
    return query;
  };

  const handlePageClick = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const geometry = urlParams.get("geometry");
    if (document.getElementById("outer_properties")) {
      window.scroll({
        top: document.getElementById("outer_properties").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }


    let formName1 = localStorage.getItem("setPreviousForm");
    setPage(event.selected + 1);
    urlParams.set('page', event.selected + 1);
    urlParams.set('geometry', geometry);

    // Navigate to the new URL with the updated parameters
    navigate(
      `/search-property?${urlParams.toString()}`
    );

    setIsLoadingform(true);
    setFormValues({});

    const form = document.querySelector(`#${formName1}`);
    // if (!form?.checkValidity()) {
    //   // Form is invalid, handle error
    //   return;
    // }
    // const forms = document.getElementsByTagName("form");

    const forms = document.getElementsByTagName("form");

    let values = {};
    const query = getQueryFromURL();
    // Set the query value in the values object
    if (query) {
      values["query"] = query;
    }

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const inputs = form.getElementsByTagName("input");
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value, type, checked } = select;
        values[name] = value;
      }
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (type === "checkbox") {
          if (checked) {
            if (values[name]) {
              const existingValues = values[name]
                .split(",")
                .filter((val) => val.trim() !== "");

              if (!existingValues.includes(value)) {
                existingValues.push(value);
              }

              values[name] = existingValues.join(",");
            } else {
              values[name] = value;
            }
          }
        } else if (type === "radio") {
          if (checked) {
            values[name] = value;
          }
        } else if (value !== "") {
          values[name] = value;
        }
      }
    }
    if (
      geometry !== null &&
      geometry !== "null" &&
      geometry !== "" &&
      geometry !== undefined
    ) {
      values["geometry"] = geometry;
    }
    setFormValues(values);

    const hasValues =
      Object.values(values).some((value) => value !== "") ||
      props.drawnFeatures;
    if (!hasValues) {
      setIsLoadingform(false);
      toast.error("Please fill in the form values.");

      return false;
    }
    for (let key in selectedOption) {
      if (selectedOption.hasOwnProperty(key)) {
        values[key] = selectedOption[key];
      }
    }
    for (let key in selectedOptionWithin) {
      if (selectedOptionWithin.hasOwnProperty(key)) {
        values[key] = selectedOptionWithin[key];
      }
    }
    for (let key in multSelectOptions) {
      if (multSelectOptions.hasOwnProperty(key)) {
        values[key] = multSelectOptions[key];
      }
    }

    values = { ...values, page: event.selected + 1 };

    http
      .post("/getsearchproperties", helpers.doObjToFormData(values))
      .then(({ data }) => {
        setIsLoadingform(false);
        renderMap(data.parcel_ids, values);
        const properties_list = JSON.stringify(data);
        localStorage.setItem("listingData", properties_list);
        localStorage.setItem("currentFilters", JSON.stringify(values));
        setSearchResultsData([]);
        setSearchResultsData(JSON.stringify(data));
        setTotalPropertiesRecords(Number(data.totalRecords));

        setShowSearchResults(true);
        ownertoggle(true);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROPERTIES_CONTENT_FAILED,
          payload: error,
        });
      });
  };

  const handleSubmit = (
    event,
    formName1,
    sortValueFilter = null,
    searchPage = null,
    search_text_box = false
  ) => {
    event.preventDefault();
    filterSearchSubmitData(formName1, sortValueFilter, searchPage, "no", search_text_box);
  };
  const filterSearchSubmitData = (
    formName1,
    sortValueFilter = null,
    searchPage = null,
    search_nearby = "",
    search_text_box = false
  ) => {
    let values = {};

    if (formName1 == "search_field") {
      if (props.setDrawnFeatures) {
        props.setDrawnFeatures(null);
      }

      localStorage.setItem('drawnFeatures', null);
    }
    if (formName1 == "building_and_lot") {
      const form = document.querySelector(`#${formName1}`);
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value } = select;
        values[name] = value;
      }
      const inputs = form.getElementsByTagName("input");
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (name === "arealotsf_from") {
          const numericValue = parseFloat(value);
          if (value !== "" && value !== null && value !== undefined) {
            if (
              !isNaN(numericValue) &&
              numericValue >= 100 &&
              numericValue <= 100000
            ) {
            } else {
              // Handle invalid input (for example, you can set it to an empty string)
              // Optionally, you can display an error message to the user
              toast.error(
                "Please enter a value between 100 and 100000 for Lot size From"
              );
              return;
            }
          }
        } else if (name === "arealotsf_until") {
          const numericValue = parseFloat(value);
          if (value !== "" && value !== null && value !== undefined) {
            if (
              !isNaN(numericValue) &&
              numericValue >= 100 &&
              numericValue <= 100000
            ) {
            } else {
              // Handle invalid input (for example, you can set it to an empty string)

              // Optionally, you can display an error message to the user
              toast.error(
                "Please enter a value between 100 and 100000 for Lot size Until"
              );
              return;
            }
          }
        }
      }
    }
    // setResultView("list");
    dispatch(toggleResultView("table"));
    // setIsLoadingform(true);
    setFormValues({});

    localStorage.setItem("setPreviousForm", formName1);
    const form = document.querySelector(`#${formName1}`);

    if (!form?.checkValidity()) {
      // Form is invalid, handle error
      return;
    }
    const forms = document.getElementsByTagName("form");

    const query = getQueryFromURL();
    // Set the query value in the values object
    if (query) {
      values["query"] = query;
    }

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const inputs = form.getElementsByTagName("input");
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value, type, checked } = select;
        values[name] = value;
      }
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (type === "checkbox") {
          if (checked) {
            if (values[name]) {
              const existingValues = values[name]
                .split(",")
                .filter((val) => val.trim() !== "");

              if (!existingValues.includes(value)) {
                existingValues.push(value);
              }

              values[name] = existingValues.join(",");
            } else {
              values[name] = value;
            }
          }
        } else if (type === "radio") {
          if (checked) {
            values[name] = value;
          }
        } else if (value !== "") {
          values[name] = value;
        }
      }
    }

    setFormValues(values);
    const hasValues =
      Object.values(values).some((value) => value !== "") ||
      props.drawnFeatures;
    if (!hasValues) {
      setIsLoadingform(false);
      toast.error("Please fill in the form values.");

      return false;
    }
    for (let key in selectedOption) {
      if (selectedOption.hasOwnProperty(key)) {
        values[key] = selectedOption[key];
      }
    }
    for (let key in selectedOptionWithin) {
      if (selectedOptionWithin.hasOwnProperty(key)) {
        values[key] = selectedOptionWithin[key];
      }
    }
    for (let key in multSelectOptions) {
      if (multSelectOptions.hasOwnProperty(key)) {
        values[key] = multSelectOptions[key];
      }
    }
    searchApiReq(values, sortValueFilter, searchPage, search_nearby, search_text_box);
  };
  const updateParams = (newParams, search_nearby = "") => {
    setSearchQueryValues(newParams);
    const updatedParams = new URLSearchParams(queryParams.entries());
    if (search_nearby == "no") {
      updatedParams.delete("search_nearby");
    }
    Object.entries(newParams).forEach(([key, value]) => {
      updatedParams.set(key, value);
    });

    window.history.replaceState(null, "", `?${updatedParams.toString()}`);
  };
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const searchApiReq = (
    values,
    sortValueFilter = null,
    searchPage = null,
    search_nearby = null,
    search_text_box = false
  ) => {
    const geometry_polygon = getUrlParameter("geometry");
    let geometry = props.drawnFeatures || geometry_polygon || null

    if (search_text_box) {
      geometry = null
    }
    values = {
      ...values,
      page:
        searchPage !== null && searchPage !== undefined && searchPage !== ""
          ? searchPage
          : page,
      geometry: geometry,
      sort: sortValueFilter !== null ? sortValueFilter : sortValue,
      label_include: includeLabels,
      label_exclude: excludeLabels,
    };
    if (search_nearby !== null && search_nearby !== "no") {
      values["search_nearby"] = search_nearby;
    }

    if (location?.pathname !== "/search-property") {
      const queryString = new URLSearchParams(values).toString();

      const url = `/search-property?${queryString}`;
      window.location.href = url;
    }
    updateParams(values, search_nearby);
    setIsLoadingform(true);

    localStorage.setItem("drawnFeatures", props.drawnFeatures || null);
    http
      .post("/getsearchproperties", helpers.doObjToFormData(values))
      .then(({ data }) => {
        setIsLoadingform(false);
        if (data.status == 2) {
          toast.error(data.msg);
          return false;
        }
        setShowSearchResults(true);
        // renderMap(data.parcel_ids, values);
        const properties_list = JSON.stringify(data);
        localStorage.setItem("listingData", properties_list);
        localStorage.setItem("currentFilters", JSON.stringify(values));
        setSearchResultsData([]);
        setSearchResultsData(JSON.stringify(data));
        setTotalPropertiesRecords(Number(data.totalRecords));

        // setShowMap(false);

        setShowSearchResults(true);
        Maptoggle(false);
        ownertoggle(true);

        if (!window.location.pathname.includes("search-property")) {
          const newPath = "/search-property";
          // window.history.pushState(null, '', newPath);
          navigate("/search-property");
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROPERTIES_CONTENT_FAILED,
          payload: error,
        });
      });
  };

  useEffect(() => {
    if (
      props.drawnFeatures &&
      localStorage.getItem("drawnFeatures") !== props.drawnFeatures
    ) {
      filterSearchSubmitData("search_field");
    }
  }, [props.drawnFeatures]);

  // ...

  // useEffect(() => {
  //   const query = getQueryFromURL();

  //   if (query && !submitted) {
  //     const searchInput = document.getElementsByName("serch")[0];
  //     if (searchInput) {
  //       searchInput.value = query; // Assign query value to the "search" input field
  //     }
  //     const mockEvent = {
  //       preventDefault: () => { },
  //       target: {
  //         id: "search_field",
  //       },
  //     };

  //     handleSubmit(mockEvent, "search_field");
  //     setSubmitted(true);
  //     const url = new URL(window.location);
  //     url.searchParams.delete("query");
  //     window.history.pushState({}, "", url);
  //   }
  // }, []);

  const handleOutsideClick1 = (e) => {
    if (!popupRef1.current.contains(e.target)) {
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick1);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, []);

  // useEffect(() => {
  //   if (search_p !== "" && localStorage.getItem("search-nearby")) {
  //     localStorage.removeItem("search-nearby");
  //     const mockEvent = {
  //       preventDefault: () => { },
  //       target: {
  //         id: "search_field",
  //       },
  //     };
  //     // handleSubmit(mockEvent, "search_field");
  //     // if (searchButtonRef.current)
  //     // searchButtonRef.current.click();
  //     handleSubmit(mockEvent, "search_field");
  //     setSubmitted(true);
  //   }
  // }, []);

  useEffect(() => {
    //setShowSearchResults(true);
    // Assign values back to the form whenever showSearchResults is true
    if (showSearchResults) {
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);

      for (const name in formValues) {
        const value = formValues[name];
        const inputs = document.getElementsByName(name);

        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];

          if (input.type === "checkbox") {
            input.checked = value.includes(input.value);
          } else if (input.type === "radio") {
            input.checked = input.value === value;
          } else {
            input.value = value;
          }
        }
      }
    }
  }, [formValues, showSearchResults]);

  useEffect(() => { }, [showSearchResults]);

  useEffect(() => {
    http
      .post("getFilterCounts", "")
      .then(({ data }) => {
        setPropertyCatsCounts({
          commercial: data.counts.Commercial.toLocaleString(),
          agricultural: data.counts["AGRICULTURE / FARMING"].toLocaleString(),
          industrial: data.counts["Idustrial"].toLocaleString(),
          residential: data.counts["Residential"].toLocaleString(),
          public: data.counts["PUBLIC WORKS"].toLocaleString(),
          other: data.counts["OTHER / UNKNOWN"].toLocaleString(),
          vacant: data.counts["VACANT LAND"].toLocaleString(),
        });
        // setCommercialCount(data.counts.Commercial.toLocaleString());
        // setAGRICULTURECOunt(
        //   data.counts["AGRICULTURE / FARMING"].toLocaleString()
        // );
        // setINDUSTRIALCount(data.counts["Industrial"].toLocaleString());
        // setResidentialCount(data.counts["Residential"].toLocaleString());
        // setPUBLICount(data.counts["PUBLIC WORKS"].toLocaleString());
        // setOTHERCount(data.counts["OTHER / UNKNOWN"].toLocaleString());
        // setVACANTCOUNT(data.counts["VACANT LAND"].toLocaleString());
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // Render Map base of Parcel Id's

  const renderMap = (parcel_ids, filtervalues) => { };

  // setPropertyType(false);
  // setRecommended(false);
  // setMyProperties(false);
  // setMoreFilters(false);
  // setPropertySize(false);

  const propertyTypeDrop = useRef();
  const recommendedDrop = useRef();
  const myPropertiesDrop = useRef();
  const propertySizeDrop = useRef();
  const moreFiltersDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !propertyTypeDrop?.current?.contains(e.target) &&
        !myPropertiesDrop?.current?.contains(e.target) &&
        !propertySizeDrop?.current?.contains(e.target) &&
        !moreFiltersDrop?.current?.contains(e.target) &&
        !recommendedDrop?.current?.contains(e.target)
      ) {
        setPropertyType(false);
        setRecommended(false);
        setMyProperties(false);
        setMoreFilters(false);
        setPropertySize(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  // useEffect(() => {
  // }, [searchResultsData]);
  const yearOptions = helpers.getYearOptions();
  const lotSizeRange = helpers.getNumbersRange();
  let property_type = searchQueryValues?.property_type;
  if (property_type) {
    if (property_type?.includes(",")) {
      property_type = property_type?.split(",");
    }
  }
  return (
    <>
      {isLoadingform ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />

          <div
            ref={popupRef1}
            className={
              hideSearch ? "filter_sec hide_filter_sec_new" : "filter_sec"
            }
          >
            <div className="contain-fluid">
              <div className="filter_nav">
                <div
                  className={
                    hideSearch ? "colL show_search_btn_active" : "colL"
                  }
                >
                  <form id="search_field">
                    <div className="search_filter_first cell_search_flex_field">
                      <div className="seach_field after_search">
                        <input
                          type="text"
                          name="search_query"
                          className="input"
                          onKeyDown={handleKeyDown}
                          placeholder={searchPlaceHolder}
                          id="search_field_id"
                          defaultValue={searchQueryValues?.search_query}
                          ref={searchInputRef}
                        />
                        <select
                          name="search_type"
                          className="input"
                          onChange={hanldeSearchSelectFieldSubmit}
                        >
                          <option
                            value="address"
                            selected={
                              searchQueryValues?.search_type === "address"
                                ? true
                                : false
                            }
                          >
                            Address
                          </option>
                          <option
                            value="city_state"
                            selected={
                              searchQueryValues?.search_type === "city_state"
                                ? true
                                : false
                            }
                          >
                            City, State
                          </option>
                          <option
                            value="zipcode"
                            selected={
                              searchQueryValues?.search_type === "zipcode"
                                ? true
                                : false
                            }
                          >
                            Zipcode
                          </option>
                          <option
                            value="owner_name"
                            selected={
                              searchQueryValues?.search_type === "owner_name"
                                ? true
                                : false
                            }
                          >
                            Property Owner
                          </option>
                        </select>
                        <button
                          type="button"
                          ref={searchButtonRef}
                          onClick={hanldeSearchFieldSubmit}
                        >
                          <img src="/images/search_green.svg" alt="" />
                        </button>
                      </div>
                      <button
                        className="hide_cell_filter"
                        onClick={ToggleHideSearch}
                        type="button"
                      >
                        <img src="/images/eye_circle_hide.svg" alt="" />
                      </button>
                    </div>
                  </form>
                  <div className="outer_oth">
                    <div className="oth_filter">
                      <div className="drop_filter" ref={propertyTypeDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={TogglePropertyType}
                        >
                          Property type
                        </div>
                        <div
                          className={
                            propertyType ? "drop_cnt active" : "drop_cnt"
                          }
                        >
                          <div className="head_filter">
                            <p className="dark_text">Popular categories</p>
                            {/* <p className="dim_text">
                              <a href="">See all property types</a>
                            </p> */}
                          </div>
                          <form
                            id="property_type"
                            onSubmit={(event) =>
                              handleSubmit(event, "property_type")
                            }
                          >
                            <div className="form-row row">
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="Commercial"
                                    value="Commercial"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "Commercial"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="Commercial">
                                    <span>Commercial</span>
                                    <span>
                                      {propertyCatsCounts?.commercial}
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="OTHER/UNKNOWN"
                                    value="OTHER / UNKNOWN"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "OTHER / UNKNOWN"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="OTHER/UNKNOWN">
                                    <span>Other / Unknown</span>
                                    <span>{propertyCatsCounts?.other}</span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="INDUSTRIAL"
                                    value="Idustrial"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "Idustrial"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="INDUSTRIAL">
                                    <span>Industrial</span>
                                    <span>
                                      {propertyCatsCounts?.industrial}
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                            <div className="lbl_btn">
                              <input
                                type="checkbox"
                                name="property_type"
                                id="RESIDENTIAL"
                                value="RESIDENTIAL"
                                onChange={(event) => handleInputChange(event, 'property_type')}
                              />
                              <label htmlFor="hospitality">
                                <span>RESIDENTIAL</span>
                                <span>543,56</span>
                              </label>
                            </div>
                          </div> */}
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="AGRICULTURE / FARMING"
                                    value="AGRICULTURE / FARMING"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "AGRICULTURE / FARMING"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="AGRICULTURE / FARMING">
                                    <span>Agriculture / Farming</span>
                                    <span>
                                      {propertyCatsCounts?.agricultural}
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="Residential"
                                    value="Residential"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "Residential"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="Residential">
                                    <span>Residential</span>
                                    <span>
                                      {propertyCatsCounts?.residential}
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="VACANTLAND"
                                    value="VACANT LAND"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "VACANT LAND"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="VACANTLAND">
                                    <span>Vacant Land</span>
                                    <span>{propertyCatsCounts?.vacant}</span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="lbl_btn">
                                  <input
                                    type="checkbox"
                                    name="property_type"
                                    id="PUBLICWORKS"
                                    value="PUBLIC WORKS"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_type")
                                    }
                                    defaultChecked={
                                      property_type && property_type?.length > 0
                                        ? property_type?.indexOf(
                                          "PUBLIC WORKS"
                                        ) !== -1
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                  <label htmlFor="PUBLICWORKS">
                                    <span>Public Works</span>
                                    <span>{propertyCatsCounts?.public}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "property_type")
                                }
                              >
                                Clear
                              </button>
                              <a
                                href="javscript:viod(0)"
                                onClick={(event) =>
                                  handleSubmit(event, "property_type")
                                }
                                className="site_btn"
                              >
                                Apply
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={propertySizeDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={TogglePropertySize}
                        >
                          Size
                        </div>
                        <div
                          className={
                            propertySize ? "drop_cnt active" : "drop_cnt"
                          }
                        >
                          <form
                            id="property_size"
                            onSubmit={(event) =>
                              handleSubmit(event, "property_size")
                            }
                            className="half_flex_form"
                          >
                            <div className="form_blk">
                              <h6>Total units</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_unites"
                                    id="min_unites"
                                    className="input"
                                    placeholder="Min Units"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={searchQueryValues?.min_unites}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_unites"
                                    id="max_unites"
                                    className="input"
                                    placeholder="Max Units"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={searchQueryValues?.max_unites}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Building area (SF)</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_sf"
                                    id="min_sf"
                                    className="input"
                                    placeholder="Min (SF)"
                                    onChange={(event) =>
                                      handleInputChange(event, "min_sf")
                                    }
                                    defaultValue={searchQueryValues?.min_sf}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_sf"
                                    id="max_sf"
                                    className="input"
                                    placeholder="Max (SF)"
                                    onChange={(event) =>
                                      handleInputChange(event, "max_sf")
                                    }
                                    defaultValue={searchQueryValues?.max_sf}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Lot Size</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_lotsize"
                                    id="min_lotsize"
                                    className="input"
                                    placeholder="Min Value"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={
                                      searchQueryValues?.min_lotsize
                                    }
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_lotsize"
                                    id="max_lotsize"
                                    className="input"
                                    placeholder="Max Value"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={
                                      searchQueryValues?.max_lotsize
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "property_size")
                                }
                              >
                                Clear
                              </button>
                              <button type="submit" className="site_btn">
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={recommendedDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={ToggleRecommended}
                        >
                          Recommended
                        </div>
                        <div
                          className={
                            recommended
                              ? "drop_cnt wide_sm active"
                              : "drop_cnt wide_sm"
                          }
                        >
                          <form
                            id="recommended"
                            onSubmit={(event) =>
                              handleSubmit(event, "recommended")
                            }
                            className="half_flex_form"
                          >
                            <div className="form_blk">
                              <h6>Year built</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="year_build"
                                    id="year_build"
                                    className="input"
                                    placeholder="Year built From"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={searchQueryValues?.year_build}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="year_build_until"
                                    id="year_build_until"
                                    className="input"
                                    placeholder="Year built Until"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.year_build_until
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Properties in portfolio</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="properties_in_portfolio_min"
                                    id="properties_in_portfolio_min"
                                    className="input"
                                    placeholder="Min"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.properties_in_portfolio_min
                                    }
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="properties_in_portfolio_max"
                                    id="properties_in_portfolio_max"
                                    className="input"
                                    placeholder="Max"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.properties_in_portfolio_max
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="occupaid"
                                  id="occupaid"
                                  value="occupaid"
                                  onChange={(event) =>
                                    handleInputChange(event, "recommended")
                                  }
                                  defaultChecked={
                                    searchQueryValues?.occupaid ? true : false
                                  }
                                />

                                <label
                                  htmlFor="occupaid"
                                  className="dim_dark_text f_s_15"
                                >
                                  Owner Occupied Property
                                </label>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_phone_number"
                                  id="recommended_owner_phone_number"
                                  value="recommended_owner_phone_number"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_phone_number"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_phone_number
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_phone_number"
                                  className="dim_dark_text f_s_15"
                                >
                                  Has owner phone number
                                </label>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_email_address"
                                  id="recommended_owner_email_address"
                                  value="recommended_owner_email_address"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_email_address"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_email_address
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_email_address"
                                  className="dim_dark_text f_s_15"
                                >
                                  Has owner email address
                                </label>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_property_sq_ft"
                                  id="recommended_owner_property_sq_ft"
                                  value="recommended_owner_property_sq_ft"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_property_sq_ft"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_property_sq_ft
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_property_sq_ft"
                                  className="dim_dark_text f_s_15"
                                >
                                  Property over 5,000 square feet
                                </label>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_portfolio"
                                  id="recommended_owner_portfolio"
                                  value="recommended_owner_portfolio"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_portfolio"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_portfolio
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_portfolio"
                                  className="dim_dark_text f_s_15"
                                >
                                  Portfolio with over 5 properties
                                </label>
                              </div>
                            </div>

                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "recommended")
                                }
                              >
                                Clear
                              </button>
                              <a
                                href="javscript:viod(0)"
                                onClick={(event) =>
                                  handleSubmit(event, "property_size")
                                }
                                className="site_btn"
                              >
                                Apply
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={myPropertiesDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={ToggleMyProperties}
                        >
                          My properties
                        </div>
                        <div
                          className={
                            myProperties
                              ? "drop_cnt wide_sm right_drop_cnt active"
                              : "drop_cnt wide_sm right_drop_cnt"
                          }
                        >
                          <form
                            id="my_properties"
                            onSubmit={(event) =>
                              handleSubmit(event, "my_properties")
                            }
                          >
                            <div className="form_blk">
                              <h6 className="f_size_16">Exported properties</h6>
                              <div className="boolean_flex">
                                <div className="lbl_btn">
                                  <input
                                    type="radio"
                                    name="exported_properties"
                                    id="include"
                                    value="include"
                                    defaultChecked={
                                      selectedOption["include"] === "include"
                                    }
                                    onChange={(event) =>
                                      handleRadioChange(
                                        event,
                                        "exported_properties",
                                        "include"
                                      )
                                    }
                                    className="hidden"
                                  />
                                  <label
                                    htmlFor="include"
                                    className="site_btn blank yellow_blank main_color"
                                    style={{
                                      backgroundColor:
                                        selectedOption[
                                          "exported_properties"
                                        ] === "include"
                                          ? "#67773F"
                                          : "",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color:
                                          selectedOption[
                                            "exported_properties"
                                          ] === "include"
                                            ? "white"
                                            : "black",
                                      }}
                                    >
                                      Include
                                    </span>
                                  </label>
                                </div>
                                <div className="lbl_btn">
                                  <input
                                    type="radio"
                                    name="exported_properties"
                                    id="exclude"
                                    value="exclude"
                                    checked={
                                      selectedOption["include"] === "exclude"
                                    }
                                    onChange={(event) =>
                                      handleRadioChange(
                                        event,
                                        "exported_properties",
                                        "exclude"
                                      )
                                    }
                                    className="hidden"
                                  />
                                  <label
                                    htmlFor="exclude"
                                    className="site_btn blank yellow_blank main_color"
                                    style={{
                                      backgroundColor:
                                        selectedOption[
                                          "exported_properties"
                                        ] === "exclude"
                                          ? "#67773F"
                                          : "",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color:
                                          selectedOption[
                                            "exported_properties"
                                          ] === "exclude"
                                            ? "white"
                                            : "black",
                                      }}
                                    >
                                      Exclude
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <h6 className="f_s_14 f_dim_light">Labels</h6>
                              <input
                                type="text"
                                className="input"
                                name="labels"
                                placeholder="Search for label"
                              />
                            </div>
                            <div className="form_blk margin-top-neg lbl_table_search">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Labels</th>
                                    <th>Includes</th>
                                    <th>Exclude</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userLabels.map((label) => (
                                    <tr>
                                      <td
                                        className="lbl_dot blue_dot"
                                        style={{
                                          backgroundColor: label.label_color,
                                        }}
                                      >
                                        {label.label_name}
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name=""
                                          defaultValue={label.label_name}
                                          onChange={handleLabelIncludeChange}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          defaultValue={label.label_name}
                                          onChange={handleLabelExcludeChange}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "my_properties")
                                }
                              >
                                Clear
                              </button>
                              <button type="submit" className="site_btn">
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="drop_filter mobile_filter_advance"
                        ref={moreFiltersDrop}
                      >
                        <button
                          className="site_btn yellow_blank blank"
                          onClick={ToggleMoreFilters}
                        >
                          <img src="/images/plus_icon_dark.svg" alt="" />
                          <img
                            src="/images/ad_filter.svg"
                            alt=""
                            className="show_cell_advance_icon"
                          />{" "}
                          <span>More filters</span>
                        </button>
                        <div
                          className={
                            moreFilters
                              ? "drop_cnt wide_lg active"
                              : "drop_cnt wide_lg"
                          }
                        >
                          <div className="more_filter_drop tabs_filter">
                            <div className="sub_filter">
                              <div className="tabs_main">
                                <Tabs>
                                  <TabList>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Property type
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Building & Lot
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Owner
                                      </button>
                                    </Tab>
                                    {/* <Tab>
                                  <button className="site_btn blank">
                                    Occupants
                                  </button>
                                </Tab> */}
                                    <Tab>
                                      <button className="site_btn blank">
                                        Sales
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Debt
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Distressed
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        Tax
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button className="site_btn blank">
                                        My Properties
                                      </button>
                                    </Tab>
                                  </TabList>
                                  <TabPanel>
                                    <Tabs>
                                      <TabPanel>
                                        <form
                                          id="more_filters"
                                          onSubmit={(event) =>
                                            handleSubmit(event, "property_type")
                                          }
                                        >
                                          <div className="check_flex flex">
                                            <div className="col">
                                              <div className="form-row row">
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="Commercial"
                                                      value="Commercial"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "Commercial"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="Commercial">
                                                      <span>Commercial</span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.commercial
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="OTHER/UNKNOWN"
                                                      value="OTHER / UNKNOWN"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "OTHER / UNKNOWN"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="OTHER/UNKNOWN">
                                                      <span>
                                                        Other / Unknown
                                                      </span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.other
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="INDUSTRIAL"
                                                      value="Idustrial"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "Idustrial"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="INDUSTRIAL">
                                                      <span>Industrial</span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.industrial
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="AGRICULTURE / FARMING"
                                                      value="AGRICULTURE / FARMING"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "AGRICULTURE / FARMING"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="AGRICULTURE / FARMING">
                                                      <span>
                                                        Agriculture / Farming
                                                      </span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.agricultural
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="Residential"
                                                      value="Residential"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "Residential"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="Residential">
                                                      <span>Residential</span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.residential
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="VACANTLAND"
                                                      value="VACANT LAND"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "VACANT LAND"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="VACANTLAND">
                                                      <span>Vacant Land</span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.vacant
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="property_type"
                                                      id="PUBLICWORKS"
                                                      value="PUBLIC WORKS"
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          "property_type"
                                                        )
                                                      }
                                                      defaultChecked={
                                                        property_type &&
                                                          property_type?.length >
                                                          0
                                                          ? property_type?.indexOf(
                                                            "PUBLIC WORKS"
                                                          ) !== -1
                                                            ? true
                                                            : false
                                                          : false
                                                      }
                                                    />
                                                    <label htmlFor="PUBLICWORKS">
                                                      <span>Public Works</span>
                                                      <span>
                                                        {
                                                          propertyCatsCounts?.public
                                                        }
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <ul hidden>
                                                <li>
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="commecial"
                                                      value="Commercial"
                                                      id="ch_1"
                                                    />
                                                    <label htmlFor="ch_1">
                                                      <span>
                                                        Commercial General/misc.
                                                      </span>
                                                      <span>55,543,56</span>
                                                    </label>
                                                  </div>
                                                  <ul>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_1_1"
                                                        />
                                                        <label htmlFor="ch_1_1">
                                                          <span>
                                                            Commercial (General)
                                                          </span>
                                                          <span>4,43,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          value="Commercial"
                                                          id="ch_1_2"
                                                        />
                                                        <label htmlFor="ch_1_2">
                                                          <span>
                                                            Commercial
                                                            Condeminium
                                                          </span>
                                                          <span>10,2,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_1_3"
                                                        />
                                                        <label htmlFor="ch_1_3">
                                                          <span>
                                                            Self-Storage
                                                          </span>
                                                          <span>3,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </li>
                                                <li>
                                                  <div className="lbl_btn">
                                                    <input
                                                      type="checkbox"
                                                      name="commecial"
                                                      id="ch_2"
                                                    />
                                                    <label htmlFor="ch_2">
                                                      <span>Office</span>
                                                      <span>55,543,56</span>
                                                    </label>
                                                  </div>
                                                  <ul>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_2_1"
                                                        />
                                                        <label htmlFor="ch_2_1">
                                                          <span>
                                                            Bussiness park
                                                          </span>
                                                          <span>4,43,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_2_2"
                                                        />
                                                        <label htmlFor="ch_2_2">
                                                          <span>
                                                            Financial building
                                                          </span>
                                                          <span>10,2,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_2_3"
                                                        />
                                                        <label htmlFor="ch_2_3">
                                                          <span>
                                                            Medical condeminium
                                                          </span>
                                                          <span>3,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_2_4"
                                                        />
                                                        <label htmlFor="ch_2_4">
                                                          <span>
                                                            Office building
                                                          </span>
                                                          <span>3,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div className="lbl_btn">
                                                        <input
                                                          type="checkbox"
                                                          name="commecial"
                                                          id="ch_2_5"
                                                        />
                                                        <label htmlFor="ch_2_5">
                                                          <span>
                                                            Office condeminium
                                                          </span>
                                                          <span>3,56</span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="btn_blk text-right">
                                            <button
                                              className="site_btn blank yellow_blank"
                                              onClick={(event) =>
                                                handleClearForm(
                                                  event,
                                                  "property_type"
                                                )
                                              }
                                            >
                                              Clear
                                            </button>
                                            <a
                                              href="javscript:viod(0)"
                                              onClick={(event) =>
                                                handleSubmit(
                                                  event,
                                                  "property_type"
                                                )
                                              }
                                              className="site_btn"
                                            >
                                              Apply
                                            </a>
                                          </div>
                                        </form>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>text is here</p>
                                      </TabPanel>
                                    </Tabs>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="building_and_lot"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "building_and_lot")
                                      }
                                    >
                                      <div className="mix_half_flex flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <h6>Year built</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name="yearbuilt_from"
                                                  id="yearbuilt_from"
                                                  className="input"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                >
                                                  <option value="">From</option>
                                                  {yearOptions?.map(
                                                    (year, index) => {
                                                      return (
                                                        <option
                                                          value={year?.value}
                                                          key={index}
                                                          selected={
                                                            searchQueryValues?.yearbuilt_from ===
                                                              year?.value
                                                              ? true
                                                              : false
                                                          }
                                                        >
                                                          {year?.label}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name="yearbuilt_until"
                                                  id="yearbuilt_until"
                                                  className="input"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    until
                                                  </option>
                                                  {yearOptions?.map(
                                                    (year, index) => {
                                                      return (
                                                        <option
                                                          value={year?.value}
                                                          key={index}
                                                          selected={
                                                            searchQueryValues?.yearbuilt_until ===
                                                              year?.value
                                                              ? true
                                                              : false
                                                          }
                                                        >
                                                          {year?.label}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <h6>Lot size (SF)</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="arealotsf_from"
                                                  id="arealotsf_from"
                                                  className="input"
                                                  placeholder="Min (SF)"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.arealotsf_from
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="arealotsf_until"
                                                  id="arealotsf_until"
                                                  className="input"
                                                  placeholder="Max (SF)"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.arealotsf_until
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form nested_half_flex">
                                          <div className="form_blk">
                                            <h6>Zoning</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="zonedcodelocal"
                                                  className="input"
                                                  placeholder="Example: C2, I3, R5"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.zonedcodelocal
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn_blk text-right">
                                        <button
                                          className="site_btn blank yellow_blank"
                                          onClick={(event) =>
                                            handleClearForm(
                                              event,
                                              "building_and_lot"
                                            )
                                          }
                                        >
                                          Clear
                                        </button>
                                        <button
                                          type="submit"
                                          className="site_btn"
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </form>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="owners"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "owners")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner</h6>
                                              {/* <ReactTooltip
                                            id="owner-name"
                                            place="bottom"
                                            content="Write name of person or company."
                                          />
                                          <div className="info_span" data-tooltip-id="owner-name">
                                            <span className="ic_on">
                                              <img
                                                src="/images/info-circle.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div> */}
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="owner_name"
                                              placeholder="Person or Company name"
                                              defaultValue={
                                                searchQueryValues?.owner_name
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Properties in portfolio</h6>
                                              {/* <ReactTooltip
                                            id="properties-portfolio"
                                            place="bottom"
                                            content="Write values in range between minimum and maximum to find properties."
                                          />
                                          <div className="info_span" data-tooltip-id="properties-portfolio">
                                            <span className="ic_on">
                                              <img
                                                src="/images/info-circle.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div> */}
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name=""
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Min</option>
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name=""
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Max</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner type</h6>
                                              {/* <ReactTooltip
                                            id="owner-type"
                                            place="bottom"
                                            content="Owner type can be Company or individual so please select one among these."
                                          />
                                          <div className="info_span" data-tooltip-id="owner-type">
                                            <span className="ic_on">
                                              <img
                                                src="/images/info-circle.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div> */}
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="owner_type"
                                                  value="Company"
                                                  checked={
                                                    selectedOption[
                                                    "owner_type"
                                                    ] === "Company"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "owner_type",
                                                      "Company"
                                                    )
                                                  }
                                                  id="Company"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="Company"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] === "Company"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "owner_type"
                                                        ] === "Company"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Company
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="owner_type"
                                                  value="Individual"
                                                  checked={
                                                    selectedOption[
                                                    "owner_type"
                                                    ] === "Individual"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "owner_type",
                                                      "Individual"
                                                    )
                                                  }
                                                  id="Person"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="Person"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] === "Individual"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "owner_type"
                                                        ] === "Individual"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Individual
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner Occupied</h6>
                                              {/* <ReactTooltip
                                            id="owner-occupied"
                                            place="bottom"
                                            content="Find your own properties as an owner"
                                          />
                                          <div className="info_span" data-tooltip-id="owner-occupied">
                                            <span className="ic_on">
                                              <img
                                                src="/images/info-circle.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div> */}
                                            </div>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="Owner_occupied"
                                                value="Owner-Occupaid Property"
                                                checked={
                                                  selectedOption[
                                                  "owner_type"
                                                  ] ===
                                                  "Owner-Occupaid Property"
                                                }
                                                onChange={(event) =>
                                                  handleRadioChange(
                                                    event,
                                                    "owner_type",
                                                    "Owner-Occupaid Property"
                                                  )
                                                }
                                                id="Person"
                                              />
                                              <label
                                                for="Person"
                                                style={{
                                                  backgroundColor:
                                                    selectedOption[
                                                      "owner_type"
                                                    ] ===
                                                      "Owner-Occupaid Property"
                                                      ? "#67773F"
                                                      : "",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] ===
                                                        "Owner-Occupaid Property"
                                                        ? "white"
                                                        : "black",
                                                  }}
                                                >
                                                  {" "}
                                                  Owner Occupied Property
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner contact information</h6>
                                              {/* <ReactTooltip
                                            id="owner-contact"
                                            place="bottom"
                                            content="Find all owners who have stored their contact information either phone, email or mailing address"
                                          />
                                          <div className="info_span" data-tooltip-id="owner-contact">
                                            <span className="ic_on">
                                              <img
                                                src="/images/info-circle.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div> */}
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="checkbox"
                                                  name="owner_info"
                                                  value="phone_include"
                                                  className="hidden"
                                                  id="include_phone"
                                                  checked={
                                                    ownerContactIncludes[
                                                    "phone_include"
                                                    ]
                                                  }
                                                // onClick={
                                                //   handleSetOwnerContactIncludes
                                                // }
                                                // className="hidden"
                                                />{" "}
                                                <label
                                                  htmlFor="include_phone"
                                                  className={
                                                    ownerContactIncludes[
                                                      "phone_include"
                                                    ]
                                                      ? "site_btn blank yellow_blank main_color active"
                                                      : "site_btn blank yellow_blank main_color"
                                                  }
                                                  onClick={(e) =>
                                                    handleSetOwnerContactIncludesLabel(
                                                      e,
                                                      "phone_include",
                                                      !ownerContactIncludes[
                                                      "phone_include"
                                                      ]
                                                    )
                                                  }
                                                >
                                                  Include Phone
                                                </label>
                                                {/* <label
                                              for="phone_include"
                                              className="site_btn blank yellow_blank main_color"
                                              style={{
                                                backgroundColor:
                                                  ownerContactIncludes[
                                                    "phone_include"
                                                  ]
                                                    ? "#67773F"
                                                    : ""
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: ownerContactIncludes[
                                                    "phone_include"
                                                  ]
                                                    ? "white"
                                                    : "black"
                                                }}
                                              >
                                                Include phone number
                                              </span>
                                            </label> */}
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="checkbox"
                                                  name="owner_info"
                                                  value="email_include"
                                                  className="hidden"
                                                  checked={
                                                    ownerContactIncludes[
                                                    "email_include"
                                                    ]
                                                  }
                                                  // onChange={
                                                  //   handleSetOwnerContactIncludes
                                                  // }
                                                  id="email_include"
                                                // className="hidden"
                                                />{" "}
                                                <label
                                                  htmlFor="email_include"
                                                  className={
                                                    ownerContactIncludes[
                                                      "email_include"
                                                    ]
                                                      ? "site_btn blank yellow_blank main_color active"
                                                      : "site_btn blank yellow_blank main_color"
                                                  }
                                                  onClick={(e) =>
                                                    handleSetOwnerContactIncludesLabel(
                                                      e,
                                                      "email_include",
                                                      !ownerContactIncludes[
                                                      "email_include"
                                                      ]
                                                    )
                                                  }
                                                >
                                                  Include Email Address
                                                </label>
                                                {/* <label
                                              for="email_include"
                                              className="site_btn blank yellow_blank main_color"
                                              style={{
                                                backgroundColor:
                                                  ownerContactIncludes[
                                                    "email_include"
                                                  ]
                                                    ? "#67773F"
                                                    : ""
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: ownerContactIncludes[
                                                    "email_include"
                                                  ]
                                                    ? "white"
                                                    : "black"
                                                }}
                                              >
                                                Include email address
                                              </span>
                                              </label>*/}
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="checkbox"
                                                  name="owner_info"
                                                  value="mailing_include"
                                                  className="hidden"
                                                  checked={
                                                    ownerContactIncludes[
                                                    "mailing_include"
                                                    ]
                                                  }
                                                  // onChange={
                                                  //   handleSetOwnerContactIncludes
                                                  // }
                                                  id="mailing_include"
                                                // className="hidden"
                                                />{" "}
                                                <label
                                                  htmlFor="mailing_include"
                                                  className={
                                                    ownerContactIncludes[
                                                      "mailing_include"
                                                    ]
                                                      ? "site_btn blank yellow_blank main_color active"
                                                      : "site_btn blank yellow_blank main_color"
                                                  }
                                                  onClick={(e) =>
                                                    handleSetOwnerContactIncludesLabel(
                                                      e,
                                                      "mailing_include",
                                                      !ownerContactIncludes[
                                                      "mailing_include"
                                                      ]
                                                    )
                                                  }
                                                >
                                                  Include Mailing Address
                                                </label>
                                                {/* <label
                                              for="mailing_include"
                                              className="site_btn blank yellow_blank main_color"
                                              style={{
                                                backgroundColor:
                                                  ownerContactIncludes[
                                                    "mailing_include"
                                                  ]
                                                    ? "#67773F"
                                                    : ""
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: ownerContactIncludes[
                                                    "mailing_include"
                                                  ]
                                                    ? "white"
                                                    : "black"
                                                }}
                                              >
                                                Include mailing address
                                              </span>
                                              </label> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Portfolio assessed value</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name=""
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Min</option>
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name=""
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Max</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Reported owner</h6>
                                              <ReactTooltip
                                                id="reported-owner"
                                                place="bottom"
                                                content="Current owner of the property"
                                              />
                                              <div
                                                className="info_span"
                                                data-tooltip-id="reported-owner"
                                              >
                                                <span className="ic_on">
                                                  <img
                                                    src="/images/info-circle.svg"
                                                    alt=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="reported_owner"
                                              placeholder="Reported owner"
                                              defaultValue={
                                                searchQueryValues?.reported_owner
                                              }
                                            />
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>
                                                Reported owner mailing address
                                              </h6>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="reported_owner_email"
                                              placeholder="Mailing address"
                                              defaultValue={
                                                searchQueryValues?.reported_owner_email
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn_blk text-right">
                                        <button
                                          className="site_btn blank yellow_blank"
                                          onClick={ToggleMoreFilters}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="submit"
                                          className="site_btn"
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </form>
                                  </TabPanel>

                                  <TabPanel>
                                    <form
                                      id="sales"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "sales")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk padding_right_span">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales date</h6>
                                              <div className="span_type_lbl">
                                                <span
                                                  className={
                                                    selectedOptionWithin[
                                                      "sale_date_within"
                                                    ] === "yes"
                                                      ? "btn_sm_span fil_btn"
                                                      : "btn_sm_span"
                                                  }
                                                  onClick={(event) =>
                                                    handleRadioWithinChange(
                                                      event,
                                                      "sale_date_within",
                                                      "yes"
                                                    )
                                                  }
                                                >
                                                  Within
                                                </span>
                                                <span
                                                  className={
                                                    selectedOptionWithin[
                                                      "sale_date_within"
                                                    ] === "no"
                                                      ? "btn_sm_span fil_btn"
                                                      : "btn_sm_span"
                                                  }
                                                  onClick={(event) =>
                                                    handleRadioWithinChange(
                                                      event,
                                                      "sale_date_within",
                                                      "no"
                                                    )
                                                  }
                                                >
                                                  Not within
                                                </span>
                                              </div>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="90_days"
                                                  value="90 days ago"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "90 days ago"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "90 days ago"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="90_days"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "90 days ago"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "90 days ago"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 90 days
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past year"
                                                  id="past_year"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "past year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past year"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="past_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past 2 years"
                                                  id="2_year"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "past 2 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 2 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="2_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 2 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 2 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 2 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="5_year"
                                                  value="past 5 years"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "past 5 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 5 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="5_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 5 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 5 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 5 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past 10 years"
                                                  id="10_year"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "past 10 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 10 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="10_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 10 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 10 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 10 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="15_year"
                                                  value="past 15 year"
                                                  checked={
                                                    selectedOption[
                                                    "sale_date"
                                                    ] === "past 15 year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 15 year"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="15_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 15 year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 15 year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 15 year
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales price</h6>

                                              <div className="span_type_lbl">
                                                <span className="btn_sm_span fil_btn">
                                                  Preset
                                                </span>
                                                <span className="btn_sm_span">
                                                  Custom
                                                </span>
                                              </div>
                                            </div>
                                            <MultiRangeSlider
                                              min={0}
                                              max={674333}
                                              onChange={({ min, max }) =>
                                                console.log(
                                                  `min = ${min}, max = ${max}`
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Multi-parcel sales?</h6>
                                              <ReactTooltip
                                                id="multi-parcel-sales"
                                                place="bottom"
                                                content="Please select options given below to find sales in properties."
                                              />
                                              <div
                                                className="info_span"
                                                data-tooltip-id="multi-parcel-sales"
                                              >
                                                <span className="ic_on">
                                                  <img
                                                    src="/images/info-circle.svg"
                                                    alt=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_multi_parcel"
                                                  value="yes"
                                                  checked={
                                                    selectedOption[
                                                    "sale_multi_parcel"
                                                    ] === "yes"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_multi_parcel",
                                                      "yes"
                                                    )
                                                  }
                                                  id="yes"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="yes"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_multi_parcel"
                                                      ] === "yes"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_multi_parcel"
                                                        ] === "yes"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Yes
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_multi_parcel"
                                                  value="no"
                                                  checked={
                                                    selectedOption[
                                                    "sale_multi_parcel"
                                                    ] === "no"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_multi_parcel",
                                                      "no"
                                                    )
                                                  }
                                                  id="no"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="no"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_multi_parcel"
                                                      ] === "no"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_multi_parcel"
                                                        ] === "no"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    No
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales price / SF</h6>

                                              <div className="span_type_lbl">
                                                <span className="btn_sm_span fil_btn">
                                                  Preset
                                                </span>
                                                <span className="btn_sm_span">
                                                  Custom
                                                </span>
                                              </div>
                                            </div>
                                            <MultiRangeSlider
                                              min={0}
                                              max={674333}
                                              onChange={({ min, max }) =>
                                                console.log(
                                                  `min = ${min}, max = ${max}`
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales price / Acre</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="min_sales_price"
                                                  id="min_sales_price"
                                                  className="input"
                                                  placeholder="Min Price"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.min_sales_price
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="max_sales_price"
                                                  id="max_sales_price"
                                                  className="input"
                                                  placeholder="Min Price"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.max_sales_price
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="br"></div>

                                          <div className="br"></div>
                                          <div className="doted_flex purple_clr">
                                            <span className="site_btn">
                                              High
                                            </span>
                                            <span className="doted_line"></span>
                                            <p>55,543,56</p>
                                          </div>
                                          <div className="sm_br_gap"></div>
                                          <div className="doted_flex green_clr">
                                            <span className="site_btn">
                                              Medium
                                            </span>
                                            <span className="doted_line"></span>
                                            <p>23,543,52</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="footer_filter_btn">
                                        <div className="text_left">
                                          <p>
                                            Filters apply to most recent
                                            transaction
                                          </p>
                                        </div>
                                        <div className="btn_blk text-right">
                                          <button
                                            className="site_btn blank yellow_blank"
                                            onClick={ToggleMoreFilters}
                                          >
                                            Close
                                          </button>
                                          <a
                                            href="javscript:viod(0)"
                                            onClick={(event) =>
                                              handleSubmit(event, "sales")
                                            }
                                            className="site_btn"
                                          >
                                            Apply
                                          </a>
                                        </div>
                                      </div>
                                    </form>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="mortage_amount"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "mortage_amount")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Mortgage Amount</h6>

                                              <div className="span_type_lbl">
                                                <span className="btn_sm_span fil_btn">
                                                  Preset
                                                </span>
                                                <span className="btn_sm_span">
                                                  Custom
                                                </span>
                                              </div>
                                            </div>
                                            <MultiRangeSlider
                                              name="mortgage_amount"
                                              min={0}
                                              max={1000000}
                                              onChange={({ min, max }) =>
                                                handleMultiSelectChange(
                                                  "mortgage_amount",
                                                  min,
                                                  max
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Maturity Date</h6>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="maturity_date"
                                                  value="90_days"
                                                  checked={
                                                    selectedOption[
                                                    "maturity_date"
                                                    ] === "90_days"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "maturity_date",
                                                      "90_days"
                                                    )
                                                  }
                                                  id="90_days"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="90_days"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "maturity_date"
                                                      ] === "90_days"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "maturity_date"
                                                        ] === "90_days"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 90 days
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="maturity_date"
                                                  value="past_year"
                                                  checked={
                                                    selectedOption[
                                                    "maturity_date"
                                                    ] === "past_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "maturity_date",
                                                      "past_year"
                                                    )
                                                  }
                                                  id="past_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="past_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "maturity_date"
                                                      ] === "past_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "maturity_date"
                                                        ] === "past_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="maturity_date"
                                                  value="2_year"
                                                  checked={
                                                    selectedOption[
                                                    "maturity_date"
                                                    ] === "2_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "maturity_date",
                                                      "2_year"
                                                    )
                                                  }
                                                  id="2_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="2_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "maturity_date"
                                                      ] === "2_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "maturity_date"
                                                        ] === "2_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 2 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="maturity_date"
                                                  value="5_year"
                                                  checked={
                                                    selectedOption[
                                                    "maturity_date"
                                                    ] === "5_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "maturity_date",
                                                      "5_year"
                                                    )
                                                  }
                                                  id="5_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="5_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "maturity_date"
                                                      ] === "5_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "maturity_date"
                                                        ] === "5_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 5 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="maturity_date"
                                                  value="10_year"
                                                  checked={
                                                    selectedOption[
                                                    "maturity_date"
                                                    ] === "10_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "maturity_date",
                                                      "10_year"
                                                    )
                                                  }
                                                  id="10_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="10_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "maturity_date"
                                                      ] === "10_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "maturity_date"
                                                        ] === "10_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 10 years
                                                  </span>
                                                </label>
                                              </div>
                                              {/* <div className="lbl_btn">
                                            <input
                                              type="radio"
                                              name="maturity_date"
                                              value="custom"
                                              checked={
                                                selectedOption[
                                                "maturity_date"
                                                ] === "custom"
                                              }
                                              onChange={(event) =>
                                                handleRadioChange(
                                                  event,
                                                  "maturity_date",
                                                  "custom"
                                                )
                                              }
                                              id="custom"
                                              className="hidden"
                                            />
                                            <label
                                              for="custom"
                                              className="site_btn blank yellow_blank main_color"
                                              style={{
                                                backgroundColor:
                                                  selectedOption[
                                                    "maturity_date"
                                                  ] === "custom"
                                                    ? "#67773F"
                                                    : "",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color:
                                                    selectedOption[
                                                      "maturity_date"
                                                    ] === "custom"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                Custom
                                              </span>
                                            </label>
                                          </div> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Mortgage origination</h6>
                                              {/* <div className="span_type_lbl">
                                            <span className="btn_sm_span fil_btn">
                                              Within
                                            </span>
                                            <span className="btn_sm_span">
                                              Not within
                                            </span>
                                          </div> */}
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="last_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "last_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "last_year"
                                                    )
                                                  }
                                                  id="last_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="last_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "last_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "last_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="1_2_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "1_2_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "1_2_year"
                                                    )
                                                  }
                                                  id="1_2_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="1_2_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "1_2_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "1_2_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last 1-2 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="2_3_year"
                                                  id="2_3_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "2_3_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "2_3_year"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="2_3_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "2_3_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "2_3_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last 2-3 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="3_5_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "3_5_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "3_5_year"
                                                    )
                                                  }
                                                  id="3_5_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="3_5_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "3_5_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "3_5_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last 3-5 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="5_7_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "5_7_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "5_7_year"
                                                    )
                                                  }
                                                  id="5_7_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="5_7_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "5_7_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "5_7_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last 5-7 years
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="mortage_origination"
                                                  value="7_8_year"
                                                  checked={
                                                    selectedOption[
                                                    "mortage_origination"
                                                    ] === "7_8_year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "mortage_origination",
                                                      "7_8_year"
                                                    )
                                                  }
                                                  id="7_8_year"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="7_8_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === "7_8_year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === "7_8_year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Last 7-8 years
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="footer_filter_btn">
                                        <div className="text_left">
                                          <p>
                                            Filters apply to most recent
                                            transaction
                                          </p>
                                        </div>
                                        <div className="btn_blk text-right">
                                          <button
                                            className="site_btn blank yellow_blank"
                                            onClick={ToggleMoreFilters}
                                          >
                                            Close
                                          </button>
                                          <button
                                            className="site_btn"
                                            type="submit"
                                          >
                                            Apply
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="auction_data"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "auction_data")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Forclosure Properties</h6>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <label
                                                  for="next_week"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "forclosure_properties"
                                                      ] === "Yes"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                  onClick={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "forclosure_properties",
                                                      "Yes"
                                                    )
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "forclosure_properties"
                                                        ] === "Yes"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Yes
                                                  </span>
                                                </label>
                                              </div>

                                              <div className="lbl_btn">
                                                <label
                                                  for="next_week"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "forclosure_properties"
                                                      ] === "No"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                  onClick={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "forclosure_properties",
                                                      "No"
                                                    )
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "forclosure_properties"
                                                        ] === "No"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    No
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn_blk text-right">
                                        <button
                                          className="site_btn blank yellow_blank"
                                          onClick={ToggleMoreFilters}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="submit"
                                          className="site_btn"
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </form>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="yoy_cahange"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "yoy_cahange")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Tax Year</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name="tax_min_year"
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Min</option>
                                                  {Array.from(
                                                    { length: 151 },
                                                    (_, index) => (
                                                      <option
                                                        key={1900 + index}
                                                        value={1900 + index}
                                                        selected={
                                                          searchQueryValues?.tax_min_year ==
                                                            1900 + index
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        {1900 + index}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name="tax_max_year"
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Max</option>
                                                  {Array.from(
                                                    { length: 151 },
                                                    (_, index) => (
                                                      <option
                                                        key={1900 + index}
                                                        value={1900 + index}
                                                        selected={
                                                          searchQueryValues?.tax_max_year ==
                                                            1900 + index
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        {1900 + index}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Tax amount</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="number"
                                                  name="min_tax_amount"
                                                  className="input"
                                                  placeholder="Min"
                                                  defaultValue={
                                                    searchQueryValues?.min_tax_amount
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="number"
                                                  name="max_tax_amount"
                                                  className="input"
                                                  placeholder="Max"
                                                  defaultValue={
                                                    searchQueryValues?.max_tax_amount
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn_blk text-right">
                                        <button
                                          className="site_btn blank yellow_blank"
                                          onClick={ToggleMoreFilters}
                                        >
                                          Close
                                        </button>
                                        <button className="site_btn">
                                          Apply
                                        </button>
                                      </div>
                                    </form>
                                  </TabPanel>
                                  <TabPanel>
                                    <form
                                      id="labels_form"
                                      onSubmit={(event) =>
                                        handleSubmit(event, "labels_form")
                                      }
                                    >
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Labels</h6>
                                              <ReactTooltip
                                                id="user-labels"
                                                place="bottom"
                                                content="Write label name manually or select among your stored labels to find properties which have these labels."
                                              />
                                              <div
                                                className="info_span"
                                                data-tooltip-id="user-labels"
                                              >
                                                <span className="ic_on">
                                                  <img
                                                    src="/images/info-circle.svg"
                                                    alt=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              placeholder="Search for label"
                                            />
                                            <div className="sm_br_gap"></div>
                                            <div className="form_blk lbl_table_search">
                                              <table>
                                                <thead>
                                                  <tr>
                                                    <th>Labels</th>
                                                    <th>Includes</th>
                                                    <th>Exclude</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {userLabels.map((label) => (
                                                    <tr>
                                                      <td
                                                        className="lbl_dot blue_dot"
                                                        style={{
                                                          backgroundColor:
                                                            label.label_color,
                                                        }}
                                                      >
                                                        {label.label_name}
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          onChange={
                                                            handleLabelIncludeChange
                                                          }
                                                          defaultValue={
                                                            label.label_name
                                                          }
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          defaultValue={
                                                            label.label_name
                                                          }
                                                          onChange={
                                                            handleLabelExcludeChange
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="br"></div>
                                            <div className="form_blk">
                                              <div className="info_lbl_lbl">
                                                <h6>Viewed Date</h6>
                                                <div className="span_type_lbl">
                                                  <span className="btn_sm_span fil_btn">
                                                    Within
                                                  </span>
                                                  <span className="btn_sm_span">
                                                    Not within
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="boolean_flex">
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="past_day"
                                                    checked={
                                                      selectedOption[
                                                      "viewed_date"
                                                      ] === "past_day"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "past_day"
                                                      )
                                                    }
                                                    id="past_day"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="past_day"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "past_day"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "past_day"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past day
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="past_7_days"
                                                    checked={
                                                      selectedOption[
                                                      "viewed_date"
                                                      ] === "past_7_days"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "past_7_days"
                                                      )
                                                    }
                                                    id="past_7_days"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="past_7_days"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "past_7_days"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "past_7_days"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 7 days
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="30_days"
                                                    checked={
                                                      selectedOption[
                                                      "viewed_date"
                                                      ] === "30_days"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "30_days"
                                                      )
                                                    }
                                                    id="30_days"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="30_days"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "30_days"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "30_days"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 30 days
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="3_mnth"
                                                    checked={
                                                      selectedOption[
                                                      "viewed_date"
                                                      ] === "3_mnth"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "3_mnth"
                                                      )
                                                    }
                                                    id="3_mnth"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="3_mnth"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "3_mnth"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "3_mnth"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 3 months
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="custom"
                                                    checked={
                                                      selectedOption[
                                                      "viewed_date"
                                                      ] === "custom"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "custom"
                                                      )
                                                    }
                                                    id="custom"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="custom"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "custom"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "custom"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Custom
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="br"></div>
                                            <div className="form_blk">
                                              <div className="info_lbl_lbl">
                                                <h6>Exported properties</h6>
                                              </div>
                                              <div className="boolean_flex">
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="exported_properteis"
                                                    value="include"
                                                    checked={
                                                      selectedOption[
                                                      "exported_properteis"
                                                      ] === "include"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "exported_properteis",
                                                        "include"
                                                      )
                                                    }
                                                    id="include"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="include"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "exported_properteis"
                                                        ] === "include"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "exported_properteis"
                                                          ] === "include"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Include
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="exported_properteis"
                                                    value="exclude"
                                                    checked={
                                                      selectedOption[
                                                      "exported_properteis"
                                                      ] === "exclude"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "exported_properteis",
                                                        "exclude"
                                                      )
                                                    }
                                                    id="exclude"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="exclude"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "exported_properteis"
                                                        ] === "exclude"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "exported_properteis"
                                                          ] === "exclude"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Exclude
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Note text</h6>
                                            </div>
                                            <input
                                              type="text"
                                              placeholder="Text"
                                              className="input"
                                            />
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Has a note?</h6>
                                            </div>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="note"
                                                id="note"
                                              />
                                              <label htmlFor="note">Yes</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn_blk text-right">
                                        <button
                                          className="site_btn blank yellow_blank"
                                          onClick={ToggleMoreFilters}
                                        >
                                          Close
                                        </button>
                                        <button
                                          typye="submit"
                                          className="site_btn"
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </form>
                                  </TabPanel>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        ref={props.searchRef}
                        href="javascript:void(0)"
                        className="site_btn blank yellow_blank"
                        onClick={(event) => handleSubmit(event, "search_field")}
                      >
                        Search Result
                      </a>
                    </div>
                  </div>
                  <div className="cell_show_filters">
                    <button
                      className="site_btn"
                      onClick={ToggleHideSearch}
                      type="button"
                    >
                      <span>Show Search</span>
                      <img src="/images/eye_circle_hide.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div className="colR hide_cell_filter">
                  {setShowSearchResults && !showMap && (
                    <button
                      className="site_btn blank no_filter yellow_blank"
                      onClick={ToggleHideSearch}
                      type="button"
                    >
                      <span>Hide Search</span>
                      <img src="/images/eye_circle_hide.svg" alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {
            // isLoadingform ? (
            //   <LoadingScreen />
            // ) : (
            showSearchResults && (
              <SearchResultsComponent
                data={searchResultsData}
                handlePageClick={handlePageClick}
                hideSearch={hideSearch}
                ToggleHideSearch={ToggleHideSearch}
                sortValue={sortValue}
                setSortValue={setSortValue}
                handleSubmit={handleSubmit}
                searchedPage={page}
                drawnFeatures={props.drawnFeatures}
                setDrawnFeatures={props.setDrawnFeatures}
              />
            )
            // )
          }
        </>
      )}
    </>
  );
};

export default FiltersSearch;
