import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SIGN_IN_CONTENT,
  FETCH_SIGN_IN_CONTENT_SUCCESS,
  FETCH_SIGN_IN_CONTENT_FAILED,
  SIGN_IN_ACCOUNT_MESSAGE,
  SIGN_IN_ACCOUNT_MESSAGE_SUCCESS,
  SIGN_IN_ACCOUNT_MESSAGE_FAILED,
  SIGN_IN_VERIFICATION_MESSAGE,
  SIGN_IN_VERIFICATION_MESSAGE_SUCCESS,
  SIGN_IN_VERIFICATION_MESSAGE_FAILED,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS,
  SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED
} from "./actionTypes";

export const fetchSignin = () => (dispatch) => {
  dispatch({
    type: FETCH_SIGN_IN_CONTENT,
    payload: null
  });
  http
    .get("signin-page")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGN_IN_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGN_IN_CONTENT_FAILED,
        payload: error
      });
    });
};

export const signin = (formData, location) => (dispatch) => {
  dispatch({
    type: SIGN_IN_ACCOUNT_MESSAGE,
    payload: null
  });
  http
    .post("login", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        // toast.success("Sign in successful. Redirecting…", TOAST_SETTINGS);
        dispatch({
          type: SIGN_IN_ACCOUNT_MESSAGE_SUCCESS,
          payload: data
        });
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: SIGN_IN_ACCOUNT_MESSAGE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SIGN_IN_ACCOUNT_MESSAGE_FAILED,
        payload: error
      });
    });
};

export const verifyLogin = (formData, location) => (dispatch) => {
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: SIGN_IN_VERIFICATION_MESSAGE,
    payload: null
  });
  http
    .post("verify-login", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Sign in successful. Redirecting…", TOAST_SETTINGS);
        dispatch({
          type: SIGN_IN_VERIFICATION_MESSAGE_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          if (localStorage.getItem("fromHome")) {
            window.location.replace("/dashboard");
            localStorage.removeItem("fromHome");
          } else {
            if (location.state?.from)
              window.location.replace(location.state.from.pathname);
            else window.location.replace("/dashboard");
          }
        }, 3000);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: SIGN_IN_VERIFICATION_MESSAGE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SIGN_IN_VERIFICATION_MESSAGE_FAILED,
        payload: error
      });
    });
};

// export const signinPopup = (formData) => (dispatch) => {
//   const signinFrom = formData.from;
//   dispatch({
//     type: SIGN_IN_ACCOUNT_POPUP_MESSAGE,
//     payload: null
//   });
//   http
//     .post("auth/signin", helpers.doObjToFormData(formData))
//     .then(({ data }) => {
//       if (data.status) {
//         toast.success(
//           "Signin successfully. Redirecting to dashboard, please wait...",
//           TOAST_SETTINGS
//         );
//         dispatch({
//           type: SIGN_IN_ACCOUNT_POPUP_MESSAGE_SUCCESS,
//           payload: data
//         });
//         if (signinFrom !== "popup") {
//           setTimeout(() => {
//             window.location.replace("/dashboard");
//           }, 4000);
//         }
//       } else {
//         if (data.validationErrors) {
//           toast.error(
//             <Text string={data.validationErrors} parse={true} />,
//             TOAST_SETTINGS
//           );
//           dispatch({
//             type: SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED,
//             payload: null
//           });
//         }
//       }
//     })
//     .catch((error) => {
//       dispatch({
//         type: SIGN_IN_ACCOUNT_POPUP_MESSAGE_FAILED,
//         payload: error
//       });
//     });
// };

// export const signout = () => {
//   localStorage.removeItem("authToken");
//   window.location.replace("/signin");
// };
