import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import * as paths from "../../constants/paths";

const ImageControl = ({
  src,
  folder,
  classes,
  isThumb = false,
  specificWidth = false,
  isLazy = false,
  isUser = false
}) => {
  let url = paths.API_UPLOADS_URL;

  if (src) {
    if (isThumb) {
      src = `${url}${folder}/thumb_${src}`;
    } else if (specificWidth) {
      src = `${url}${folder}/${specificWidth}${src}`;
    } else {
      src = `${url}${folder}/${src}`;
    }
  }

  return (
    <>
      {src ? (
        isLazy ? (
          <LazyLoadImage
            alt="Error While Loading Image"
            effect="blur"
            src={src}
            className={classes && classes}
          />
        ) : (
          <img
            alt="Error While Loading Image"
            src={src}
            className={classes && classes}
          />
        )
      ) : isUser ? (
        <img alt="No User Image Found" src="/images/no-user.svg" />
      ) : (
        <img alt="No Image Found" src="/images/no-image.svg" />
      )}
    </>
  );
};

export default ImageControl;
