import React, { useEffect } from "react";
import { Link } from "react-router-dom";


import { fetchIndustries } from "../../../states/actions/fetchHome";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import MetaGenerator from "../../common/meta-generator";
const Industries = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchHome.content);
    const isLoading = useSelector((state) => state.fetchHome.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    const { page_title, industries, meta } = data;
    useEffect(() => {
        dispatch(fetchIndustries());
    }, []);

    // useDocumentTitle(data?.page_title);
    return (
        <>

            {isLoading || isSiteSettingsLoading ? (
                <LoadingScreen />
            ) : <>
                <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
                <section className="leading_industries industries_page">
                    <div className="contain">
                        <div className="sec_heading text-center">
                            <h1>Rhino’s Leading Industries</h1>
                        </div>
                        <div className="flex">
                            {
                                industries?.map((industry, index) => {
                                    return (
                                        <div className="col" key={index}>
                                            <Link to={"/industry/" + industry?.slug} className="inner">
                                                <div className="icon_img">
                                                    <ImageControl folder="industries" src={industry?.icon} />
                                                </div>
                                                <div className="cntnt">
                                                    <h5>{industry?.title}</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </section>
            </>
            }
        </>
    );
};
export default Industries;
