import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const Story = ({ content }) => {
  return (
    <>
      <section className="abt_story">
        <div className="contain">
          <div className="flex">
            <div className="colL">
              <div className="image">
                <ImageControl src={content.image6} folder="images" />
              </div>
              <Text string={content.section4_left_bottom_text} />
              {/* <Link to="/">More We Works ></Link> */}
            </div>
            <div className="colR">
              <h2>
                <Text string={content.section4_right_upper_heading} />
              </h2>
              <div className="image">
                <ImageControl src={content.image7} folder="images" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Story;
