import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_ENTERPRISE_CHECKOUT_CONTENT,
  FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS,
  FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED,
  ENTERPRISE_CHECKOUT_MESSAGE,
  ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS,
  ENTERPRISE_CHECKOUT_MESSAGE_FAILED
} from "./actionTypes";

export const fetchEnterpriseCheckout = () => (dispatch) => {
  dispatch({
    type: FETCH_ENTERPRISE_CHECKOUT_CONTENT,
    payload: null
  });
  http
    .post("enterprise-checkout-page")
    .then(({ data }) => {
      

      dispatch({
        type: FETCH_ENTERPRISE_CHECKOUT_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ENTERPRISE_CHECKOUT_CONTENT_FAILED,
        payload: error
      });
    });
};
export const postEnterpriseCheckout = (formData, location) => (dispatch) => {
  dispatch({
    type: ENTERPRISE_CHECKOUT_MESSAGE,
    payload: null
  });
  http
    .post("save-enterprise-checkout", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data)
      if (data.status) {
        toast.success(data?.msg, TOAST_SETTINGS);
        dispatch({
          type: ENTERPRISE_CHECKOUT_MESSAGE_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.reload()
        }, 4000);
      } else {
        if (data?.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: ENTERPRISE_CHECKOUT_MESSAGE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: ENTERPRISE_CHECKOUT_MESSAGE_FAILED,
        payload: error
      });
    });
};
