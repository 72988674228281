import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import UserInfoDropdown from "./UserInfoDropdown";
import { useSelector, useDispatch } from "react-redux";
import { logout, getWordInitials } from "../../helpers/helpers";
import { fetchSavedSearchesHeader } from "../../states/actions/fetchSavedSearches";

export default function Nav({ active, setToggle, member }) {
  const dispatch = useDispatch();
  const [dropShow, setDropShow] = useState(false);
  const [searches, setSearches] = useState(false);

  const ToggleSearches = () => {
    setSearches(!searches);
  };
  const handleLinkClick = () => {
    setToggle(false);
    setSearches(false);
  };

  useEffect(() => {
    if (searches) dispatch(fetchSavedSearchesHeader());
  }, [searches]);

  const ToggleDrop = () => {
    setDropShow(!dropShow);
  };

  const searchesHeader = useSelector(
    (state) => state.fetchSavedSearches.searchesHeader
  );

  const isFetchingForHeader = useSelector(
    (state) => state.fetchSavedSearches.isFetchingForHeader
  );
  const total_available_credits = useSelector(
    (state) => state.fetchSiteSettings.total_available_credits
  );
  const redirectToSearchPage = (data) => {
    let url = `/search-property?${objectToQueryString(data)}`;
    window.location.href = url;
    // navigate(url);
  };
  const objectToQueryString = (obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
        );
      }
    }
    return keyValuePairs.join("&");
  };
  const savedSearchDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!savedSearchDrop.current.contains(e.target)) {
        setSearches(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  return (
    <div className="hide_sm_screen">
      {/* for desktop navigation */}
      <nav
        className={active ? "loged_nav active" : "loged_nav"}
        nav="true"
        id="nav"
      >
        <ul className="nav_neg">
          <li className="">
            <NavLink
              to="/search-property"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Search
            </NavLink>
          </li>
          <li
            className={searches ? "nav_drop active" : "nav_drop"}
            ref={savedSearchDrop}
          >
            <button onClick={ToggleSearches} className="nav_drop_btn">
              Saved Searches
            </button>
            <div className="sub sm_wide">
              <div className="parts_searches">
                {isFetchingForHeader
                  ? "fetching..."
                  : searchesHeader && searchesHeader.length > 0
                  ? searchesHeader.map((search, i) => (
                      <button
                        key={i + 100}
                        onClick={() => redirectToSearchPage(search?.query)}
                      >
                        {search?.keyword + " On " + search?.searched_date}
                      </button>
                      // <button key={i + 100}>{getSearchTag(type, data)}</button>
                    ))
                  : "The searches that you have made will show here"}
              </div>
              <div className="devide_line"></div>
              <Link
                onClick={ToggleSearches}
                to="/saved-searches"
                className="site_btn"
              >
                View all
              </Link>
            </div>
          </li>
          <li className="">
            <NavLink
              to="/help"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Help Center
            </NavLink>
          </li>
        </ul>
      </nav>
      <ul className="log_btns hide_cell_log_btns">
        <li className="credit_nav">
          <span>
            Credits :
            <em>{total_available_credits ? total_available_credits : 0}</em>
          </span>
        </li>
        <li>
          <NavLink to="/upload-data" className="site_btn blank yellow_blank">
            Data upload
          </NavLink>
        </li>
        <UserInfoDropdown is_public={false} />
      </ul>

      <div className="clearfix"></div>

      {/* end for desktop navigation */}

      {/* mobile_navigation */}
      <nav className="cell_logged_nav" nav="true">
        <ul className="nav_neg">
          <li className="">
            <NavLink
              to="/search-property"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Search
            </NavLink>
          </li>
          <li
            className={
              searches
                ? "nav_drop hide_ds_ds_cell active"
                : "hide_ds_ds_cell nav_drop"
            }
          >
            <button onClick={ToggleSearches} className="nav_drop_btn">
              Saved searches
            </button>
            <div className="sub sm_wide">
              <div className="parts_searches">
                {isFetchingForHeader
                  ? "fetching..."
                  : searchesHeader && searchesHeader.length > 0
                  ? searchesHeader.map((search, i) => (
                      <button
                        key={i + 100}
                        onClick={() => redirectToSearchPage(search?.query)}
                      >
                        {search?.keyword + " On " + search?.searched_date}
                      </button>
                    ))
                  : "The searches that you have made will show here"}
              </div>
              <div className="devide_line"></div>
              <Link
                onClick={handleLinkClick}
                to="/saved-searches"
                className="site_btn"
              >
                View all
              </Link>
            </div>
          </li>
          <li className="hide_cell_cell">
            <NavLink
              to="/help"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Help Center
            </NavLink>
          </li>
        </ul>
      </nav>
      <ul className="log_btns cell_log_btns">
        <li>
          <NavLink to="/upload-data" className="site_btn blank yellow_blank">
            Data upload
          </NavLink>
        </li>

        <li
          className={
            active ? "drop drop dashboard_dp active" : "drop dashboard_dp"
          }
        >
          <NavLink to="#" onClick={ToggleDrop} className="profile_btn drop_btn">
            CK
          </NavLink>
          <div className="sub mobile_dashboard_dp">
            <div className="head_user">
              <div className="user_dp">
                <img src="/images/user_dp.png" alt="" />
              </div>
              <div className="user_name">
                <h6>Mr.Matt</h6>
                <p>Member</p>
              </div>
            </div>
            <div className="text-center">
              <Link
                to="/upload-data"
                className="site_btn blank yellow_blank"
                onClick={handleLinkClick}
              >
                Data Upload
              </Link>
            </div>
            <ul className="lst">
              <li>
                <span>
                  <img src="/images/profile_setting.svg" alt="" />
                </span>
                <Link to="/account-settings" onClick={handleLinkClick}>
                  Profile Settings
                </Link>
              </li>
              <li>
                <span>
                  <img src="/images/support.svg" alt="" />
                </span>
                <Link to="/support" onClick={handleLinkClick}>
                  Support
                </Link>
              </li>
              <li>
                <span>
                  <img src="/images/logout.svg" alt="" />
                </span>
                <a href="#" onClick={() => logout()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div className="clearfix"></div>
      {/* end mobile navigation */}
    </div>
  );
}
