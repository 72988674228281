import {
  FETCH_HELP_CONTENT,
  FETCH_HELP_CONTENT_SUCCESS,
  FETCH_HELP_CONTENT_FAILED,
  SEARCH_HELP,
  SEARCH_HELP_SUCCESS,
  SEARCH_HELP_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  isSearching: false,
  help_records: null,
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HELP_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_HELP_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        help_records: payload.help_records,
        content: payload
      };
    case FETCH_HELP_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SEARCH_HELP:
      return {
        ...state,
        isSearching: true
      };
    case SEARCH_HELP_SUCCESS:
      return {
        ...state,
        help_records: payload.help_records,
        isSearching: false
      };
    case SEARCH_HELP_FAILED:
      return {
        ...state,
        isSearching: false
      };
    default:
      return state;
  }
}
