import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SubmitButton from "../../common/SubmitButton";
import { updateNotificationsSettings } from "../../../states/actions/fetchProfileSettings";

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationSettings = useSelector(
    (state) => state.fetchProfileSettings.notificationSettings
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    dispatch(updateNotificationsSettings(data));
  };

  return (
    <>
      <div className="setting_blk wide_sm">
        <h4>Notifications</h4>

        <form onSubmit={handleSubmit(firstSubmit)} method="POST">
          <div className="from_row row">
            <div className="col-sm-12">
              <div className="blk_form">
                <h5 className="sub_acc_heading">General</h5>
                <div className="switch_flex cell_bottom_mrgin_none">
                  <div className="cntnt">
                    <p>
                      Get notifications from Rhino Recon to stay up-to-date.
                    </p>
                  </div>
                  <div className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={notificationSettings?.site_updates}
                      {...register("site_updates")}
                    />
                    <em></em>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="divide"></div>
              <div className="blk_form mr_b_0">
                <h5 className="sub_acc_heading">Emails</h5>
                <div className="check_lbl">
                  <div className="lbl_btn">
                    <input
                      type="checkbox"
                      defaultChecked={
                        notificationSettings?.team_related_products
                      }
                      {...register("team_related_products")}
                    />
                    <label htmlFor="team_related">
                      Receive team-related product emails
                    </label>
                  </div>
                  <div className="lbl_btn">
                    <input
                      type="checkbox"
                      defaultChecked={
                        notificationSettings?.property_recommendation_updates
                      }
                      {...register("property_recommendation_updates")}
                    />
                    <label htmlFor="recommendation">
                      Receive property recommendation updates
                    </label>
                  </div>
                  <div className="lbl_btn">
                    <input
                      type="checkbox"
                      defaultChecked={
                        notificationSettings?.membership_information
                      }
                      {...register("membership_information")}
                    />
                    <label htmlFor="membership">
                      Receive membership information such as renewal
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="divide"></div>
              <div className="blk_form mr_b_0">
                <h5 className="sub_acc_heading">Email Newsletter</h5>
                <div className="flex radio_email">
                  <div className="lbl_btn">
                    <input
                      type="radio"
                      defaultChecked={notificationSettings?.email_newsletter}
                      value={1}
                      {...register("email_newsletter")}
                    />
                    <label htmlFor="radio_email_on">On</label>
                  </div>
                  <div className="lbl_btn">
                    <input
                      type="radio"
                      defaultChecked={!notificationSettings?.email_newsletter}
                      value={0}
                      {...register("email_newsletter")}
                    />
                    <label htmlFor="radio_email_off">Off</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divide"></div>
          <div className="btn_blk text-right cell_wide_full">
            <SubmitButton
              isFormProcessing={isFormProcessing}
              button_text="Save Changes"
              classes="site_btn"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Notifications;
