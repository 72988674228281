import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_PROFILE_SETTINGS,
  FETCH_PROFILE_SETTINGS_SUCCESS,
  FETCH_PROFILE_SETTINGS_FAILED,
  SAVE_PROFILE_SETTINGS,
  SAVE_PROFILE_SETTINGS_SUCCESS,
  SAVE_PROFILE_SETTINGS_FAILED,
  TOGGLE_CHANGE_PASSWORD_POPUP,
  TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILED,
  SHOW_USER_POPUP,
  CANCEL_SUBSCRIPTION_MESSAGE,
  CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS
} from "./actionTypes";
export const cancelSubscription = (formData, location) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    formData = { ...formData, token: localStorage.getItem("authToken") };
    dispatch({
      type: CANCEL_SUBSCRIPTION_MESSAGE,
      payload: null
    });
    http
      .post("cancel-member-subscription", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        console.log(data);
        if (data.status) {
          toast.success(data?.msg, TOAST_SETTINGS);
          dispatch({
            type: CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
            payload: data
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          if (data?.msg) {
            toast.error(
              <Text string={data.msg} parse={true} />,
              TOAST_SETTINGS
            );
            dispatch({
              type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
              payload: null
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
          payload: error
        });
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
export const fetchProfileSettings = () => (dispatch) => {
  dispatch({
    type: FETCH_PROFILE_SETTINGS,
    payload: null
  });
  http
    .post(
      "profile-settings",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_PROFILE_SETTINGS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROFILE_SETTINGS_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const saveProfileSettingsAction = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  let file = formData.profile;
  delete formData.profile;
  formData = helpers.doObjToFormData(formData);
  if (typeof file != "undefined") formData.append("profile", file[0]);

  dispatch({
    type: SAVE_PROFILE_SETTINGS,
    payload: null
  });
  httpBlob
    .post("save-profile-settings", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Profile settings saved successfully.", TOAST_SETTINGS);
        dispatch({
          type: SAVE_PROFILE_SETTINGS_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: SAVE_PROFILE_SETTINGS_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_PROFILE_SETTINGS_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const toggleChangePasswordPopupAction = () => (dispatch) => {
  dispatch({
    type: TOGGLE_CHANGE_PASSWORD_POPUP,
    payload: null
  });
};

export const toggleConfirmDeleteAccountPopupAction = () => (dispatch) => {
  dispatch({
    type: TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP,
    payload: null
  });
};

export const changePasswordAction = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: CHANGE_PASSWORD,
    payload: null
  });
  http
    .post("update-password", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("Password changed successfully.", TOAST_SETTINGS);
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          dispatch({
            type: TOGGLE_CHANGE_PASSWORD_POPUP,
            payload: null
          });
        }, 1500);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: CHANGE_PASSWORD_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const deleteAccount = () => (dispatch) => {
  let formData = { token: localStorage.getItem("authToken") };
  dispatch({
    type: DELETE_ACCOUNT,
    payload: null
  });
  http
    .post("delete-account", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("Account deleted successfully.", TOAST_SETTINGS);
        dispatch({
          type: DELETE_ACCOUNT_SUCCESS,
          payload: data
        });
        localStorage.removeItem("authToken");
        setTimeout(() => {
          dispatch({
            type: TOGGLE_CONFIRM_DELETE_ACCOUNT_POPUP,
            payload: null
          });
        }, 1000);
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: DELETE_ACCOUNT_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: DELETE_ACCOUNT_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const updateNotificationsSettings = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload: null
  });
  http
    .post(
      "update-notification-settings",
      helpers.doObjToFormDataWithoutString(formData)
    )
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "Notification settings updated successfully!",
          TOAST_SETTINGS
        );
        dispatch({
          type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: UPDATE_NOTIFICATION_SETTINGS_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_NOTIFICATION_SETTINGS_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const toggleUserPopupAction = () => (dispatch) => {
  dispatch({
    type: SHOW_USER_POPUP,
    payload: null
  });
};

export const saveUsers = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: SAVE_USER,
    payload: null
  });
  http
    .post("save-user", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("User saved successfully.", TOAST_SETTINGS);
        dispatch({
          type: SAVE_USER_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          dispatch({
            type: SHOW_USER_POPUP,
            payload: null
          });
        }, 1500);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: SAVE_USER_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_USER_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const deleteUser = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: DELETE_USER,
    payload: null
  });
  http
    .post("delete-user", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("User deleted successfully.", TOAST_SETTINGS);
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: data
        });
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: DELETE_USER_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: DELETE_USER_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
