import React from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../../common/SubmitButton";
import { changePasswordAction } from "../../../states/actions/fetchProfileSettings";
import { useDispatch, useSelector } from "react-redux";

const ChangePasswordPopup = ({ toggleChangePasswordPopup }) => {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    dispatch(changePasswordAction(data));
  };
  return (
    <div class="popup sm popup_password_change active">
      <div className="table_dv">
        <div className="table_cell">
          <div className="contain">
            <div className="_inner">
              <button
                type="button"
                className="x_btn"
                onClick={toggleChangePasswordPopup}
              ></button>
              <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
                <div className="from_row row">
                  <div className="col-sm-12">
                    <div className="blk_form">
                      <h6>Current Password</h6>
                      <div className="form_blk">
                        <input
                          type="password"
                          className="input"
                          {...register("old_password", {
                            required: "Old Password is required.",
                            minLength: {
                              value: 6,
                              message:
                                "Old Password should be atleast 6 characters long."
                            }
                          })}
                        />
                        <span className="validation-error">
                          {errors.old_password?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="blk_form">
                      <h6>New Password</h6>
                      <div className="form_blk">
                        <input
                          type="password"
                          className="input"
                          {...register("new_password", {
                            required: "New Password is required.",
                            minLength: {
                              value: 6,
                              message:
                                "New Password should be atleast 6 characters long."
                            }
                          })}
                        />
                        <span className="validation-error">
                          {errors.new_password?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="blk_form">
                      <h6>Confirm Password</h6>
                      <div className="form_blk">
                        <input
                          type="password"
                          className="input"
                          {...register("confirm_password", {
                            required: "Confirm Password is required.",
                            validate: (val) => {
                              if (watch("new_password") != val) {
                                return "Your passwords do no match.";
                              }
                            },
                            minLength: {
                              value: 6,
                              message:
                                "Password should be atleast 6 characters long."
                            }
                          })}
                        />
                        <span className="validation-error">
                          {errors.confirm_password?.message}
                        </span>
                        <p className="small_text_popup">
                          <small>
                            Password must be at least 12 characters and
                            combination of small and capital letters, symbols,
                            and numbers.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_blk text-center">
                  <SubmitButton
                    classes="site_btn"
                    isFormProcessing={isFormProcessing}
                    button_text="Update Password"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPopup;
