import React from "react";
import { Link } from "react-router-dom";

const ViewdProperties = ({ data }) => {
  return (
    <>
      <section className="property_list_grid property_list_sec">
        <div className="contain">
          <h4 className="light_heading f_s_24">{data.heading}</h4>
          <div className="flex">
            {data?.properties_list?.map((property, i) => {
              return (
                <div className="_col" key={i + 78965412789100000000}>
                  {property.popular == "true" ? (
                    <div className="property_badge">
                      <img src="/images/star.svg" alt="" />
                      <span>POPULAR</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="inner">
                    <Link
                      to={`/property-details/${property.property_id}`}
                      className="abst_link"
                    ></Link>
                    <div className="cntnt">
                      <h4 className="black_text color_text f_s_24">
                        ${Number(property.price)?.toLocaleString()} <sub>| {property.date}</sub>
                      </h4>
                      <h4 className="bold_text f_s_24">{property.title}</h4>
                      <p>{property.address}</p>
                      <div className="like_btn"></div>
                    </div>

                    <div className="divide_line"></div>
                    <ul>
                      {property.accommodation?.map((acc, index) => {
                        return (
                          <li key={index + 1278910000000065435}>
                            <img src={acc.img} alt={acc.accommo} />
                            <span
                              dangerouslySetInnerHTML={{ __html: acc.accommo }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewdProperties;
