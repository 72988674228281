import React, { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signin } from "../../../states/actions/fetchSignin";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";
import ShowPasswordIcon from "../../common/ShowPasswordIcon";
import { ErrorMessage } from "@hookform/error-message";
import AuthenticationLogin from "./Authentication";

const Form = ({ content }) => {
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isFormProcessing = useSelector(
    (state) => state.fetchSignin.isFormProcessing
  );
  const authentication = useSelector(
    (state) => state.fetchSignin.authentication
  );
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data, e) => {
    e.preventDefault();
    data = { ...data, type: "web" };
    dispatch(signin(data, location));
  };
  return (
    <>
      {authentication ? (
        <AuthenticationLogin />
      ) : (
        <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
          <div className="blk_form">
            <h6>Email</h6>
            <div className="form_blk">
              <input
                type="text"
                className="input"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email"
                  }
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="validation_error"> {message}</p>
                )}
              />
            </div>
          </div>
          <div className="blk_form">
            <h6>Password</h6>
            <div className="form_blk pass_blk">
              <input
                type={showPassword ? "text" : "password"}
                className="input"
                placeholder="Enter password"
                {...register("password", {
                  required: "Password is required."
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <p className="validation_error"> {message}</p>
                )}
              />
              <ShowPasswordIcon
                showPassword={showPassword}
                handleShowPassword={() => setShowPassword(!showPassword)}
              />
            </div>
          </div>
          <div className="forgot">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className="btn_blk">
            <SubmitButton
              classes="site_btn"
              button_text={content.left_sec_button_text}
              isFormProcessing={isFormProcessing}
            />
            <button className="site_btn blank" type="button">
              <img src="/images/google.svg" alt="" /> Continue with Google
            </button>
          </div>
          <div className="dont_acc">
            <p>
              {content.signup_link_left_heading}{" "}
              <Link to="/signup">{content.signup_link_heading}</Link>
            </p>
          </div>
        </form>
      )}
    </>
  );
};

export default Form;
