import React, { useRef, useState, useEffect } from 'react';
import Text from '../../common/Text';

const TermsPopup = ({ terms_conditions, subscription_agreement, setTermsPopup, setIsTermsAccepted, isTermsAccepted }) => {
    const popupRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const scrollToBottom = () => {
        popupRef.current.scroll({
            top: popupRef.current.scrollHeight,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = popupRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsAtBottom(true);
        } else {
            setIsAtBottom(false);
        }
    };

    useEffect(() => {
        const popupElement = popupRef.current;
        popupElement.addEventListener('scroll', handleScroll);
        return () => popupElement.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="popup lg active" ref={popupRef}>
            <div className="table_dv">
                <div className="table_cell">
                    <div className="_inner relative">
                        <div className="x_btn" onClick={() => setTermsPopup(false)}></div>
                        <div className="terms_content">
                            <h4>{terms_conditions?.page_title}</h4>
                            <Text string={terms_conditions?.policy_text} />
                            <hr />
                            <h4>{subscription_agreement?.page_title}</h4>
                            <Text string={subscription_agreement?.policy_text} />
                            <hr />
                            <div className="small_txt text-center agree_smll">
                                <div className="txtGrp flex">
                                    <div className="lblBtn flex">
                                        <input type="checkbox" name="confirm" id="confirm" onChange={() => setIsTermsAccepted(!isTermsAccepted)} defaultChecked={isTermsAccepted} />
                                        <h6 htmlFor="confirm">I agree to Terms & condition & subscription agreement</h6>
                                    </div>
                                    {/* <span className="validation-error">{errors.confirm?.message}</span> */}
                                </div>
                            </div>
                        </div>
                        {!isAtBottom && (
                            <button
                                className="scroll-to-bottom-btn"
                                onClick={scrollToBottom}
                            >
                                Scroll to bottom to Accept
                            </button>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TermsPopup;
