import { TOGGLE_RESULT_VIEW_STATE, FETCH_WISHLIST_CONTENT_SUCCESS } from "../actions/actionTypes";

const initialState = {
  resultView: "table",
  favoriteContent: {}
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_RESULT_VIEW_STATE:
      return {
        ...state,
        resultView: payload
      };
    case FETCH_WISHLIST_CONTENT_SUCCESS:
      return {
        ...state,
        favoriteContent: payload?.data
      };
    default:
      return state;
  }
}
