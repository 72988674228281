import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";
import { SEARCH_NEWSLETTER_EMAIL, SEARCH_NEWSLETTER_EMAIL_SUCCESS, SEARCH_NEWSLETTER_EMAIL_FAILED } from "./actionTypes";

export const search = (formData, setMessage) => (dispatch) => {
  const formDataObj = helpers.doObjToFormData(formData);
  dispatch({
    type: SEARCH_NEWSLETTER_EMAIL,
    payload: null
  });
  http
    .post("newsletter", formDataObj)
    .then(({ data }) => {
      if (data.status) {
        setMessage({type: 'success', text: 'You have registered successfully.'});
        dispatch({
          type: SEARCH_NEWSLETTER_EMAIL_SUCCESS,
          payload: data
        });
        const inputFields = document.querySelectorAll("input[type=email]");
        inputFields.forEach((inputField) => {
          inputField.value = "";
        });
      } else {
        if (data.msg) {
          setMessage({type: 'error', text: data.msg});
          dispatch({
            type: SEARCH_NEWSLETTER_EMAIL_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
