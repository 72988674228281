import React from "react";
import { deleteAccount } from "../../../states/actions/fetchProfileSettings";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../common/SubmitButton";

const ConfimDeletePassword = ({ toggleConfirmDeleteAccountPopup }) => {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.fetchProfileSettings.isFormProcessing
  );

  const handleDeleteAccount = (e) => {
    e.preventDefault();
    dispatch(deleteAccount());
  };
  return (
    <div class="popup sm popup_password_change active">
      <div className="table_dv">
        <div className="table_cell">
          <div className="contain">
            <div className="_inner">
              <button
                type="button"
                className="x_btn"
                onClick={toggleConfirmDeleteAccountPopup}
              ></button>
              <h5>Do you want to delete your account? You will not be able to login again.</h5>
              <button className="site_btn blank red_blank" type="button"
              onClick={toggleConfirmDeleteAccountPopup}>
                Cancel
              </button>

              <SubmitButton
              type="button"
              classes="site_btn blank red_blank"
              isFormProcessing={isFormProcessing}
              button_text="Yes, Delete"
              onClick={handleDeleteAccount}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfimDeletePassword;
