import {
  FETCH_PRICING_CONTENT,
  FETCH_PRICING_CONTENT_SUCCESS,
  FETCH_PRICING_CONTENT_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE,
  CANCEL_SUBSCRIPTION_MESSAGE_FAILED,
  CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS,
  UPDATE_SUBSCRIPTION_MESSAGE,
  UPDATE_SUBSCRIPTION_MESSAGE_FAILED,
  UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  authToken: localStorage.getItem("authToken"),
  memData: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PRICING_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_PRICING_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_PRICING_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case CANCEL_SUBSCRIPTION_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case CANCEL_SUBSCRIPTION_MESSAGE_SUCCESS:
      return {
        ...state,
        authToken: payload.authToken,
        isFormProcessing: true
      };
    case CANCEL_SUBSCRIPTION_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case UPDATE_SUBSCRIPTION_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case UPDATE_SUBSCRIPTION_MESSAGE_SUCCESS:
      return {
        ...state,
        authToken: payload.authToken,
        isFormProcessing: true
      };
    case UPDATE_SUBSCRIPTION_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
