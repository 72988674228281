import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const FeaturedPost = ({ featured_blog }) => {
  return (
    <>
      <div className="dir_col flex">
        <Link
          to={`/blog-detail/${featured_blog.encoded_id}/${featured_blog.slug}`}
          className="image"
        >
          <ImageControl src={featured_blog.image} folder="blog" />
        </Link>
        <div className="cntnt">
          <div className="cat_lbl">
            By {featured_blog.writter_name} in {featured_blog.cat_name}
          </div>
          <h4>
            <Link
              to={`/blog-detail/${featured_blog.encoded_id}/${featured_blog.slug}`}
            >
              <Text string={featured_blog.title} length={50} />
            </Link>
          </h4>
          <Text string={featured_blog.detail} length={200} />
        </div>
      </div>
    </>
  );
};

export default FeaturedPost;
