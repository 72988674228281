import React, { useEffect, useState } from 'react';
import { abbreviatedAmount } from '../../../helpers/helpers';
import http from '../../../helpers/http';
import MapComponent from './Map';

const SearchMap = (props) => {
  const { hideSearch, showMap } = props;
  const [PropertiesCount, setPropertiesCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (PropertiesCount === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [PropertiesCount]);

  useEffect(() => {
    http
      .post('getallPropertiesTotal', '')
      .then(({ data }) => {
        setPropertiesCount(data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    showMap && (
      <>
        <section
          className={
            hideSearch ? 'search_map less_top_search_map' : 'search_map'
          }
        >
          <div className='contain-fluid fix_cell_result_lbl'>
            {isLoading ? (
              'fetching total available properties count...'
            ) : (
              <strong>
                {PropertiesCount.toLocaleString()} (
                {abbreviatedAmount(PropertiesCount)}) properties available
              </strong>
            )}
          </div>
        </section>
        <MapComponent setDrawnFeatures={props.setDrawnFeatures} drawnFeatures={props.drawnFeatures} handleSearchClick={props.handleSearchClick} page="search" />
      </>
    )
  );
};

export default SearchMap;
